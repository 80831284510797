import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Button, Tooltip, Dropdown, Menu } from 'antd';
import JobStatus from '../JobStatus/JobStatus';
import SourcingProgressIndicator from '../SourcingProgressIndicator/SourcingProgressIndicator';
import './JobDetailHeader.scss';
import styles from './jobDetailHeader.module.scss';
import messages from './messages';
import CollaboratorsContainer from '../../Containers/CollaboratorsContainer/CollaboratorsContainer';
import { AryaBotIcon, AutomationOffIcon } from '../../Icons/AryaIcons';
import { getSourceName, getShowSourcingProgress } from '../../Utils/SourceUtils';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import SourceLimitPopover from '../SourceLimitPopover/SourceLimitPopover';
import eventTypes from '../../Analytics/EventTypes';
import ScoutingAgentInsideIcon from '../../Containers/ManualSearch/ScoutingAgent/ScoutingAgentInsideIcon';
import RecruiterAiIcon from '../../Icons/RecruiterAiIcon';

const productVariantToPlanLabelColorMapper = productVariantName => {
  const mapping = {
    Basic: styles.basicPlanLabelColor,
    Premium: styles.premiumPlanLabelColor,
    'Premium Upgrade': styles.premiumPlanLabelColor,
    [FREE_TRIAL]: styles.freePlanLabelColor,
  };
  return mapping[productVariantName];
};

const productVariantToPlanTextColorMapper = productVariantName => {
  const mapping = {
    Basic: styles.basicPlanTextColor,
    Premium: styles.premiumPlanTextColor,
    'Premium Upgrade': styles.premiumPlanTextColor,
    [FREE_TRIAL]: styles.freePlanTextColor,
  };
  return mapping[productVariantName];
};
class JobDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.moreMenu = this.moreMenu.bind(this);
    this.state = {};
  }

  componentDidMount() {
    const { job, featureToggleList, fetchJobConfig } = this.props;
    const {
      JobConfiguration: { IsEnabled: isJobConfigurationEnabled },
      SourceLimitConfiguration: { IsEnabled: isSourceLimitConfigurationEnabled },
    } = featureToggleList;
    if (fetchJobConfig && job?.JobId && (isJobConfigurationEnabled || isSourceLimitConfigurationEnabled)) {
      fetchJobConfig(job?.JobId);
    }
  }

  moreMenu() {
    const {
      job,
      duplicateJob,
      toggleSettingsDrawer,
      featureToggleList,
      // userConfig,
      // toggleDistributeJobModal,
    } = this.props;
    const { JobId } = job;

    return (
      <Menu onClick={() => null}>
        {featureToggleList.JobConfiguration.IsEnabled ? (
          <Menu.Item key="Settings" onClick={toggleSettingsDrawer}>
            <span className="job-settings-popover-menu" role="presentation">
              <Icon style={{ fontSize: 15, color: '#83878c' }} type="setting" theme="filled" />
              Settings
            </span>
          </Menu.Item>
        ) : null}
        <Menu.Item key="edit">
          <Link to={`/jobs/${JobId}/edit`}>
            <span className="job-settings-popover-menu">
              <img src="/static/Images/edit-job.svg" alt="edit" className="job-settings-popover-icon" />
              Edit Job
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="copy" onClick={duplicateJob}>
          <span className="job-settings-popover-menu">
            <img src="/static/Images/copy-job.svg" alt="copy" className="job-settings-popover-icon" />
            Copy Job
          </span>
        </Menu.Item>
        {/* <Menu.Item key="export">Export</Menu.Item> */}
        {/* {featureToggleList.JobDistribution.IsEnabled &&
        _.get(userConfig, 'Role', '').toLowerCase() === 'service rep' ? (
          <Menu.Item key="distribute" onClick={toggleDistributeJobModal}>
            <div className="job-settings-popover-menu">
              <DistributeJobIcon className="job-settings-popover-icon" />
              Distribute Job
            </div>
          </Menu.Item>
        ) : null} */}
      </Menu>
    );
  }

  getAutomationTooltipTitle() {
    const { isAutoEmailEnabled, isAutoMessageEnabled } = this.props;
    let tooltipTitle;
    if (isAutoEmailEnabled && isAutoMessageEnabled) {
      tooltipTitle = 'Email and Text automation is on';
    } else if (isAutoEmailEnabled && !isAutoMessageEnabled) {
      tooltipTitle = 'Email Automation is on';
    } else if (!isAutoEmailEnabled && isAutoMessageEnabled) {
      tooltipTitle = 'Text automation is on';
    } else {
      tooltipTitle = 'Email and Text automation is off';
    }
    return tooltipTitle;
  }

  // TODO Uncomment the above code once sms automation is enabled in UI

  // getAutomationTooltipTitle() {
  //   const { isAutoEmailEnabled } = this.props;
  //   let tooltipTitle;
  //   if (isAutoEmailEnabled) {
  //     tooltipTitle = 'Automation is on';
  //   } else {
  //     tooltipTitle = 'Automation is off';
  //   }
  //   return tooltipTitle;
  // }

  getAryaBotTooltipTitle() {
    const { job } = this.props;
    const { IsBotEnabled } = job;
    const AppName = 'Arya';
    let tooltipTitle;
    if (IsBotEnabled) {
      tooltipTitle = `${AppName}Bot is on.`;
    } else {
      tooltipTitle = `${AppName}Bot is off.`;
    }
    return tooltipTitle;
  }

  onClickRecruiterAiButton(jobId) {
    const { navbarRenderFlag } = this.props;
    if (!navbarRenderFlag) {
      window.location.href = `/ai/?jobId=${jobId}&ats=bullhorn`;
    } else {
      window.open(`/ai/?jobId=${jobId}`);
    }
  }

  getJobTag = (productVariant, productVariantName, productVariantDisplayName, isTryNow = false) => {
    const { userConfig, featureToggleList, selectPlan } = this.props;
    const isPaidJobService = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    if (isTryNow) {
      return (
        <div className={styles.trynowLabel}>
          <div className={styles.trynowText}>Try Now</div>
        </div>
      );
    }

    const planLabelColor = productVariantToPlanLabelColorMapper(productVariantName);
    const planLabelText = productVariantToPlanTextColorMapper(productVariantName);

    return (isPaidJobService || userConfig.Role === 'Service Rep') && productVariant && !selectPlan ? (
      <div className={`${styles.subscriptionPlanLabel} ${planLabelColor}`}>
        <div className={`${styles.subscriptionPlanText} ${planLabelText}`}>{productVariantDisplayName}</div>
      </div>
    ) : null;
  };

  render() {
    const {
      job,
      jobStatus,
      changeJobStatus,
      showActivationDialog,
      usersById,
      showBotConfigDrawer,
      userConfig,
      isAutoEmailEnabled,
      isAutoMessageEnabled,
      toggleSettingsDrawer,
      featureToggleList,
      publishedStatusesCount,
      upgradePlan,
      productVariantrsById,
      scoutingAgentConfig,
      isTryNow = false,
      isCandidateSupplyAnalyticsView = false,
      onClickSearchAgain,
      showBotIcon = true,
    } = this.props;
    const {
      Client,
      Location,
      Country,
      JobTitle,
      Status,
      JobId,
      sourcingStats,
      aryaState,
      recentSourcedTime,
      lastChangeTime,
      IsBotEnabled,
      Zipcode,
      OrderInfo,
      SourcingLocations = [],
    } = job;
    const isPaidJobService = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const ProductVariantId = _.get(OrderInfo, 'ProductVariantId', '');
    const orderStatus = _.get(OrderInfo, 'Status', '');
    const productVariant = _.get(productVariantrsById, ProductVariantId, {});
    const productVariantName = _.get(productVariant, 'Name', null);
    const productVariantDisplayName = _.get(productVariant, 'DisplayName', null);

    const sources = _.get(userConfig, 'PortalSources', []);
    const primaryLocation = SourcingLocations.find(sourcingLocation => sourcingLocation.IsPrimaryLocation) || {};
    const label = Zipcode ? primaryLocation.Label || `${Location} ${Zipcode}` : primaryLocation.Label || Location;
    const shouldShowCountry = !primaryLocation.Label && Country;
    const location = [Client, label, shouldShowCountry].filter(Boolean);

    const onJobStatusChange = (jobId, value, setPreviousJobStatusValue) => {
      const status = jobStatus.find(elem => elem.Name === value);
      changeJobStatus(jobId, status, null, true, setPreviousJobStatusValue);
    };
    let allUsers = []; // this list include shared user and user created that job
    let botEnabledClassName = '';
    if (usersById) {
      allUsers = allUsers.concat(job.SharedWith || []);
      allUsers = allUsers.concat(job.AssignedTo || []);
    }
    if (IsBotEnabled) {
      botEnabledClassName = 'active-icon';
    }
    let automationEnabledClassName = '';
    let botRightRadiusClassName = '';
    let automationLeftRadiusClassName = '';
    const isAutmationIconEnabled = isAutoEmailEnabled || isAutoMessageEnabled;

    if (isAutmationIconEnabled) automationEnabledClassName = 'active-icon';
    if (_.get(featureToggleList, ['JobConfiguration', 'IsEnabled'], false) && featureToggleList.BotRead.IsEnabled) {
      botRightRadiusClassName = 'bot-right-radius';
      automationLeftRadiusClassName = 'automation-left-radius';
    }
    let users = allUsers
      ? _.uniq(allUsers).map(userId => {
          return {
            name: usersById[userId] ? usersById[userId].FullName : null,
            Id: userId,
            Email: usersById[userId] ? usersById[userId].Email : null,
          };
        })
      : [];
    users = users.filter(user => !!user);

    const isSourceLimitConfigEnabled = featureToggleList.SourceLimitConfiguration.IsEnabled;

    let showSourceLimit = isSourceLimitConfigEnabled;
    const showSourcingProgress = getShowSourcingProgress(featureToggleList);
    if (featureToggleList.AdvanceSearchV2.IsEnabled && !featureToggleList.AryaRanking.IsAllowed) {
      showSourceLimit = false;
    }
    const isJobStatusAllowed = featureToggleList.JobStatus.IsAllowed;
    const isJobStatusEnabled = featureToggleList.JobStatus.IsEnabled;
    return (
      <div>
        <div className="job-header">
          <div className="job-info-container">
            <div className="jobtitle-status-wrapper" id="jobDetails">
              <Tooltip title={JobTitle} overlayStyle={{ zIndex: 2000 }}>
                <span id="title">{JobTitle}</span>
              </Tooltip>
              <span id="status">
                {isJobStatusAllowed && (
                  <JobStatus
                    jobStatus={jobStatus}
                    onJobStatusChange={onJobStatusChange}
                    status={Status}
                    jobId={JobId}
                    className="job-status"
                    dropdownClassName="job-status-dropdown"
                    job={job}
                    userConfig={userConfig}
                  />
                )}
                {!isJobStatusAllowed && isJobStatusEnabled && Status}
              </span>
            </div>
            <div className="job-subinfo">
              <div id="client" className="job-subinfo-item">
                <Tooltip title={location.join(', ')}>{location.join(', ')}</Tooltip>
              </div>
              <div>
                {!isTryNow ? (
                  <span id="id" className="job-subinfo-item">
                    <FormattedMessage {...messages.id} />: {JobId}
                  </span>
                ) : null}
              </div>
              <div>
                {job.JobCode ? (
                  <Tooltip title={job.JobCode}>
                    <span className="job-external-source-text">E</span>
                  </Tooltip>
                ) : null}
              </div>
            </div>
            {this.getJobTag(productVariant, productVariantName, productVariantDisplayName, isTryNow)}
          </div>
          <div className="action-container">
            <div className="portal-activation-wrapper">
              {showSourceLimit ? <SourceLimitPopover jobId={JobId} /> : null}
              {showSourcingProgress ? (
                <div
                  className={`portal-activation ${
                    isSourceLimitConfigEnabled ? 'no-left-border' : ''
                  } sourcing-progress-indicator`}
                  onClick={showActivationDialog}
                  role="presentation"
                  style={{ pointerEvents: featureToggleList.CandidateManualSearch.IsEnabled ? 'none' : 'all' }}
                >
                  <SourcingProgressIndicator
                    jobSourcingStats={sourcingStats || {}}
                    recentSourcedTime={recentSourcedTime}
                    lastChangeTime={lastChangeTime}
                    sourcingStatus={
                      !sources.every(source => {
                        const sourceName = getSourceName(source).toLowerCase();
                        return !_.get(aryaState, [sourceName, 'IsActivated'], false);
                      })
                    }
                  />
                </div>
              ) : null}
            </div>
            <div className="side-wrapper">
              {featureToggleList.ShareJob.IsEnabled ? (
                <div className="job-share-button-wrapper">
                  <CollaboratorsContainer collaborators={users} jobId={JobId} />
                </div>
              ) : null}
              {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && (
                <ScoutingAgentInsideIcon jobId={JobId} />
              )}

              {userConfig.Role === 'Service Rep' ||
              (featureToggleList.BotRead.IsEnabled &&
                !['basic', 'freetrial'].includes(productVariantName?.toLowerCase()) &&
                !isTryNow &&
                showBotIcon) ? (
                <Tooltip placement="bottom" className="bot-tooltip" title={this.getAryaBotTooltipTitle()}>
                  <div
                    role="presentation"
                    onClick={() => showBotConfigDrawer()}
                    className={`bot-config ${botEnabledClassName} ${botRightRadiusClassName}`}
                    sk-event-name={eventTypes.job.aryaBotConfig.goToAryaBotConfig}
                  >
                    <div className={`${botEnabledClassName && 'green-circle'}`}></div>
                    <AryaBotIcon className="bot-icon" width="16" style={{ fontSize: '18px' }} />
                  </div>
                </Tooltip>
              ) : null}
              {featureToggleList.JobConfiguration.IsEnabled ? (
                <Tooltip placement="bottom" className="automation-tooltip" title={this.getAutomationTooltipTitle()}>
                  <div
                    role="presentation"
                    onClick={() => toggleSettingsDrawer()}
                    className={`automation-config ${automationEnabledClassName} ${automationLeftRadiusClassName}`}
                  >
                    <div className={`${automationEnabledClassName && 'green-circle'}`}></div>
                    <AutomationOffIcon key="automationOffIcon" className="automation-icon" />
                  </div>
                </Tooltip>
              ) : null}
              {featureToggleList.JobSettings.IsEnabled ? (
                <div className="more-wrapper">
                  <Dropdown overlay={this.moreMenu} placement="bottomRight">
                    <Icon className="more" type="setting" theme="filled" />
                  </Dropdown>
                </div>
              ) : null}
              {featureToggleList.RecruiterAI.IsEnabled ? (
                <div
                  role="presentation"
                  className="recruiterAi-icon"
                  onClick={() => this.onClickRecruiterAiButton(JobId)}
                >
                  <RecruiterAiIcon />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {isPaidJobService &&
        productVariantName === 'Basic' &&
        !(orderStatus === 'Cancelled' || orderStatus === 'OpenForCancellation') &&
        publishedStatusesCount > 0 ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button className="engaged-button engaged-button-text" type="primary" shape="round" onClick={upgradePlan}>
              Engage Candidates
            </Button>
          </div>
        ) : null}
        {!isCandidateSupplyAnalyticsView && isTryNow ? (
          <div className={styles.searchAgainButtonWrapper}>
            <Button
              className="search-again-button search-again-button-text"
              type="primary"
              shape="round"
              onClick={onClickSearchAgain}
            >
              Search Again
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

JobDetailHeader.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})),
  changeJobStatus: PropTypes.func.isRequired,
  job: PropTypes.shape({
    Title: PropTypes.string,
    Status: PropTypes.string,
    ClientName: PropTypes.string,
    Location: PropTypes.string,
    Country: PropTypes.string,
    Id: PropTypes.number,
    CreatedDate: PropTypes.string,
  }),
  showActivationDialog: PropTypes.func.isRequired,
  isCandidateSupplyAnalyticsView: PropTypes.bool,
};

JobDetailHeader.defaultProps = {
  jobStatus: [],
  job: {},
  isCandidateSupplyAnalyticsView: false,
};

export default JobDetailHeader;
