import React from 'react';
import { Select, Input, Button, Form, Skeleton } from 'antd';
import styles from './AvailableCredit.module.scss';

const { Option } = Select;

const AvailableCredit = props => {
  const {
    fetchAvailableCreditsApiStatus,
    displayAmount,
    getShouldHideAddCreditsForm,
    handleSendRequest,
    requestForCreditsApiStatus,
    isSendRequestButtonDisabled,
    validateCredits,
    form,
    creditName,
    creditType,
  } = props;
  return (
    <div className={styles.creditsSettings}>
      <div className={styles.creditsDisplay}>
        <div className={styles.creditsDisplayTitle}>{creditName}</div>
        <div className={styles.creditsDisplayAmount}>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 1, width: '64px' }}
            loading={
              fetchAvailableCreditsApiStatus?.type === creditType &&
              fetchAvailableCreditsApiStatus.status === 'INPROGRESS'
            }
          >
            {displayAmount}
          </Skeleton>
        </div>
      </div>
      <div className={`${getShouldHideAddCreditsForm(creditType) ? styles.hideCreditsForm : ''}`}>
        <div className={styles.creditsRequest}>
          <Form.Item label="Request more credits" colon={false} required={false}>
            {form.getFieldDecorator('Credits', {
              rules: [{ required: true, message: 'Please choose credits' }],
            })(
              <Select className={styles.creditsRequestAmount}>
                <Option value={500}>500</Option>
                <Option value={1000}>1000</Option>
                <Option value={2000}>2000</Option>
                <Option value="Customize">Customize..</Option>
              </Select>
            )}
          </Form.Item>
        </div>

        {form.getFieldValue('Credits') === 'Customize' ? (
          <div className={styles.otherAmountRequest}>
            <Form.Item label="Enter other amount" colon={false}>
              {form.getFieldDecorator('OtherAmount', {
                rules: [{ validator: validateCredits }],
              })(<Input className={styles.otherAmountInput} placeholder="Enter credit amounts" />)}
            </Form.Item>
          </div>
        ) : null}
        <Button
          className={styles.sendRequestButton}
          onClick={e => {
            handleSendRequest(e, creditType, form);
          }}
          loading={
            requestForCreditsApiStatus?.type === creditType && requestForCreditsApiStatus?.status === 'INPROGRESS'
          }
          disabled={isSendRequestButtonDisabled(form)}
        >
          Send Request
        </Button>
      </div>
      <div className={styles.creditsSubmitDisclaimer}>
        Disclaimer: Access to contact information provider data is available as a subscription for an additional fee
      </div>
    </div>
  );
};

export default Form.create()(AvailableCredit);
export { AvailableCredit as AvailableCreditWithoutForm };
