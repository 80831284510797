import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';

import CandidateSourceAndName from './CandidateSourceAndName/CandidateSourceAndName';
import styles from './CandidateCardPersonalInfo.module.scss';
import { WorkExperienceIcon } from '../../Icons/AryaIcons';
import { getLatestProfileDateMarkup } from '../../Utils/CandidateListUtils';
import { getJoinedDisplayedInfo } from '../../Utils/CandidateCardUtils';
import CandidateBookmark from './CandidateBookmark';
import { candidatePublishRecommendedStatuses, getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import AssistedSourcingIndicator from './AssistedSourcingIndicator';

function CandidateCardPersonalInfo(props) {
  const {
    candidate,
    userConfig,
    whiteLabelInfo,
    featureToggleList,
    candidateType,
    updateCandidateBookmarkStatus,
    jobId,
    candidateBookmarkApiStatus,
    version,
    isCandidateViewIconVisible,
    openCandidateView,
    isCandidateGroupHead,
    duplicateCandidateIds,
    showExpandedCandidateCard,
    adminFeatures,
    activeTab,
  } = props;
  const {
    AssistedCandidateIndicator: { IsEnabled: isAssistedCandidateIndicatorEnabled },
  } = featureToggleList;
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
  const candidateTitle =
    candidate.Title && candidate.Company ? (
      <div className={styles.candidateDesignationInformation}>
        <span className={styles.highlightedText}>{candidate.Title}</span> at{' '}
        <span className={styles.candidateCompany}>{candidate.Company}</span>
      </div>
    ) : (
      <span className={styles.highlightedText}>{candidate.Title || candidate.Company}</span>
    );

  const candidateLocation = (
    <Tooltip title={candidate.Location} mouseEnterDelay={0.5}>
      <div className={styles.candidateLocation}>{candidate.Location}</div>
    </Tooltip>
  );

  const displayedCandidateTitle =
    candidate.Title || candidate.Company ? (
      <Tooltip title={candidateTitle} mouseEnterDelay={0.5}>
        {candidateTitle}
      </Tooltip>
    ) : null;
  const displayedCandidateLocation = candidate.Location ? candidateLocation : null;

  const candidateExperience = _.get(candidate, ['YearsOfExperience'], null);
  const displayedCandidateExperience =
    candidateExperience === 0
      ? '< 1 Year of Overall Experience'
      : `${candidateExperience}+ Years of Overall Experience`;
  const candidateExperienceWithIcon =
    candidateExperience !== null ? (
      <div className={styles.workExperienceWithIcon}>
        <WorkExperienceIcon className={styles.workExperienceIcon} />{' '}
        <Tooltip title={displayedCandidateExperience} mouseEnterDelay={0.5}>
          <span className={styles.workExperienceText}>{displayedCandidateExperience}</span>
        </Tooltip>
      </div>
    ) : null;
  const isBookmarkVisible = candidateType !== 'quick-search';

  const candidateId = candidate.Id;

  const candidateBookmark =
    !isCandidatePublishEnabled && isBookmarkVisible ? (
      <CandidateBookmark
        candidate={candidate}
        updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
        jobId={jobId}
        candidateBookmarkApiStatus={candidateBookmarkApiStatus?.[candidateId]}
        width={14}
        height={15}
      />
    ) : null;
  const latestProfileDataMarkup = getLatestProfileDateMarkup(candidate, true, true, { fontSize: '12px' });
  const isValidCandidateExperience = typeof candidateExperience === 'number';
  const candidateProfessionalInfoList =
    isValidCandidateExperience || latestProfileDataMarkup
      ? [candidateExperienceWithIcon, latestProfileDataMarkup, candidateBookmark]
      : [];
  const displayedProfessionalInfo = getJoinedDisplayedInfo(candidateProfessionalInfoList, '|');
  const isValidInfo = candidateProfessionalInfoList.some(value => value);
  const showCandidateHeaderInfo = isValidInfo ? (
    <div className={styles.displayedProfessionalInfo}>{displayedProfessionalInfo}</div>
  ) : null;

  const candidateTitleAndLocation = [displayedCandidateTitle, displayedCandidateLocation];

  const candidateTitleAndLocationList =
    !isValidCandidateExperience && !latestProfileDataMarkup
      ? [...candidateTitleAndLocation, candidateBookmark]
      : candidateTitleAndLocation;
  const candidateDesignationAndLocation = (
    <div className={styles.designationAndLocation}>{getJoinedDisplayedInfo(candidateTitleAndLocationList, '|')}</div>
  );
  const candidateCardHeadlineData =
    candidate.Headline && candidate.Headline.trim().length > 0 ? (
      <div className={styles.candidateCardHeadline}>
        <Tooltip title={candidate.Headline} mouseEnterDelay={0.5}>
          <div className={styles.candidateCardHeadlineText}>
            <span className={styles.headlineSeparator}>|</span>
            <span className={styles.truncatedHeadlineText}>{candidate.Headline}</span>
          </div>
        </Tooltip>
      </div>
    ) : null;
  return (
    <div className={styles.candidateCardPersonalInfo}>
      <div className={styles.candidateCardPrimaryData}>
        <CandidateSourceAndName
          candidate={candidate}
          userConfig={userConfig}
          whiteLabelInfo={whiteLabelInfo}
          version={version}
          featureToggleList={featureToggleList}
          isCandidateViewIconVisible={isCandidateViewIconVisible}
          openCandidateView={openCandidateView}
          isCandidateGroupHead={isCandidateGroupHead}
          duplicateCandidateIds={duplicateCandidateIds}
          adminFeatures={adminFeatures}
        />
        {candidateCardHeadlineData}
        {isAssistedCandidateIndicatorEnabled &&
        candidate.Status?.toLowerCase() !== 'favourite' &&
        candidatePublishRecommendedStatuses.includes(candidate.RecommendedBy) &&
        activeTab !== 'favourite' ? (
          <AssistedSourcingIndicator />
        ) : null}
      </div>
      {candidateDesignationAndLocation}
      {showExpandedCandidateCard ? showCandidateHeaderInfo : null}
    </div>
  );
}

export default CandidateCardPersonalInfo;
