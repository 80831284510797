import FileSaver from 'file-saver';
import _ from 'lodash';
import { message } from 'antd';
import { addClient } from '../Repository/ClientRepository';
import * as jobRepository from '../Repository/JobRepository';
import * as noteRepository from '../Repository/NoteRepository';
import { fetchUsers } from './UserActions';
import { getConversationReference, fetchJobsUnreadConversationCount } from './ConnectActions';
import { setFeatureLockedNotificationAlert } from './AppNotificationActions';
import { getEmailTemplate } from './EmailTemplates';
import { getTextTemplate } from './TextTemplates';
import { getDripTemplate } from './DripTemplates';
import { fetchJobCountByStatus, setCurrentJobStatusId } from './JobStatusActions';
import fetchJobSourcingStats from './SourcingStatsActions';
import * as aryaBotContextRepository from '../Repository/AryabotContextRepository';
import * as smartJobRepository from '../Repository/SmartJobRepository';
import * as tagRepository from '../Repository/TagRepository';
import {
  setDistributeJobApiStatus,
  setJobDistributionStatus,
  setMustHavesForJob,
  setUndoJobAutoRejectApiStatus,
  setUpdateJobIntelligenceApiStatus,
  setJobReactivationApiStatus,
  setAdvancedJobData,
  setCreateSegmentApiStatus,
  setPutEditSegmentApiStatus,
  setShowCurrentUserCandidateViewedFlag,
  setMinimizeManualSearchAction,
  setJobsBasicInfoList,
} from './ActionCreators/JobActionCreator';
import { mapErrorCodes } from '../Utils/JobsApiErrorResponseMapper';
import { setNotification } from './ActionCreators/ConnectActions';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { setJobDetailsApiStatus } from './ApiStatusActions';
import { getConfig } from '../Reducers/ConfigReducer';
import { filterEmptyValuesFromObject } from '../Utils/JobUtils';
import {
  setJobNotes,
  addJobNote,
  removeJobNote,
  clearJobNotes,
  setJobNoteFetchApiStatus,
  updateJobNote as _updateJobNote,
  setJobNoteDeleteApiStatus,
  setJobNoteAddButtonApiStatus,
  addJobNoteTags,
  removeTagFromJobNote,
} from './ActionCreators/NoteActionCreator';
import { setTagDeleteApiStatus } from './ActionCreators/TagActionCreator';
import { getJobStatus } from '../Reducers/JobStatusReducer';
import { setCandidatesRecommendationStatus } from './JobCandidatesTabActions';

message.config({
  top: 100,
  duration: 1,
  maxCount: 1,
});

const getJobSumtionErrorFailureMessage = status => {
  return `Failed: You have reached the maximum limit of adding/submitting jobs in ${status} status`;
};

function setCurrentJobId(jobId) {
  return dispatch => {
    dispatch({
      type: 'SET_CURRENT_JOB_ID',
      payload: jobId,
    });
  };
}
function clearLocations() {
  return {
    type: 'SET_LOCATIONS',
    payload: [],
  };
}

function setJobListPageSize(pageSize) {
  return {
    type: 'SET_JOB_LIST_PAGE_SIZE',
    payload: pageSize,
  };
}
function setJobListPageNumber(pageNumber) {
  return {
    type: 'SET_JOB_LIST_PAGE_NUMBER',
    payload: pageNumber,
  };
}
function setJobListSelectedUser(userId) {
  return {
    type: 'SET_JOB_LIST_SELECTED_USER',
    payload: userId,
  };
}

function setJobListSearchTerm(searchTerm, candidateContext) {
  return {
    type: 'SET_JOB_LIST_SEARCH_TERM',
    payload: { searchTerm, candidateContext },
  };
}

function setDefaultHomePageRenderFlag(homePageRenderFlag) {
  return {
    type: 'SET_DEFAULT_HOME_PAGE_RENDER_FLAG',
    payload: homePageRenderFlag,
  };
}

function setJobListTabName(tabName) {
  return {
    type: 'SET_JOB_LIST_TAB_NAME',
    payload: tabName,
  };
}

function setJobTags(tags = []) {
  return {
    type: 'SET_TAGS_FOR_JOB_LIST',
    payload: tags,
  };
}

function addTagForJobNote({ jobId, noteId, tag }) {
  return dispatch => {
    tagRepository
      .createNoteTags({ noteId, tags: [tag] })
      .then(response => {
        dispatch(addJobNoteTags({ jobId, noteId, tags: response.data.Tags }));
        dispatch(setNotification('SUCCESS', 'Tag for note added successfully'));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
      });
  };
}

function deleteTagFromJobNote({ tag, jobId, noteId }) {
  return async dispatch => {
    dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'INPROGRESS' }));
    try {
      await tagRepository.deleteTag({ tagAssociationId: tag?.AssociationId });
      dispatch(removeTagFromJobNote({ jobId, noteId, tagAssociationId: tag?.AssociationId }));
      dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'COMPLETED' }));
      dispatch(setNotification('SUCCESS', 'Tag deleted successfully', 2));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'FAILED' }));
      dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
    }
  };
}

const getAllJobNotes = ({ jobId }) => {
  return dispatch => {
    dispatch(clearJobNotes(jobId));
    dispatch(setJobNoteFetchApiStatus('INPROGRESS'));
    noteRepository
      .getNotes({ jobId })
      .then(response => {
        const payload = response.data;
        dispatch(setJobNotes({ jobId, notes: payload.Notes }));
        dispatch(setJobNoteFetchApiStatus('COMPLETED'));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(setJobNoteFetchApiStatus('FAILED'));
      });
  };
};

const createJobNote = ({ jobId, note }) => {
  return async dispatch => {
    dispatch(setJobNoteAddButtonApiStatus('INPROGRESS'));
    try {
      const response = await noteRepository.createNote({ jobId, payload: note });
      dispatch(addJobNote({ jobId, note: response.data }));
      dispatch(setJobNoteAddButtonApiStatus('COMPLETED'));
      dispatch(setNotification('SUCCESS', 'Note created successfully'));
      return { isSuccess: true };
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
      dispatch(setJobNoteAddButtonApiStatus('FAILED'));
      return { isSuccess: false };
    }
  };
};

const updateJobNote = ({ jobId, noteId, note }) => {
  return async dispatch => {
    dispatch(setJobNoteAddButtonApiStatus('INPROGRESS'));
    try {
      const response = await noteRepository.updateNote({ noteId, payload: note });
      dispatch(_updateJobNote({ jobId, note: response.data }));
      dispatch(setJobNoteAddButtonApiStatus('COMPLETED'));
      dispatch(setNotification('SUCCESS', 'Note updated successfully'));
      return { isSuccess: true };
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
      dispatch(setJobNoteAddButtonApiStatus('FAILED'));
      return { isSuccess: false };
    }
  };
};

const deleteJobNote = ({ jobId, noteId }) => {
  return dispatch => {
    dispatch(setJobNoteDeleteApiStatus({ [noteId]: 'INPROGRESS' }));
    noteRepository
      .deleteNote({ noteId })
      .then(() => {
        dispatch(removeJobNote({ jobId, noteId }));
        dispatch(setNotification('SUCCESS', 'Note deleted successfully'));
        dispatch(setJobNoteDeleteApiStatus({ [noteId]: 'COMPLETED' }));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(setJobNoteDeleteApiStatus({ [noteId]: 'FAILED' }));
        dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
      });
  };
};

function fetchActivationStatus(jobIds) {
  return dispatch => {
    if (jobIds && jobIds.length > 0) {
      jobRepository
        .fetchActivationDetails(jobIds)
        .then(response => {
          const payload = response.data;
          dispatch({
            type: 'SET_ACTIVATION_STATES',
            payload,
          });
        })
        .catch(() => {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              code: 'ACTIVATION_STATUSES_FETCH_FAILED',
              timeStamp: new Date(),
            },
          });
        });
    }
  };
}

function fetchBulkJobTags(jobIds) {
  return async dispatch => {
    try {
      const jobTagsResponse = await jobRepository.fetchBulkJobTags(jobIds);
      const bulkJobTags = _.get(jobTagsResponse, 'data', []);
      dispatch({
        type: 'SET_BULK_JOB_TAGS',
        payload: {
          bulkJobTags,
        },
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'BULK_JOB_LIST_TAGS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function fetchConnectedJobs(filter, fetchType) {
  return dispatch => {
    dispatch({
      type: 'SET_JOB_LIST_API_STATUS',
      payload: 'INPROGRESS',
    });
    jobRepository
      .fetchJobList(filter)
      .then(response => {
        const jobGuids = response.data.Jobs.map(job => job.JobGuid);
        const jobs = _.get(response, ['data', 'Jobs'], []);
        const jobGuidsToJobIdMapping = {};
        jobs.forEach(job => {
          jobGuidsToJobIdMapping[job.JobGuid] = job.JobId;
        });
        dispatch({
          type: 'SET_JOB_GUID_TO_ID_MAPPING',
          payload: jobGuidsToJobIdMapping,
        });
        dispatch({
          type: fetchType === 'ON_LOAD' ? 'APPEND_CONNECT_JOBS' : 'SET_CONNECT_JOBS',
          payload: {
            jobs: response.data.Jobs,
            connectStatus: filter.connectStatus,
          },
        });
        dispatch({
          type: 'SET_JOB_LIST_API_STATUS',
          payload: 'COMPLETED',
        });
        dispatch(fetchJobsUnreadConversationCount(jobGuids));
      })
      .catch(err => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function toggleJobCard(jobId, isTagSelected) {
  return dispatch => {
    const makeTagSelected = !isTagSelected;
    dispatch({
      type: 'TOGGLE_JOB_CARD',
      payload: {
        jobId,
        makeTagSelected,
      },
    });
  };
}

function updateFilterTags(selectedTags) {
  return {
    type: 'UPDATE_FILTER_TAGS',
    payload: {
      selectedTags,
    },
  };
}

function fetchJobs(filter) {
  return dispatch => {
    jobRepository
      .fetchJobList(filter)
      .then(response => {
        const jobs = _.get(response, ['data', 'Jobs'], []);
        const jobGuidsToJobIdMapping = {};
        jobs.forEach(job => {
          jobGuidsToJobIdMapping[job.JobGuid] = job.JobId;
        });
        dispatch({
          type: 'SET_JOB_GUID_TO_ID_MAPPING',
          payload: jobGuidsToJobIdMapping,
        });
        dispatch({
          type: 'SET_JOB_LIST',
          payload: {
            statusId: filter.statusId,
            status: filter.status,
            jobs,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOB_LIST_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchJobList(filter, allowPageLoading = true) {
  return async (dispatch, getState) => {
    try {
      const selectedTagIds = _.get(filter, ['tagIds'], []);
      const isTagSelected = selectedTagIds.length > 0;
      const store = getState();
      const isJobTagEnabled = store.FeatureToggleReducer.JobTag.IsEnabled;
      const { isJobStatsRequired } = filter;
      if (allowPageLoading) {
        dispatch({
          type: 'SET_JOB_LIST_API_STATUS',
          payload: 'INPROGRESS',
        });
        if (isJobStatsRequired)
          dispatch({
            type: 'SET_JOB_STATUS_COUNT_API_STATUS',
            payload: 'INPROGRESS',
          });
      }

      const acceptableKeys = ['skip', 'count', 'isassigned', 'isjobstatsrequired', 'entitytype', 'statusid'];
      const payloadValues = filterEmptyValuesFromObject(filter);
      const shouldMakeGetCall = Object.keys(payloadValues)?.every(key => acceptableKeys.includes(key.toLowerCase()));
      const response = shouldMakeGetCall
        ? await jobRepository.getJobList(payloadValues)
        : await jobRepository.fetchJobList(payloadValues);
      const { references } = filter;
      let jobs = response.data.Jobs;
      const { Aggregations } = response.data;

      if (references) {
        const referenceMap = references.reduce((accumulator, ref) => {
          accumulator[ref.Id] = ref;
          return accumulator;
        }, {});

        jobs = jobs.map(job => {
          if (referenceMap[job.JobGuid]) {
            return {
              ...job,
              ...referenceMap[job.JobGuid],
            };
          }
          return job;
        });
      }
      dispatch({
        type: 'SET_JOB_LIST',
        payload: {
          statusId: filter.statusId,
          status: filter.status,
          jobs,
        },
      });
      dispatch({
        type: 'TOGGLE_ALL_JOB_CARDS',
        payload: {
          isTagSelected,
        },
      });
      if (isJobStatsRequired) {
        dispatch({
          type: 'SET_JOB_COUNT_BY_STATUS',
          payload: response.data,
        });
      }
      if (allowPageLoading) {
        dispatch({
          type: 'SET_JOB_LIST_API_STATUS',
          payload: 'COMPLETED',
        });

        if (isJobStatsRequired) {
          dispatch({
            type: 'SET_JOB_STATUS_COUNT_API_STATUS',
            payload: 'COMPLETED',
          });
        }
      }
      const nonDraftJobsCount =
        response.data.JobStatus?.filter(word => word.Name !== 'Draft')
          .map(item => item.Count)
          .reduce((acc, val) => acc + val, 0) || 0;

      const showSampleCandidates = nonDraftJobsCount <= 0;
      localStorage.setItem('SampleCandidatesDisplayFlag', showSampleCandidates);
      if (isJobStatsRequired) {
        dispatch({ type: 'SET_NON_DRAFT_JOB_COUNT', payload: nonDraftJobsCount });
        dispatch({
          type: 'SET_SAMPLE_CANDIDATES_DISPLAY_FLAG',
          payload: showSampleCandidates,
        });
      }
      if (isJobTagEnabled) {
        dispatch(setJobTags(Aggregations?.Tag));
      }
      const jobIds = jobs.map(job => job.JobId);
      if (jobIds.length > 0) {
        await Promise.all([
          dispatch(fetchJobSourcingStats(jobIds)),
          dispatch(fetchActivationStatus(jobIds)),
          dispatch(fetchBulkJobTags(jobIds)),
        ]);
      }
      let sharedWith = [];
      jobs.forEach(job => {
        if (job.SharedWith && job.SharedWith.length > 0) {
          sharedWith = sharedWith.concat(job.SharedWith);
        }
        if (job.AssignedTo && job.AssignedTo.length > 0) {
          sharedWith = sharedWith.concat(job.AssignedTo);
        }
      });
      sharedWith = sharedWith.filter(userId => !!userId);
      if (sharedWith && sharedWith.length > 0) {
        sharedWith = _.uniq(sharedWith);
        await dispatch(
          fetchUsers({
            userIds: sharedWith,
          })
        );
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'JOB_LIST_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function fetchJobCount(filter) {
  return dispatch => {
    jobRepository
      .fetchJobCount(filter)
      .then(response => {
        const payload = {
          count: response.data.Total,
          status: filter.statusId || filter.connectStatus,
        };
        dispatch({
          type: 'SET_JOB_COUNT',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOBS_COUNT_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchOrgActivatedJobsCount() {
  return (dispatch, getState) => {
    const store = getState();
    const userConfig = getConfig(store);
    if (userConfig.JobLimit) {
      jobRepository.fetchOrgActivatedJobsCount().then(response => {
        const payload = {
          count: response.data.Total,
        };
        dispatch({
          type: 'SET_ORG_ACTIVATED_JOBS_COUNT',
          payload,
        });
      });
    }
  };
}

export function fetchOrgOpenedJobsCount() {
  return async (dispatch, getState) => {
    const store = getState();
    const userConfig = getConfig(store);
    try {
      if (userConfig.JobLimit) {
        const response = await jobRepository.fetchOrgOpenedJobsCount();
        const payload = {
          count: response.data.Total,
        };
        dispatch({
          type: 'SET_ORG_OPENED_JOBS_COUNT',
          payload,
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'JOB_OPENED_COUNT_ERROR',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function changeJobStatus(jobId, status, filter, allowPageLoading = true, setPreviousJobStatusValue) {
  return dispatch => {
    jobRepository
      .changeJobStatus(jobId, status.Name)
      .then(() => {
        dispatch({
          type: 'CHANGE_JOB_STATUS',
          payload: {
            jobId,
            status,
          },
        });
        if (filter) {
          dispatch(fetchJobList(filter, allowPageLoading));
          dispatch(fetchJobCountByStatus(filter, allowPageLoading));
        }
      })
      .catch(error => {
        const statusCode = error.response.status;
        const errorCode = error.response.data.Error.Code;
        if (statusCode === 403 && errorCode === 'JOB_UPDATION_LIMIT_EXCEEDED') {
          if (setPreviousJobStatusValue) setPreviousJobStatusValue();
          const jobSubmitionFailureMessage = getJobSumtionErrorFailureMessage(status.Name);
          message.error(jobSubmitionFailureMessage);
          throw Error(jobSubmitionFailureMessage);
        }

        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOBSTATUS_POST_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function patchEditJob(jobId, jobDetails) {
  return dispatch => {
    jobRepository
      .patchEditJob(jobId, jobDetails)
      .then(() => {
        const payload = jobDetails;
        dispatch({
          type: 'UPDATE_JOB_PATCH',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOB_PATCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function setBotContextId(BotContextId, jobId, payload) {
  return dispatch => {
    jobRepository.setBotContextId(jobId, BotContextId).then(() => {
      dispatch({
        type: 'SET_JOB_DETAILS',
        payload,
      });
    });
  };
}

function fetchCountryDetails(countryCode, jobId) {
  return dispatch => {
    jobRepository.fetchCountryDetails(countryCode).then(response => {
      dispatch({
        type: 'SET_COUNTRY_DETAILS_FOR_JOB',
        payload: {
          jobId,
          countryInfo: response.data,
        },
      });
    });
  };
}

function fetchJobDetails(jobId) {
  return async dispatch => {
    dispatch(setJobDetailsApiStatus('INPROGRESS'));
    return jobRepository
      .fetchJobDetails(jobId)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_JOB_DETAILS',
          payload,
        });
        dispatch(setJobDetailsApiStatus('COMPLETED'));
        if (!payload.BotContextId) {
          const type = 'REF';
          const {
            JobGuid,
            Client,
            Country,
            Description,
            JobTitle,
            Location,
            Zipcode,
            MinExperience,
            MaxExperience,
            Summary,
          } = payload;
          const location = [];
          if (Zipcode) {
            location.push(Zipcode);
          }
          if (Location) {
            location.push(Location);
          }
          location.push(Country);
          const ClientLocation = location.join(' ');
          const answers = {
            JobDescription: Description,
            ClientLocation,
            ClientDetails: Client,
            JobTitle,
          };
          if (MinExperience || MaxExperience) {
            let experience = '';
            if (MinExperience && MaxExperience) {
              experience = `${MinExperience} to ${MaxExperience} years.`;
            } else if (MinExperience) {
              experience = `Minimum ${MinExperience} year experience.`;
            } else {
              experience = `Maximum ${MaxExperience} year experience.`;
            }
            answers.JobExperience = experience;
          }
          answers.Summary = Summary;
          const _payload = payload;
          aryaBotContextRepository
            .createContext(type, JobGuid, false, answers)
            .then(respose => {
              const BotContextId = respose.data.Id;
              _payload.BotContextId = BotContextId;
              dispatch(setBotContextId(BotContextId, jobId, _payload));
            })
            .catch(error => {
              if (error.response.status === 409) {
                const BotContextId = error.response.data.Error.Details.Id;
                _payload.BotContextId = BotContextId;
                dispatch(setBotContextId(BotContextId, jobId, _payload));
              }
            });
        }
        fetchCountryDetails(response.data.CountryCode, response.data.JobId)(dispatch);
        let allCollaborators = [];
        allCollaborators = allCollaborators.concat(response.data.SharedWith || []);
        allCollaborators = allCollaborators.concat(response.data.AssignedTo || []);
        if (response.data.CreatedBy) {
          allCollaborators.push(response.data.CreatedBy);
        }
        allCollaborators = allCollaborators.filter(userId => !!userId);
        if (allCollaborators && allCollaborators.length > 0) {
          allCollaborators = _.uniq(allCollaborators);
          dispatch(
            fetchUsers({
              userIds: allCollaborators,
            })
          );
        }
        dispatch(getConversationReference(response.data.JobId, response.data.JobGuid));
        return response.data;
      })
      .catch(error => {
        if (error.response && [400, 403, 404].includes(error.response.status)) {
          dispatch({
            type: 'SET_JOB_DETAILS',
            payload: {
              JobId: jobId,
              ErrorCode: error.response.status,
            },
          });
          dispatch(setJobDetailsApiStatus('COMPLETED'));
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              code: 'JOB_DETAILS_FETCH_FAILED',
              timeStamp: new Date(),
            },
          });
          dispatch(setJobDetailsApiStatus('FAILED'));
        }
        return {};
      });
  };
}

function setActivationStateChanged(jobId, status) {
  return dispatch =>
    dispatch({
      type: 'SET_ACTIVATION_STATE_CHANGED',
      payload: {
        jobId,
        status,
      },
    });
}

function activatePortals(activationDetails, storeApiStatus = false, searchCriteria) {
  return dispatch => {
    if (storeApiStatus) {
      dispatch(setJobReactivationApiStatus('INPROGRESS'));
    }
    jobRepository
      .activatePortals({ activationDetails, searchCriteria: !_.isEmpty(searchCriteria) ? searchCriteria : undefined })
      .then(() => {
        dispatch({
          type: 'UPDATE_ACTIVATION_STATES',
          payload: {
            jobId: activationDetails.JobId,
            sources: activationDetails.Sources,
          },
        });
        dispatch(fetchActivationStatus([activationDetails.JobId]));
        if (activationDetails.RetainType !== 'keepAll') {
          dispatch(setActivationStateChanged(activationDetails.JobId, true));
        }
        if (storeApiStatus) {
          dispatch(setJobReactivationApiStatus('COMPLETED'));
        }
      })
      .catch(error => {
        const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
        const statusCode = _.get(error, ['response', 'status']);
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'ACTIVATION_STATUSES_POST_FAILED',
            timeStamp: new Date(),
          },
        });
        if (storeApiStatus) {
          dispatch(setJobReactivationApiStatus('FAILED'));
        }
        if (statusCode === 403 && appCode === 'JOB_ACTIVATION_LIMIT_EXCEEDED')
          dispatch(setFeatureLockedNotificationAlert({ type: 'JOB_LIMIT_EXCEEDED', autoClose: true }));
      });
  };
}

function fetchJobResumes(jobId) {
  return dispatch => {
    jobRepository.fetchJobResumes(jobId).then(response => {
      dispatch({
        type: 'SET_RESUMES_FOR_JOB',
        payload: {
          jobId,
          resumes: response.data,
        },
      });
    });
  };
}

function bulkUploadJobResumes(jobId, resumeFiles, showUploadConfirmation) {
  const formData = new FormData();
  resumeFiles.forEach(file => {
    formData.append('files', file);
  });
  return dispatch => {
    jobRepository.bulkUploadJobResumes(jobId, formData).then(() => {
      if (showUploadConfirmation) {
        message.success('Resume(s) uploaded successfully');
      }
      dispatch(fetchJobResumes(jobId));
    });
  };
}

function bulkDeleteJobResumes(jobId, resumeIds) {
  return dispatch => {
    jobRepository.bulkDeleteJobResumes(jobId, resumeIds).then(() => {
      dispatch(fetchJobResumes(jobId));
    });
  };
}

function updateJobConfig(jobId, jobConfig) {
  const updatedJobConfig = jobConfig;
  delete updatedJobConfig.AutomationConfiguration?.Workflows?.[0]?.WorkflowConfigId;
  const messageConfig = updatedJobConfig.AutomationConfiguration?.Message;
  const isMessageTemplateIsEnableWithoutTemplateId = messageConfig?.IsEnabled && !messageConfig?.TemplateId;
  const workFlow = updatedJobConfig.AutomationConfiguration?.Workflows?.[0];

  return dispatch => {
    if (isMessageTemplateIsEnableWithoutTemplateId) {
      setErrorMessage(dispatch, 'Please select a message template before saving.');
      return;
    }
    if (workFlow?.IsEnabled && !workFlow?.WorkflowDefinitionId) {
      setErrorMessage(dispatch, 'Please select a workflow before saving.');
      return;
    }
    dispatch({
      type: 'SET_UPDATE_JOB_CONFIG_API_STATUS',
      payload: {
        jobId,
        status: 'INPROGRESS',
      },
    });
    jobRepository
      .updateJobConfig(jobId, updatedJobConfig)
      .then(() => {
        dispatch({
          type: 'SET_UPDATE_JOB_CONFIG_API_STATUS',
          payload: {
            jobId,
            status: 'COMPLETED',
          },
        });
        dispatch({
          type: 'UPDATE_JOB_CONFIG',
          payload: {
            updatedJobConfig,
            jobId,
          },
        });
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: 'Saved successfully!',
          },
        });
      })
      .catch(err => {
        if (err) {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Failed to save. Please try again!',
            },
          });
          dispatch({
            type: 'SET_UPDATE_JOB_CONFIG_API_STATUS',
            payload: {
              jobId,
              status: 'FAILED',
            },
          });
        }
      });
  };
}

async function getJobDetailsWitchClientID(jobDetails, store) {
  const newJobDeatils = {
    ...jobDetails,
  };
  try {
    const featureToggleList = getFeatureToggleList(store);
    const isPadiJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    if (!jobDetails.ClientId && isPadiJobServiceEnabled) {
      const clientResponse = await addClient({
        Name: jobDetails.ClientName,
      });
      newJobDeatils.ClientId = clientResponse.data.Id;
      return newJobDeatils;
    }
    return jobDetails;
  } catch {
    throw Error('Failed to create Job, unable to create client');
  }
}

function setTrynowFormData(data, override = false) {
  return dispatch => {
    dispatch({
      type: 'SET_TRYNOW_FORM_DATA',
      payload: { data, override },
    });
  };
}

function setAdvancedJobFormData(data) {
  return dispatch => {
    dispatch(setAdvancedJobData(data));
  };
}

function createJob(jobDetails, redirectOptions, uploadJobResumeFiles, jobConfig = null, submitAction = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_CREATE_JOB_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const store = getState();
      const newJobDetails = await getJobDetailsWitchClientID(jobDetails, store);
      const featureToggleList = getFeatureToggleList(store);
      const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
      const userRole = getConfig(store).Role;
      const response = await jobRepository.createJob(newJobDetails, { isPaidJobServiceEnabled, role: userRole });
      const payload = response.data;
      dispatch({
        type: 'CREATE_JOB',
        payload,
      });
      dispatch({
        type: 'SET_CREATE_JOB_API_STATUS',
        payload: 'COMPLETED',
      });
      dispatch(setTrynowFormData({}, true));
      dispatch(setJobListPageNumber(1));
      dispatch(setCurrentJobStatusId(undefined));
      dispatch(setJobListSearchTerm('', 'job'));
      dispatch(updateFilterTags([]));
      if (uploadJobResumeFiles?.length) {
        dispatch(bulkUploadJobResumes(payload.JobId, uploadJobResumeFiles, false));
      }
      if (jobConfig) {
        dispatch(updateJobConfig(payload.JobId, { ...jobConfig, IsReactivationRequired: false }));
      }
      if (redirectOptions) {
        const searchQuery = submitAction !== 'SaveDraft' ? `&jobGuid=${payload.JobGuid}&jobId=${payload.JobId}` : '';
        redirectOptions.history.push({
          pathname: redirectOptions.redirectUrl,
          search: searchQuery,
          state: payload,
        });
      }
      return response;
      // dispatch(updateJobSourcingConfig(payload.JobId, sourcingConfiguration));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'JOB_POST_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch({
        type: 'SET_CREATE_JOB_API_STATUS',
        payload: 'FAILED',
      });
      const statusCode = error.response.status;
      const errorCode = error.response.data.Error.Code;

      if (statusCode === 403 && errorCode === 'JOB_CREATION_LIMIT_EXCEEDED') {
        const store = getState();
        const jobStatus = getJobStatus(store);
        const status = jobStatus.find(elem => elem.Id === jobDetails.StatusId);
        const jobSubmitionFailureMessage = getJobSumtionErrorFailureMessage(status.Name);
        message.error(jobSubmitionFailureMessage);
        throw Error(jobSubmitionFailureMessage);
      }
      message.error('Failed to create, please try again');
      throw Error('Failed to create, please try again');
    }
  };
}

function putEditJob(jobId, jobDetails, redirectOptions, resumeUpdateDetails) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SET_EDIT_JOB_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const store = getState();
      const newJobbDetails = await getJobDetailsWitchClientID(jobDetails, store);
      const featureToggleList = getFeatureToggleList(store);
      const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
      const userRole = getConfig(store).Role;
      await jobRepository.putEditJob(jobId, newJobbDetails, { isPaidJobServiceEnabled, role: userRole });
      const payload = newJobbDetails;
      dispatch({
        type: 'UPDATE_JOB_PUT',
        payload,
      });
      dispatch({
        type: 'SET_EDIT_JOB_API_STATUS',
        payload: 'COMPLETED',
      });
      if (redirectOptions) {
        redirectOptions.history.push({
          pathname: redirectOptions.redirectUrl,
          search: redirectOptions.search,
          state: {
            JobId: jobId,
            JobTitle: newJobbDetails.JobTitle,
          },
        });
      }
      if (resumeUpdateDetails) {
        if (resumeUpdateDetails.uploadedResumeFiles.length) {
          dispatch(bulkUploadJobResumes(jobId, resumeUpdateDetails.uploadedResumeFiles));
        }
        if (resumeUpdateDetails.removedResumeIds.length) {
          dispatch(bulkDeleteJobResumes(jobId, resumeUpdateDetails.removedResumeIds));
        }
      }
      return newJobbDetails;
    } catch (e) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'JOB_PUT_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch({
        type: 'SET_EDIT_JOB_API_STATUS',
        payload: 'FAILED',
      });
      const statusCode = e.response.status;
      const errorCode = e.response.data.Error.Code;
      if (statusCode === 403 && errorCode === 'JOB_UPDATION_LIMIT_EXCEEDED') {
        const store = getState();
        const jobStatus = getJobStatus(store);
        const status = jobStatus.find(elem => elem.Id === jobDetails.StatusId);
        const jobSubmitionFailureMessage = getJobSumtionErrorFailureMessage(status.Name);
        message.error(jobSubmitionFailureMessage);
        throw Error(jobSubmitionFailureMessage);
      }
      message.error('An error occured while saving your input. Please recheck and try again');
      throw e;
    }
  };
}

function duplicateJob(jobId, history) {
  return dispatch => {
    jobRepository
      .duplicateJob(jobId)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'DUPLICATE_JOB',
          payload,
        });
        history.push(`/jobs/${response.data.JobId}/edit`);
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'DUPLICATE_JOB_POST_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function undoJobCandidateAutoReject(jobId) {
  return dispatch => {
    dispatch(setUndoJobAutoRejectApiStatus('INPROGRESS'));
    jobRepository
      .undoJobCandidateAutoReject(jobId)
      .then(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: 'Auto-reject undo successful',
          },
        });
        dispatch(setUndoJobAutoRejectApiStatus('COMPLETED'));
      })
      .catch(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Oops, something just went wrong. Please try again.',
          },
        });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'UNDO_JOB_CANDIDATE_AUTO_REJECT_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(setUndoJobAutoRejectApiStatus('FAILED'));
      });
  };
}

function fetchTagsForJobForm(filter) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_TAGS_FETCH_API_STATUS',
      payload: 'INPROGRESS',
    });
    jobRepository
      .fetchTagsForJobForm(filter)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_TAGS_FOR_JOB_FORM',
          payload,
        });
        dispatch({
          type: 'UPDATE_TAGS_FETCH_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_TAGS_FOR_JOB_FORM',
          payload: [],
        });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'TAGS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'UPDATE_TAGS_FETCH_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function updateTotalTagsCount(totalTagsCount) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_TOTAL_TAGS_COUNT',
      payload: totalTagsCount,
    });
  };
}

function fetchTitleSynonyms(title) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_TITLE_SYNONYMS_FETCH_API_STATUS',
      payload: 'INPROGRESS',
    });
    dispatch({
      type: 'SET_TITLE_SYNONYMS',
      payload: [],
    });
    jobRepository
      .getTitleSynonyms(title)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_TITLE_SYNONYMS',
          payload,
        });
        dispatch({
          type: 'UPDATE_TITLE_SYNONYMS_FETCH_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'TITLE_SYNONYMS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'UPDATE_TITLE_SYNONYMS_FETCH_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function clearTitleSynonyms() {
  return dispatch => {
    dispatch({
      type: 'SET_TITLE_SYNONYMS',
      payload: [],
    });
  };
}

function fetchCountries(requireAuthentication = true) {
  return async dispatch => {
    let response;
    let payload;
    try {
      if (requireAuthentication) {
        response = await jobRepository.getCountries();
      } else {
        response = await jobRepository.getSmartjobCountries();
      }
      payload = response.data;
      dispatch({
        type: 'SET_COUNTRIES',
        payload,
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'COUNTRIES_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function resetZipcodes() {
  return dispatch => {
    dispatch({
      type: 'SET_ZIP_CODES',
      payload: [],
    });
  };
}

function fetchZipCodes(filter, requireAuthentication = true) {
  return async dispatch => {
    let response;
    let payload;
    try {
      if (requireAuthentication) {
        response = await jobRepository.getZipCodes(filter);
      } else {
        response = await jobRepository.getSmartjobZipCodes(filter);
      }
      payload = response.data;
      dispatch({
        type: 'SET_ZIP_CODES',
        payload,
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'ZIPCODES_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function fetchLocations(filter, requireAuthentication = true) {
  const newFilter = _.cloneDeep(filter);
  newFilter.search = newFilter?.search?.replace(/,(\s+)?$/, '');
  return async dispatch => {
    let response;
    let payload;
    dispatch({
      type: 'FETCH_LOCATION_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      if (requireAuthentication) {
        response = await jobRepository.getLocations(newFilter);
      } else {
        response = await jobRepository.getSmartjobLocations(newFilter);
      }
      payload = response.data;
      dispatch({
        type: 'SET_LOCATIONS',
        payload,
      });
      dispatch({
        type: 'SET_MANUAL_ZIP_CODE',
        payload: {},
      });
      dispatch({
        type: 'SET_ZIP_CODE_STATUS',
        payload: false,
      });
      dispatch({
        type: 'FETCH_LOCATION_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'LOCATIONS_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch({
        type: 'FETCH_LOCATION_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

const fetchJobIdUsingJobCode = ({ jobCode }) => {
  return async dispatch => {
    try {
      const response = await jobRepository.getJobIdUsingJobCode({ jobCode });
      return { jobId: response.data };
    } catch (error) {
      const errorMessage = mapErrorCodes(error);
      dispatch(setNotification('ERROR', errorMessage));
      return {
        jobId: -1,
      };
    }
  };
};

function fetchIndustries(filter, requireAuthentication = true) {
  return async dispatch => {
    let response;
    let payload;
    try {
      if (requireAuthentication) {
        response = await jobRepository.getIndustries(filter);
      } else {
        response = await jobRepository.getSmartjobIndustries(filter);
      }
      payload = response.data;
      dispatch({
        type: 'SET_INDUSTRIES',
        payload,
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'INDUSTRIES_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

export function fetchOccupations(filter) {
  return async dispatch => {
    let response;
    let payload;
    try {
      response = await jobRepository.getOccupations(filter);
      payload = response.data;
      dispatch({
        type: 'SET_OCCUPATIONS',
        payload,
      });
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'OCCUPATION_FETCH_FAILED',
          timeStamp: new Date(),
        },
      });
    }
  };
}

function fetchJobTags(filter) {
  return (dispatch, getState) => {
    const store = getState();
    const isJobTagEnabled = _.get(store, ['FeatureToggleReducer', 'JobTag', 'IsEnabled'], false);
    if (!isJobTagEnabled) {
      return Promise.resolve();
    }
    dispatch({
      type: 'SET_TAGS_FOR_JOB_LIST',
      payload: [],
    });
    return jobRepository
      .fetchJobTags(filter)
      .then(response => {
        dispatch({
          type: 'SET_TAGS_FOR_JOB_LIST',
          payload: _.get(response, ['data', 'Aggregations', 'Tag'], {}),
        });
        return response.data;
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SET_TAGS_FOR_JOB_LIST_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchClients(filter) {
  return dispatch => {
    jobRepository
      .getClients(filter)
      .then(response => {
        dispatch({
          type: 'SET_CLIENTS',
          payload: {
            clients: response.data.Clients,
            total: response.data.Total,
            searchTerm: filter.SearchTerm,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'CLIENTS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchJobIntel(jobId) {
  return dispatch => {
    jobRepository
      .fetchJobIntel(jobId)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_JOB_INTEL',
          payload,
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOB_INTEL_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchJobIntelLocationData(jobId) {
  return dispatch => {
    jobRepository.fetchJobIntelLocationData(jobId).then(response => {
      const payload = response.data;
      dispatch({
        type: 'SET_JOB_INTEL_LOCATION_DATA',
        payload,
      });
    });
  };
}

function shareJob(jobId, shareOptions, redirectOptions) {
  return dispatch => {
    jobRepository
      .shareJob(jobId, shareOptions)
      .then(() => {
        dispatch({
          type: 'SET_JOB_SHARED_WITH',
          payload: shareOptions.sharedWith,
          jobId,
        });
        if (redirectOptions) {
          redirectOptions.history.push(redirectOptions.redirectUrl);
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SHARE_JOB_POST_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function updateJobIntelligence(jobId, intelligenceData, showNotification = true) {
  return dispatch => {
    dispatch(setUpdateJobIntelligenceApiStatus('INPROGRESS'));
    jobRepository
      .updateJobIntelligence(jobId, intelligenceData)
      .then(() => {
        if (showNotification) {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'SUCCESS',
              Message: 'Successfully saved',
            },
          });
        }
        dispatch({
          type: 'SET_JOB_DETAILS',
          payload: {
            quickSearchParams: intelligenceData,
            JobId: jobId,
          },
        });
        dispatch(setMustHavesForJob(jobId, intelligenceData?.Keywords?.Musts));
        dispatch(setUpdateJobIntelligenceApiStatus('COMPLETED'));
      })
      .catch(() => {
        dispatch(setUpdateJobIntelligenceApiStatus('FAILED'));
        if (showNotification) {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Failed to update the job intel',
            },
          });
        }
      });
  };
}

function getUserGivenIntel(jobId) {
  return dispatch => {
    dispatch({
      type: 'SET_FETCH_USER_GIVEN_INTEL_API_STATUS',
      payload: 'INPROGRESS',
    });
    jobRepository
      .fetchUserGivenIntel(jobId)
      .then(response => {
        dispatch({
          type: 'SET_JOB_DETAILS',
          payload: {
            quickSearchParams: response.data,
            JobId: jobId,
          },
        });
        dispatch({
          type: 'SET_FETCH_USER_GIVEN_INTEL_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_FETCH_USER_GIVEN_INTEL_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function uploadJobResume(jobId, resumeFile) {
  const formData = new FormData();
  formData.set('file', resumeFile);
  return dispatch => {
    jobRepository.uploadJobResume(jobId, formData).then(response => {
      message.success('Resume uploaded successfully');
      dispatch({
        type: 'ADD_RESUME_FOR_JOB',
        payload: {
          jobId,
          resume: {
            Id: response.data.Id,
            Name: resumeFile.name,
            MediaType: resumeFile.type,
          },
        },
      });
    });
  };
}

function downloadJobResume(jobId, resumeId, resumeName) {
  return () => {
    jobRepository.downloadJobResume(jobId, resumeId).then(response => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const downloadedFileName = resumeName ? resumeName.trim() : 'Resume';
      FileSaver.saveAs(blob, downloadedFileName);
    });
  };
}

function deleteJobResume(jobId, resumeId) {
  return dispatch => {
    jobRepository.deleteJobResume(jobId, resumeId).then(() => {
      dispatch({
        type: 'DELETE_RESUMES_FOR_JOB',
        payload: {
          jobId,
          resumeIds: [resumeId],
        },
      });
    });
  };
}

function searchZipCode(filter) {
  return dispatch => {
    dispatch({
      type: 'SET_ZIP_CODE_STATUS',
      payload: false,
    });
    jobRepository
      .searchZipCode(filter)
      .then(response => {
        const location = _.get(response, ['data', 0], false);
        if (location) {
          dispatch({
            type: 'SET_MANUAL_ZIP_CODE',
            payload: location,
          });
          dispatch({
            type: 'SET_LOCATIONS',
            payload: [location],
          });
          dispatch({
            type: 'SET_ZIP_CODE_STATUS',
            payload: false,
          });
          dispatch({
            type: 'SET_ZIP_CODES',
            payload: [filter.postalCode],
          });
        } else {
          dispatch({
            type: 'SET_ZIP_CODE_STATUS',
            payload: true,
          });
          dispatch({
            type: 'SET_MANUAL_ZIP_CODE',
            payload: {},
          });
          dispatch({
            type: 'SET_LOCATIONS',
            payload: [],
          });
        }
      })
      .catch(errorResponse => {
        const isRequestCancelled = errorResponse.message === 'Request Cancelled';
        if (!isRequestCancelled) {
          dispatch({
            type: 'SET_ZIP_CODE_STATUS',
            payload: true,
          });
          dispatch({
            type: 'SET_MANUAL_ZIP_CODE',
            payload: {},
          });
          dispatch({
            type: 'SET_LOCATIONS',
            payload: [],
          });
        }
      });
  };
}

function clearZipCodeError() {
  return dispatch => {
    dispatch({
      type: 'SET_ZIP_CODE_STATUS',
      payload: false,
    });
    dispatch({
      type: 'SET_MANUAL_ZIP_CODE',
      payload: {},
    });
    dispatch({
      type: 'SET_LOCATIONS',
      payload: [],
    });
    dispatch({
      type: 'SET_ZIP_CODES',
      payload: [],
    });
  };
}

function clearZipValidationError() {
  return dispatch => {
    dispatch({
      type: 'SET_ZIP_CODE_STATUS',
      payload: false,
    });
  };
}

function fetchJobConfig(jobId) {
  return dispatch => {
    jobRepository
      .fetchJobConfig(jobId)
      .then(res => {
        dispatch({
          type: 'SET_JOB_CONFIG',
          payload: {
            data: res.data,
            jobId,
          },
        });
        const emailTemplateId = _.get(res, ['data', 'AutomationConfiguration', 'Email', 'TemplateId'], false);
        const isDrip = _.get(res, ['data', 'AutomationConfiguration', 'Email', 'IsDrip'], false);
        const messageTemplateId = _.get(res, ['data', 'AutomationConfiguration', 'Message', 'TemplateId'], false);
        if (emailTemplateId && !isDrip) {
          dispatch(getEmailTemplate(emailTemplateId));
        } else if (emailTemplateId && isDrip) {
          dispatch(getDripTemplate(emailTemplateId));
        }
        if (messageTemplateId) {
          dispatch(getTextTemplate(messageTemplateId));
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: 'Unable to fetch JobConfig',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function distributeJob(jobId, payload) {
  return dispatch => {
    dispatch(
      setDistributeJobApiStatus(jobId, {
        status: 'INPROGRESS',
      })
    );
    jobRepository
      .distributeJob(jobId, payload)
      .then(() => {
        dispatch(
          setDistributeJobApiStatus(jobId, {
            status: 'COMPLETED',
          })
        );
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: 'Job distributed successfully!',
          },
        });
      })
      .catch(err => {
        if (err) {
          const status = {
            status: 'FAILED',
            error: _.get(err, 'response'),
          };
          dispatch(setDistributeJobApiStatus(jobId, status));
          let errorMessage = null;
          if (_.get(err, ['response', 'status'], null) === 404) {
            errorMessage = "You don't have permission to access this job.";
          } else {
            errorMessage = 'Oops, something just went wrong. Please try again.';
          }
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: errorMessage,
            },
          });
        }
      });
  };
}

function clearDistributeJobApiStatus(jobId) {
  return dispatch => {
    dispatch(setDistributeJobApiStatus(jobId, {}));
  };
}

function fetchJobDistributionStatus(jobId) {
  return dispatch => {
    dispatch({
      type: 'SET_JOB_DISTRIBUTION_STATUS_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    jobRepository
      .fetchJobDistributionStatus(jobId)
      .then(response => {
        const payload = response.data;
        dispatch(setJobDistributionStatus(jobId, payload));
        dispatch({
          type: 'SET_JOB_DISTRIBUTION_STATUS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(err => {
        const status = {
          status: 'FAILED',
          error: _.get(err, 'response'),
        };
        dispatch({
          type: 'SET_JOB_DISTRIBUTION_STATUS_API_STATUS',
          payload: status,
        });
      });
  };
}

function fetchSkills(title) {
  return dispatch => {
    dispatch({
      type: 'UPDATE_SKILLS_FETCH_API_STATUS',
      payload: 'INPROGRESS',
    });
    dispatch({
      type: 'SET_SKILLS',
      payload: [],
    });
    smartJobRepository
      .fetchSkills(title)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_SKILLS',
          payload,
        });
        dispatch({
          type: 'UPDATE_SKILLS_FETCH_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'FETCH_SKILL_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'UPDATE_SKILLS_FETCH_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function fetchJobTitles(filter) {
  return dispatch => {
    dispatch({
      type: 'FETCH_TITLE_API_STATUS',
      payload: 'INPROGRESS',
    });
    smartJobRepository
      .fetchTitle(filter)
      .then(response => {
        const payload = response.data;
        dispatch({
          type: 'SET_TITLES',
          payload,
        });
        dispatch({
          type: 'FETCH_TITLE_API_STATUS',
          payload: 'COMPLETED',
        });
      })
      .catch(() => {
        dispatch({
          type: 'FETCH_TITLE_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}

function resetJobTitles() {
  return dispatch => {
    dispatch({
      type: 'SET_TITLES',
      payload: {},
    });
  };
}

function setJobUtilities(data) {
  return dispatch => {
    dispatch({
      type: 'SET_JOB_UTILITIES',
      payload: data,
    });
  };
}

function saveJobDiversityChanges(jobDiversityChanges) {
  return () => {
    jobRepository.saveJobDiversityChanges(jobDiversityChanges);
  };
}
function createSegment(jobDetails, onSegmentCreationCallback) {
  return async (dispatch, getState) => {
    dispatch(setCreateSegmentApiStatus('INPROGRESS'));
    try {
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
      const userRole = getConfig(store).Role;
      const response = await jobRepository.createSegment(jobDetails, { isPaidJobServiceEnabled, role: userRole });
      const payload = response.data;
      dispatch({
        type: 'CREATE_SEGMENT',
        payload,
      });
      const segmentId = payload.JobId;
      dispatch(setCreateSegmentApiStatus('COMPLETED'));
      onSegmentCreationCallback(segmentId);
      dispatch(setJobListPageNumber(1));
      dispatch(setJobListSearchTerm('', 'segment'));
      return response;
    } catch {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'SEGMENT_POST_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setCreateSegmentApiStatus('FAILURE'));
      message.error('Failed to create, please try again');
      throw Error('Failed to create, please try again');
    }
  };
}
function putEditSegment(jobId, jobDetails, onSegmentCreationCallback) {
  return async (dispatch, getState) => {
    dispatch(setPutEditSegmentApiStatus('INPROGRESS'));
    try {
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
      const userRole = getConfig(store).Role;
      await jobRepository.putEditSegment(jobId, jobDetails, { isPaidJobServiceEnabled, role: userRole });
      const payload = { jobDetails, jobId };
      dispatch({
        type: 'UPDATE_SEGMENT_PUT',
        payload,
      });
      dispatch(setPutEditSegmentApiStatus('COMPLETED'));
      const segmentId = jobId;
      onSegmentCreationCallback(segmentId);
      dispatch(setNotification('SUCCESS', 'Segment edited successfully'));
      return jobDetails;
    } catch (e) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          code: 'SEGMENT_PUT_FAILED',
          timeStamp: new Date(),
        },
      });
      dispatch(setPutEditSegmentApiStatus('FAILED'));
      message.error('An error occured while saving your input. Please recheck and try again');
      throw e;
    }
  };
}
function clearJobList() {
  return dispatch => {
    dispatch({ type: 'CLEAR_JOB_LIST' });
  };
}

function fetchSegmentList(filter) {
  return dispatch => {
    dispatch({
      type: 'FETCH_SEGMENT_LIST_API_STATUS',
      payload: 'INPROGRESS',
    });
    const acceptableKeys = ['skip', 'count', 'isassigned', 'isjobstatsrequired', 'entitytype', 'statusid'];
    const payloadValues = filterEmptyValuesFromObject(filter);
    const shouldMakeGetCall = Object.keys(payloadValues)?.every(key => acceptableKeys.includes(key.toLowerCase()));
    let jobListFetch;
    if (shouldMakeGetCall) {
      jobListFetch = jobRepository.getJobList(payloadValues);
    } else {
      jobListFetch = jobRepository.fetchJobList(payloadValues);
    }
    jobListFetch
      .then(response => {
        const jobs = _.get(response, ['data', 'Jobs'], []);
        const totalCount = _.get(response, ['data', 'Total'], 0);
        const jobGuidsToJobIdMapping = {};
        jobs.forEach(job => {
          jobGuidsToJobIdMapping[job.JobGuid] = job.JobId;
        });
        dispatch({
          type: 'SET_SEGMENT_JOB_GUID_TO_ID_MAPPING',
          payload: jobGuidsToJobIdMapping,
        });
        dispatch({
          type: 'SET_SEGMENT_LIST',
          payload: {
            statusId: filter.statusId,
            status: filter.status,
            totalCount,
            jobs,
          },
        });
        dispatch({
          type: 'FETCH_SEGMENT_LIST_API_STATUS',
          payload: 'COMPLETED',
        });

        let sharedWith = [];
        jobs.forEach(jobs => {
          if (jobs.SharedWith && jobs.SharedWith.length > 0) {
            sharedWith = sharedWith.concat(jobs.SharedWith);
          }
          if (jobs.AssignedTo && jobs.AssignedTo.length > 0) {
            sharedWith = sharedWith.concat(jobs.AssignedTo);
          }
        });
        sharedWith = sharedWith.filter(userId => !!userId);
        if (sharedWith && sharedWith.length > 0) {
          sharedWith = _.uniq(sharedWith);
          dispatch(
            fetchUsers({
              userIds: sharedWith,
            })
          );
        }
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'SEGMENT_LIST_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'FETCH_SEGMENT_LIST_API_STATUS',
          payload: 'FAILED',
        });
      });
  };
}
const _fetchJobs = filter => {
  // we shouldn't be setting locations data in store
  return async () => {
    try {
      const response = await jobRepository.fetchJobList(filter);
      return response.data;
    } catch {
      return [];
    }
  };
};
const fetchJobAutomationConfiguration = filter => {
  return async dispatch => {
    dispatch({
      type: 'SET_FETCH_AUTOMATION_CONFIGURATION_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const response = await jobRepository.fetchJobAutomationConfiguration(filter);
      const payload = response.data;
      dispatch({
        type: 'SET_JOB_AUTOMATION_CONFIGURATION',
        payload,
      });
      dispatch({
        type: 'SET_FETCH_AUTOMATION_CONFIGURATION_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'SET_FETCH_AUTOMATION_CONFIGURATION_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
};

function setCurrentUserCandidateViewedFlag(candidateViewStausFlag, jobId) {
  return dispatch => {
    dispatch(setShowCurrentUserCandidateViewedFlag(candidateViewStausFlag, jobId));
  };
}

function fetchCandidateRecommendationStatus({ jobId, action }) {
  return async dispatch => {
    try {
      const response = await jobRepository.fetchCandidateRecommendationStatus(jobId, action);
      dispatch(setCandidatesRecommendationStatus({ jobId, data: response.data }));
    } catch (error) {
      dispatch(setNotification('ERROR', 'Oops! Something went wrong.'));
    }
  };
}

function setMinimizeManualSearchStatus(jobId, status) {
  return setMinimizeManualSearchAction(jobId, status);
}

function fetchBasicJobsInfo(filter) {
  return dispatch => {
    jobRepository
      .fetchJobBasicInfoList(filter)
      .then(response => {
        const jobs = _.get(response, ['data'], []);
        dispatch(setJobsBasicInfoList(jobs));
      })
      .catch(() => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'JOB_LIST_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
      });
  };
}

function setErrorMessage(dispatch, errorMesssage) {
  dispatch({
    type: 'SET_NOTIFICATION',
    payload: {
      Type: 'ERROR',
      Message: errorMesssage,
    },
  });
}

export {
  fetchJobList,
  fetchJobCount,
  getAllJobNotes,
  createJobNote,
  updateJobNote,
  deleteJobNote,
  clearJobNotes,
  fetchJobSourcingStats,
  changeJobStatus,
  fetchJobDetails,
  fetchActivationStatus,
  activatePortals,
  createJob,
  patchEditJob,
  putEditJob,
  duplicateJob,
  updateTotalTagsCount,
  fetchTagsForJobForm,
  fetchTitleSynonyms,
  fetchCountries,
  fetchCountryDetails,
  fetchLocations,
  fetchZipCodes,
  resetZipcodes,
  fetchClients,
  fetchConnectedJobs,
  fetchIndustries,
  fetchJobIntel,
  fetchJobIntelLocationData,
  fetchJobResumes,
  deleteJobResume,
  shareJob,
  updateJobIntelligence,
  getUserGivenIntel,
  clearTitleSynonyms,
  uploadJobResume,
  bulkUploadJobResumes,
  bulkDeleteJobResumes,
  downloadJobResume,
  fetchOrgActivatedJobsCount,
  searchZipCode,
  clearZipCodeError,
  clearZipValidationError,
  fetchJobConfig,
  updateJobConfig,
  setActivationStateChanged,
  toggleJobCard,
  updateFilterTags,
  fetchJobTags,
  setCurrentJobId,
  fetchJobs,
  distributeJob,
  clearDistributeJobApiStatus,
  fetchJobDistributionStatus,
  fetchSkills,
  setJobListPageSize,
  clearLocations,
  setJobListPageNumber,
  setJobListSearchTerm,
  setJobListSelectedUser,
  setDefaultHomePageRenderFlag,
  setJobListTabName,
  fetchBulkJobTags,
  fetchJobTitles,
  setTrynowFormData,
  setAdvancedJobFormData,
  resetJobTitles,
  setJobUtilities,
  undoJobCandidateAutoReject,
  saveJobDiversityChanges,
  fetchJobIdUsingJobCode,
  createSegment,
  fetchSegmentList,
  putEditSegment,
  clearJobList,
  addTagForJobNote,
  _fetchJobs,
  deleteTagFromJobNote,
  fetchJobAutomationConfiguration,
  setCurrentUserCandidateViewedFlag,
  fetchCandidateRecommendationStatus,
  setMinimizeManualSearchStatus,
  fetchBasicJobsInfo,
};
