import React from 'react';
import { connect } from 'react-redux';
import { JobMatchingModal } from './JobMatchingModal';
import * as CandidateActions from '../../Actions/CandidateActions';
import {
  getCandidateJobs,
  getCandidateMatchingJobs,
  getCandidateMatchingJobHighlights,
  getCandidateMatchingJobsTotalCount,
  getCandidateMatchingJobsAggregations,
  getCandidateMatchingJobSearchTerm,
  getCandidateMatchingJobFilters,
  getCandidateMatchingJobDetails,
} from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCountries } from '../../Reducers/JobReducer';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';

const mapStateToProps = state => ({
  candidateMatchingJobs: getCandidateMatchingJobs(state),
  candidateMatchingJobHighlights: getCandidateMatchingJobHighlights(state),
  candidateMatchingJobDetails: getCandidateMatchingJobDetails(state),
  candidateMatchingJobsTotalCount: getCandidateMatchingJobsTotalCount(state),
  candidateJobs: getCandidateJobs(state),
  candidateMatchingJobsApiStatus: getApiStatus(state, 'candidateMatchingJobsApiStatus'),
  sourceCandidateStatusUpdate: getApiStatus(state, 'sourceCandidateJobMatchingStatusUpdate'),
  matchingJobsAggregations: getCandidateMatchingJobsAggregations(state),
  countries: getCountries(state),
  currentUser: getCurrentUserDetails(state),
  candidateMatchingJobSearchTerm: getCandidateMatchingJobSearchTerm(state),
  candidateMatchingJobFilters: getCandidateMatchingJobFilters(state),
});

const mapDispatchToProps = {
  recommendCandidate: CandidateActions.recommendCandidate,
  setCandidateMatchingJobSearchTerm: CandidateActions.setCandidateJobMatchingListSearchTerm,
  setCandidateMatchingJobFilters: CandidateActions.setCandidateMatchingJobFilters,
};

function JobMatchingModalWrapper(props) {
  const {
    setCandidateMatchingJobModalVisiblity,
    candidateMatchingJobsTotalCount,
    candidateMatchingJobs,
    candidateMatchingJobsApiStatus,
    fetchCandidateMatchingJobs,
    candidateMatchingJobHighlights,
    candidateMatchingJobDetails,
    candidate,
    recommendCandidate,
    sourceCandidateStatusUpdate,
    matchingJobsAggregations,
    version,
    isMatchingVisible,
    isFilterPopoverVisible,
    setIsFilterPopoverVisbility,
    countries,
    candidateJobMatchingInitialAggregation,
    candidateJobsTabRef,
    currentUser,
    isATSViewActive,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    setIsFindMathingJobClicked,
    setCandidateMatchingJobSearchTerm,
    candidateMatchingJobSearchTerm,
    candidateMatchingJobFilters,
    setCandidateMatchingJobFilters,
  } = props;

  const {
    matchingJobTitleFilter,
    matchingJobLocationFilter,
    matchingJobIndustryFilter,
    matchingJobMinExperienceFilter,
    matchingJobMaxExperienceFilter,
  } = candidateMatchingJobFilters;
  return (
    <JobMatchingModal
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
      isMatchingVisible={isMatchingVisible}
      setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
      candidateMatchingJobs={candidateMatchingJobs}
      candidateMatchingJobHighlights={candidateMatchingJobHighlights}
      candidateMatchingJobDetails={candidateMatchingJobDetails}
      candidateMatchingJobsTotalCount={candidateMatchingJobsTotalCount}
      candidateMatchingJobsApiStatus={candidateMatchingJobsApiStatus}
      fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
      candidate={candidate}
      recommendCandidate={recommendCandidate}
      sourceCandidateStatusUpdate={sourceCandidateStatusUpdate}
      matchingJobsAggregations={matchingJobsAggregations}
      version={version}
      isFilterPopoverVisible={isFilterPopoverVisible}
      setIsFilterPopoverVisbility={setIsFilterPopoverVisbility}
      countries={countries}
      candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
      candidateJobsTabRef={candidateJobsTabRef}
      currentUser={currentUser}
      isATSViewActive={isATSViewActive}
      setIsFindMathingJobClicked={setIsFindMathingJobClicked}
      setSearchTerm={setCandidateMatchingJobSearchTerm}
      searchTerm={candidateMatchingJobSearchTerm}
      selectedMatchingJobLocationFilterOptions={matchingJobLocationFilter}
      selectedMatchingJobTitleFilterOptions={matchingJobTitleFilter}
      selectedMatchingJobIndustryFilterOptions={matchingJobIndustryFilter}
      selectedMatchingJobMinExperienceFilterOptions={matchingJobMinExperienceFilter}
      selectedMatchingJobMaxExperienceFilterOptions={matchingJobMaxExperienceFilter}
      setCandidateMatchingJobFilters={setCandidateMatchingJobFilters}
    />
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(JobMatchingModalWrapper);
export { JobMatchingModalWrapper as JobMatchingModalWrapperWithoutStore };
