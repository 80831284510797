import React from 'react';
import { EmptyCandidateIntelIcon } from '../../Icons/AryaIcons';
import './CandidateIntel.scss';

export default function NoCandidateIntel() {
  return (
    <div className="candidate-intel-not-found">
      <div className="empty-candidate-intel-icon">
        <EmptyCandidateIntelIcon />
      </div>
      <span className="empty-candidate-intel-text"> No Score Details found</span>
    </div>
  );
}
