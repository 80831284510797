/* eslint-disable no-param-reassign */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Tooltip } from 'antd';
import { generateKeywordsFromBooleanString } from './BooleanStringUtility';
import { getCandidateListPageSize } from './LocalStorageUtils';
import {
  getInternalSourceWithCandidateId,
  getAtsSource,
  getSourceDisplayName,
  getPushedAtsSource,
  getCandidateSource,
  getPortalName,
  getCandidateSourceName,
} from './SourceUtils';
import { isPulseUser } from './ConfigUtils';
import { NotesFilledIcon, NotesAvailableIcon, ClockCircleIcon } from '../Icons/AryaIcons';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';

export const REJECTED = 'rejected';
export const SERVICE_REP = 'Service Rep';
export const PULSE_USER = 'PulseUser';
export const allowedDeDuplicationSources = ['All', 'Active'];
export const relocationStatusLabels = {
  Relocate: 'Relocate',
  WontRelocate: "Won't relocate",
  NotMentioned: 'Not mentioned',
};
export const lastActivityLabels = {
  FewHoursAgo: 'Few hours ago',
  LastOneDay: 'Last 1 day',
  LastThreeDays: 'Last 3 days',
  LastWeek: 'Last week',
  LastFortnight: 'Last fortnight',
  LastMonth: 'Last month',
  LastThreeMonths: 'Last 3 months',
  LastSixMonths: 'Last 6 months',
  LastYear: 'Last year',
  LastTwoYears: 'Last 2 years',
  LastFiveYears: 'Last 5 years',
  MoreThanFiveYearsAgo: 'More than 5 years ago',
};
export const allTabDefaultCandidatesFilters = {
  Skills: [],
  Titles: [],
  RelocationStatus: [],
  LastActivity: [],
  Location: [],
  Source: [],
};

export const allTabFiltersDefaultKeywordsOperation = 'or';

const isPulse = isPulseUser();
const getQuickSearchKeywords = currentJobDetails => {
  const jobQuickSearchParams = currentJobDetails.quickSearchParams;
  const keywords = jobQuickSearchParams?.Keywords;
  const musts = keywords?.Musts;
  const prefres = keywords?.Prefers;
  const quickSearchTitles = jobQuickSearchParams?.Titles ?? [];
  const quickSearchQueryString = (jobQuickSearchParams?.QueryString ?? '')
    .replace(/[()"']/gi, '')
    .replace(/(\s+OR\s+)|(\s+AND\s+)/gi, ',')
    .split(',')
    .filter(keyword => keyword.trim() !== '');
  const quickSearchPrefers =
    (typeof prefres === 'string' ? prefres.split('OR') : prefres)
      ?.map(keyword => _.trim(keyword, '"() '))
      .filter(keyword => keyword.trim() !== '') ?? [];
  const quickSearchMusts = generateKeywordsFromBooleanString(musts);
  return [...quickSearchTitles, ...quickSearchQueryString, ...quickSearchMusts, ...quickSearchPrefers];
};

const getKeyWordsToHighlight = ({ mustHaves, niceToHaves, currentJobDetails }) => {
  const quickSearchKeywordsToHighlight = currentJobDetails ? getQuickSearchKeywords(currentJobDetails) : [];
  const jobTitle = currentJobDetails ? currentJobDetails.JobTitle : '';
  const jobSynonyms = currentJobDetails ? currentJobDetails.Synonyms : [];

  const mustHavesKeywords = [...(_.flatten(mustHaves) || [])];
  const allkeywordsToHighlight = [
    ...(niceToHaves ? niceToHaves.split(',') : []),
    jobTitle || '',
    ...(jobSynonyms || []),
    ...quickSearchKeywordsToHighlight,
  ];
  return { mustHavesKeywords, allkeywordsToHighlight };
};

const getCandidatesFilterWithStatus = (candidatesFilter, status) => {
  const candidateFilterWithStatus = _.cloneDeep(candidatesFilter);
  if (status?.toLowerCase() === 'applied') {
    candidateFilterWithStatus.Applied = true;
    candidateFilterWithStatus.Statuses = [];
  } else candidateFilterWithStatus.Statuses = status ? [status] : [];
  return candidateFilterWithStatus;
};

const getDefaultPageSize = (currentUser, candidateContext = 'job') => {
  const currentUserEmail = currentUser?.email;
  return getCandidateListPageSize({ currentUserEmail, candidateContext });
};

const getCandidateStatusForSegment = candidateStatus => {
  switch (candidateStatus?.toLowerCase()) {
    case 'shortlisted':
      return 'Applied';
    case 'rejected':
      return 'Removed';
    default:
      return 'Sourced';
  }
};

export function getCandidateBucketStatus(candidate) {
  const atsSource = getInternalSourceWithCandidateId(candidate?.Sources);
  const candidateStatus = _.get(atsSource, 'Status');
  if (candidateStatus || candidate?.AssignedStatus || candidate?.AppliedTime) {
    return 'Applied';
  }
  if (candidate?.ConnectionStatus?.toLowerCase() === 'connected') {
    return 'Connected';
  }
  return candidate?.Status;
}

export function getCurrentCandidateBucket(location) {
  const regex = /jobs\/\d+\/candidates$/;
  if (location && regex.test(location.pathname)) {
    const params = queryString.parse(location.search);
    const { status } = params;
    return status;
  }
  return null;
}

export function getCandidatePrimarySourceNameToDisplay(
  candidate,
  version,
  showVaultName,
  whiteLabelInfo,
  revealActiveChannelSourceName,
  userConfig
) {
  let candidateSourceName;
  let atsSource;
  let _atsSource;
  let candidateSource;
  if (version === 'ats') {
    _atsSource = getInternalSourceWithCandidateId(candidate.Sources);
    atsSource = getAtsSource(candidate.Sources);
    const _candidateSourceName = getSourceDisplayName(
      atsSource || getCandidateSource(candidate),
      userConfig,
      showVaultName,
      {},
      whiteLabelInfo,
      revealActiveChannelSourceName
    );
    const candidatePortalName = getPortalName(candidate.Sources || []);
    candidateSourceName =
      candidatePortalName && candidatePortalName.toLowerCase() === 'internal' && _atsSource
        ? getSourceDisplayName(_atsSource, userConfig, showVaultName, {}, whiteLabelInfo, revealActiveChannelSourceName)
        : _candidateSourceName;
    candidateSource = _atsSource;
  } else {
    atsSource = getAtsSource(candidate.Sources);
    candidateSource = getPushedAtsSource(candidate.Sources);
    candidateSourceName = getSourceDisplayName(
      atsSource || getCandidateSource(candidate),
      userConfig,
      showVaultName,
      {},
      whiteLabelInfo,
      revealActiveChannelSourceName
    );
  }
  return { candidateSourceName, candidateSource };
}

const isCandidateStateChangeAllowed = candidate => {
  return ((candidate.Status !== 'Rejected' || !candidate.IsPublished) && isPulse) || !isPulse;
};

const isPublishIconDisplayAllowed = (isBulkRecommendEnabled, activeTab, candidateContext) => {
  return (
    isBulkRecommendEnabled &&
    candidateContext === 'job' &&
    ((isPulse && activeTab !== 'rejected') || !isPulse) &&
    !['shortlisted', 'contacted', 'connected', 'applied', 'failed'].includes(activeTab)
  );
};

const getCandidateCardCursorStyle = (candidateStatus, publishedStatus) => {
  return publishedStatus === 'Rejected' && candidateStatus === 'Rejected' && isPulseUser()
    ? { cursor: 'default' }
    : { cursor: 'pointer' };
};

const getExperienceTimeline = experience => {
  if (!experience.From || !experience.To) {
    return null;
  }
  return `${moment(experience.From).format('MMM YYYY ')} - ${
    moment().isSame(experience.To, 'day') ? ' Present' : moment(experience.To).format(' MMM YYYY')
  }`;
};

const getExperienceDuration = experience => {
  if (!experience.From || !experience.To) {
    return null;
  }
  const years = moment(experience.To).diff(moment(experience.From), 'years');
  const refinedYears = Math.abs(years);
  return years === 0 ? '< 1 year' : `${refinedYears}+ yrs`;
};

const getCandidate360NotesIcon = ({ isCandidateNotesAvailable }) => {
  return isCandidateNotesAvailable ? <NotesAvailableIcon /> : <NotesFilledIcon />;
};

export const getLastActive = candidate => {
  const { ProfileDate, LastActivityDate } = candidate;
  let lastActive = ProfileDate ?? LastActivityDate;
  const isProfileDateValid = moment.utc().local().diff(moment.utc(ProfileDate).local(), 'seconds') > 0;

  const isLastActivityDateValid = moment.utc().local().diff(moment.utc(LastActivityDate).local(), 'seconds') > 0;

  if (LastActivityDate && ProfileDate && isProfileDateValid && isLastActivityDateValid) {
    lastActive =
      moment.utc(LastActivityDate).local().diff(moment.utc(ProfileDate).local(), 'seconds') > 0
        ? LastActivityDate
        : ProfileDate;
  }

  if (LastActivityDate && ProfileDate && isProfileDateValid && !isLastActivityDateValid) {
    lastActive = ProfileDate;
  }

  if (LastActivityDate && ProfileDate && !isProfileDateValid && isLastActivityDateValid) {
    lastActive = LastActivityDate;
  }
  return lastActive;
};

export const getLatestProfileDateMarkup = (
  candidate,
  showClockIcon = true,
  isLatestCandidateCardVisible = false,
  style = {}
) => {
  const lastActive = getLastActive(candidate);
  if (!lastActive) {
    return null;
  }
  const localCurrentDate = moment();
  const localLastActive = moment.utc(lastActive).local();
  const diffInMonths = localCurrentDate.diff(localLastActive, 'months');
  if (diffInMonths >= 120) {
    return null;
  }
  let lastActiveText = <span style={style}>{` Updated: ${localLastActive.fromNow()}`}</span>;

  lastActiveText = ['social', 'arya passive'].includes(isLatestCandidateCardVisible) ? (
    <span style={style}>{` Refreshed by Arya: ${localLastActive.fromNow()}`}</span>
  ) : (
    lastActiveText
  );

  const inlineStyle = { display: 'flex', alignItems: 'center', gap: '4px' };

  return (
    <Tooltip
      overlayClassName="last-activity-tooltip"
      title={`${localLastActive.format(
        'll'
      )}: Activity is measured by resume upload or profile refresh or job board engagement. (Whichever is the most recent)`}
      mouseEnterDelay={0.5}
    >
      <span className="resume-date-info" style={inlineStyle}>
        {showClockIcon ? <ClockCircleIcon className="resume-date-icon" /> : null}
        {lastActiveText}
      </span>
    </Tooltip>
  );
};

export const getIsSelectThisPageChecked = (
  currentSelectedAllCandidates,
  aggregatedCandidates,
  isCurrentPageChecked,
  activeSourceName,
  duplicateCandidateIds,
  isExactMatchSearch
) => {
  const updatedAggregatedCandidates =
    allowedDeDuplicationSources.includes(activeSourceName) || isExactMatchSearch
      ? aggregatedCandidates.filter(candidate => {
          return !duplicateCandidateIds.some(filterItem => filterItem === candidate.CandidateId);
        })
      : aggregatedCandidates;

  let updatedCurrentSelectedAllCandidates;
  if (isExactMatchSearch) {
    // ? TODO : Need to do for general case as well with this condition
    updatedCurrentSelectedAllCandidates = currentSelectedAllCandidates.reduce((acc, current) => {
      const existingCandidate = acc.find(candidate => candidate.CandidateId === current.CandidateId);
      if (!existingCandidate) {
        acc.push(current);
      }
      return acc;
    }, []);
  } else {
    updatedCurrentSelectedAllCandidates = currentSelectedAllCandidates;
  }
  return (
    (currentSelectedAllCandidates?.length &&
      _.isEqual(updatedCurrentSelectedAllCandidates, updatedAggregatedCandidates)) ||
    isCurrentPageChecked
  );
};

const getCurrentSourceConfig = (candidate, userConfig) => {
  const candidateSource = getCandidateSource(candidate);
  const candidateSourceName = getCandidateSourceName(candidateSource, true);
  return userConfig?.SourceConfigsByName?.[candidateSourceName?.toLowerCase()];
};

const handleFavouriteCandidateIconClick = async ({
  candidate,
  recommendFavouriteCandidate,
  userConfig,
  jobId,
  fetchJobSourcingStats,
  fetchCandidates,
  removeFavouriteCandidate,
  defaultFilter,
}) => {
  if (candidate?.Status?.toLowerCase() === 'favourite') {
    await removeFavouriteCandidate({ aryaCandidateIds: [candidate.Id], jobId });
    fetchCandidates({ filter: defaultFilter, showLoader: false, invokationContext: 'defaultLoad' });
    return;
  }
  const sourceConfiguration = getCurrentSourceConfig(candidate, userConfig);
  const postData = {
    candidateId: candidate.Id,
    metadata: candidate,
    sourceConfigId: sourceConfiguration?.Id ?? null,
    sourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
    jobId,
  };
  await recommendFavouriteCandidate(postData);
  fetchJobSourcingStats([jobId]);
};

// Utility functions
const toLowercaseArray = arr => arr.map(item => item.toLowerCase());

const sortEntries = (entries, compareFn) => {
  return [...entries].sort(compareFn);
};

const sortMapEntries = (map, compareFn) => {
  return new Map(sortEntries(map.entries(), compareFn));
};

const getLocalDate = utcDate => moment.utc(utcDate).local();

const calculateDiffInMonths = (date1, date2) => date1.diff(date2, 'months');

export const parseActivityToDaysAgo = (activity = '') => {
  if (activity) {
    let [time, unit] = activity.split(' ');
    time = time === 'a' || time === 'an' ? 1 : parseInt(time, 10);
    const unitToDays = {
      hour: 0,
      hours: 0,
      day: time,
      days: time,
      month: time * 30,
      months: time * 30,
      year: time * 365,
      years: time * 365,
    };
    return unitToDays[unit] !== undefined ? unitToDays[unit] : Infinity;
  }
  return Infinity;
};

export const determineRange = daysAgo => {
  const ranges = [
    { days: 0, label: lastActivityLabels.FewHoursAgo },
    { days: 1, label: lastActivityLabels.LastOneDay },
    { days: 3, label: lastActivityLabels.LastThreeDays },
    { days: 7, label: lastActivityLabels.LastWeek },
    { days: 14, label: lastActivityLabels.LastFortnight },
    { days: 30, label: lastActivityLabels.LastMonth },
    { days: 90, label: lastActivityLabels.LastThreeMonths },
    { days: 180, label: lastActivityLabels.LastSixMonths },
    { days: 365, label: lastActivityLabels.LastYear },
    { days: 730, label: lastActivityLabels.LastTwoYears },
    { days: 1825, label: lastActivityLabels.LastFiveYears },
  ];
  const matchedLabels = [];

  ranges.forEach(range => {
    if (daysAgo <= range.days) {
      matchedLabels.push(range.label);
    }
  });

  if (!matchedLabels.length) {
    return [lastActivityLabels.MoreThanFiveYearsAgo];
  }

  return matchedLabels;
};

export const getRelocationStatusDisplayText = relocationStatus => {
  if (relocationStatus) return relocationStatusLabels.Relocate;
  if (relocationStatus === undefined) return relocationStatusLabels.NotMentioned;
  return relocationStatusLabels.WontRelocate;
};

export const getLocalLastActive = ({ ProfileDate, LastActivityDate }, localCurrentDate) => {
  const lastActive = getLastActive({ ProfileDate, LastActivityDate });
  if (!lastActive) return null;
  const localLastActive = getLocalDate(lastActive);
  const diffInMonths = calculateDiffInMonths(localCurrentDate, localLastActive);
  return diffInMonths >= 120 ? null : localLastActive.fromNow();
};

export const getNormalizedLastActivity = lastActive => {
  const daysAgo = parseActivityToDaysAgo(lastActive);
  return determineRange(daysAgo);
};

export const processAggregatedData = (aggregatedData, order, normalizeFn) => {
  return sortMapEntries(aggregatedData, ([keyA], [keyB]) => {
    const normalizedKeyA = normalizeFn(keyA);
    const normalizedKeyB = normalizeFn(keyB);

    const indexA = order.indexOf(normalizedKeyA);
    const indexB = order.indexOf(normalizedKeyB);
    if (indexA > -1 && indexB > -1) return indexA - indexB;
    if (indexA !== -1 && indexB !== -1) {
      const lengthA = aggregatedData.get(keyA).CandidateIds.length;
      const lengthB = aggregatedData.get(keyB).CandidateIds.length;
      return lengthB - lengthA;
    }
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;
    const lengthA = aggregatedData.get(keyA).CandidateIds.length;
    const lengthB = aggregatedData.get(keyB).CandidateIds.length;
    return lengthB - lengthA;
  });
};

export const mergeSkillsAndAliases = (skills, aliases) => {
  const combinedSkills = new Map([...skills]);
  const aliasEntries = Object.entries(aliases);

  for (const [mainSkill, aliasSkills] of aliasEntries) {
    if (combinedSkills.has(mainSkill)) {
      const mainSkillCandidates = combinedSkills.get(mainSkill).CandidateIds;

      aliasSkills.forEach(alias => {
        if (combinedSkills.has(alias)) {
          const aliasCandidates = combinedSkills.get(alias).CandidateIds;
          combinedSkills.set(mainSkill, {
            CandidateIds: [...new Set([...mainSkillCandidates, ...aliasCandidates])],
            IsHighlighted: false,
          });
          combinedSkills.delete(alias);
        }
      });
    }
  }
  return combinedSkills;
};

export const sortCandidateAggregatedSkillsAndTitles = (skills, skillsCollection) => {
  const skillsArray = Object.entries(skills);
  skillsArray.sort((a, b) => {
    const skillA = a[0].toLowerCase();
    const skillB = b[0].toLowerCase();
    return skillA.localeCompare(skillB);
  });

  return Object.fromEntries(skillsArray);
};

const processLocations = locationsMap => {
  return sortMapEntries(locationsMap, ([locationA], [locationB]) => {
    return locationA?.localeCompare(locationB);
  });
};

const aggregateCandidateData = (
  candidatesArray,
  userConfig,
  whiteLabelInfo,
  revealActiveChannelSourceName,
  manualSearchSkills,
  preferTitles
) => {
  return candidatesArray.reduce(
    (acc, candidate) => {
      const { Id, Skills = [], Title, RelocationStatus, ProfileDate, LastActivityDate, Location, Sources } = candidate;
      Skills.forEach(skill => {
        const normalizedSkill = skill?.toLowerCase();
        if (!acc.skills.has(normalizedSkill)) {
          const isHighlighted = manualSearchSkills.map(item => item.toLowerCase()).includes(normalizedSkill);
          acc.skills.set(normalizedSkill, { CandidateIds: [], IsHighlighted: isHighlighted });
        }
        acc.skills.get(normalizedSkill).CandidateIds.push(Id);
      });

      const normalizedTitle = Title?.toLowerCase();
      if (normalizedTitle) {
        if (!acc.titles.has(normalizedTitle)) {
          const isHighlighted = preferTitles.map(item => item.toLowerCase()).includes(normalizedTitle);
          acc.titles.set(normalizedTitle, { CandidateIds: [], IsHighlighted: isHighlighted });
        }
        acc.titles.get(normalizedTitle).CandidateIds.push(Id);
      }
      const status = getRelocationStatusDisplayText(RelocationStatus);
      if (!acc.relocationStatus.has(status)) {
        acc.relocationStatus.set(status, { CandidateIds: [] });
      }
      acc.relocationStatus.get(status).CandidateIds.push(Id);

      const localCurrentDate = moment();
      const lastActive = getLocalLastActive({ ProfileDate, LastActivityDate }, localCurrentDate);
      const normalizedLastActivities = getNormalizedLastActivity(lastActive);
      normalizedLastActivities.forEach(normalizedLastActivity => {
        if (!acc.lastActivity.has(normalizedLastActivity)) {
          acc.lastActivity.set(normalizedLastActivity, { CandidateIds: [] });
        }
        acc.lastActivity.get(normalizedLastActivity).CandidateIds.push(Id);
      });

      if (!acc.location.has(Location)) {
        acc.location.set(Location, { CandidateIds: [] });
      }
      acc.location.get(Location).CandidateIds.push(Id);

      const atsSource = getAtsSource(Sources);
      const shouldShowVaultName = userConfig.ShowVaultName ?? false;
      const sourceDisplayName = getSourceDisplayName(
        atsSource || getCandidateSource(candidate),
        userConfig,
        shouldShowVaultName,
        {},
        whiteLabelInfo,
        revealActiveChannelSourceName
      );

      if (!acc.source.has(sourceDisplayName)) {
        acc.source.set(sourceDisplayName, { CandidateIds: [] });
      }
      acc.source.get(sourceDisplayName).CandidateIds.push(Id);
      return acc;
    },
    {
      skills: new Map(),
      titles: new Map(),
      relocationStatus: new Map(),
      lastActivity: new Map(),
      location: new Map(),
      source: new Map(),
    }
  );
};

export const getCriteria = (store, jobId) => {
  const unsavedCriteria = store.ManualSearchReducer.ByJobId?.[jobId]?.Criterias?.Unsaved || {};
  const preferTitles = unsavedCriteria.Titles?.map(x => x.Title) || [];
  const preferSkills = unsavedCriteria.Skills?.Prefers || [];
  const formattedAliases = preferSkills.reduce((acc, skill) => {
    acc[skill.SkillName] = skill.AliasSkills;
    return acc;
  }, {});
  const manualSearchSkills = preferSkills.map(skill => skill.SkillName);
  return { preferTitles, formattedAliases, manualSearchSkills };
};

const relocationStatusOrder = [
  relocationStatusLabels.Relocate,
  relocationStatusLabels.WontRelocate,
  relocationStatusLabels.NotMentioned,
];
const lastActivityOrder = [
  lastActivityLabels.FewHoursAgo,
  lastActivityLabels.LastOneDay,
  lastActivityLabels.LastThreeDays,
  lastActivityLabels.LastWeek,
  lastActivityLabels.LastFortnight,
  lastActivityLabels.LastMonth,
  lastActivityLabels.LastThreeMonths,
  lastActivityLabels.LastSixMonths,
  lastActivityLabels.LastYear,
  lastActivityLabels.LastTwoYears,
  lastActivityLabels.LastFiveYears,
  lastActivityLabels.MoreThanFiveYearsAgo,
];
export const processAggregatedDataParts = (aggregatedData, preferTitles, formattedAliases) => {
  const titles = Object.fromEntries(processAggregatedData(aggregatedData.titles, preferTitles, x => x.toLowerCase()));
  const skills = mergeSkillsAndAliases(aggregatedData.skills, formattedAliases);
  const aggregatedSkills = Object.fromEntries(
    processAggregatedData(skills, Object.keys(formattedAliases || {}), x => x.toLowerCase())
  );
  const relocationStatus = Object.fromEntries(
    processAggregatedData(aggregatedData.relocationStatus, relocationStatusOrder, x => x)
  );
  const sortedLastActivity = Object.fromEntries(
    processAggregatedData(aggregatedData.lastActivity, lastActivityOrder, x => x)
  );
  const location = Object.fromEntries(processLocations(aggregatedData.location));
  const source = Object.fromEntries(processLocations(aggregatedData.source));
  return { titles, aggregatedSkills, relocationStatus, sortedLastActivity, location, source };
};
export function mergeAggregationData(newAggregation, existingAggregation) {
  const mergedData = { ...newAggregation };
  for (const topKey of Object.keys(existingAggregation)) {
    if (!mergedData[topKey]) {
      mergedData[topKey] = {};
    }
    const existingTopData = existingAggregation[topKey];
    const mergedTopData = mergedData[topKey];
    for (const subKey of Object.keys(existingTopData)) {
      if (!mergedTopData[subKey]) {
        mergedTopData[subKey] = { CandidateIds: [] };
      }
      const existingSubData = existingTopData[subKey];
      const mergedSubData = mergedTopData[subKey];
      mergedSubData.CandidateIds = mergeUniqueArrays(mergedSubData.CandidateIds, existingSubData.CandidateIds);
    }
  }
  return mergedData;
}
function mergeUniqueArrays(arr1, arr2) {
  return [...new Set([...arr1, ...arr2])];
}

export const getCandidatesToAggregate = (store, existingCandidatesIds, jobId, candidate) => {
  if (candidate) return [candidate];
  const candidatesObject = store.ManualSearchCandidateReducer.ByJobId?.[jobId]?.CandidatesById || {};
  const candidatesArray = Object.values(candidatesObject);
  const existingIdsSet = new Set(existingCandidatesIds);
  return candidatesArray.filter(c => !existingIdsSet.has(c.Id));
};

export const getAllTabCandidatesAggregation = (getState, jobId, existingCandidatesIds, candidate) => {
  const store = getState();
  const userConfig = store.ConfigReducer?.UserConfig || {};
  const whiteLabelInfo = store.ConfigReducer?.ConfigReducer || {};
  const featureToggleList = getFeatureToggleList(store);
  const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
  const filteredCandidatesArray = getCandidatesToAggregate(store, existingCandidatesIds, jobId, candidate);
  const { preferTitles, formattedAliases, manualSearchSkills } = getCriteria(store, jobId);
  const aggregatedData = aggregateCandidateData(
    filteredCandidatesArray,
    userConfig,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    manualSearchSkills,
    preferTitles
  );

  const { titles, aggregatedSkills, relocationStatus, sortedLastActivity, location, source } =
    processAggregatedDataParts(aggregatedData, preferTitles, formattedAliases);
  const aggregation = {
    Skills: aggregatedSkills,
    Titles: titles,
    RelocationStatus: relocationStatus,
    LastActivity: sortedLastActivity,
    Location: location,
    Source: source,
  };
  return {
    aggregation,
    preferTitles,
    manualSearchSkills,
  };
};
export function getNormalizedSelectedCandidateIds(data, keywordsOperation = '') {
  return data.map(([filterKey, subArray]) => {
    let candidateIds = [];
    if (filterKey.toLowerCase() === 'skills' && keywordsOperation.toLowerCase() === 'and') {
      const candidateIdsArray = subArray.map(item => item.CandidateIds);
      const intersectedCandidateIds = candidateIdsArray.reduce((intersection, array) =>
        intersection.filter(element => array.includes(element))
      );
      candidateIds = [...intersectedCandidateIds];
    } else
      subArray.forEach(item => {
        if (item !== null) candidateIds.push(...item.CandidateIds);
      });
    return candidateIds;
  });
}
export function getSelectedCandidateIds(selectedAggregation, aggregation, keywordsOperation) {
  const selectedAggregatedSkillsVsCandidateIds = Object.fromEntries(
    Object.entries(selectedAggregation).map(([key, values]) => [
      key,
      [...new Set(values.flatMap(value => aggregation[key]?.[value] || []))],
    ])
  );

  const nonEmptyArrays = Object.entries(selectedAggregatedSkillsVsCandidateIds).filter(
    ([_key, array]) => array.length > 0
  );
  const normalizedNonEmptyArrays = getNormalizedSelectedCandidateIds(nonEmptyArrays, keywordsOperation);
  if (!normalizedNonEmptyArrays.length) {
    return [];
  }
  return normalizedNonEmptyArrays.reduce((intersection, array) =>
    intersection.filter(element => array.includes(element))
  );
}
export const getSelectedFiltersCandidatesCount = ({
  selectedFilters,
  allTabAggregationFilter,
  store,
  jobId,
  keywordsOperation,
}) => {
  const selectedCandidateIds = [
    ...getSelectedCandidateIds(selectedFilters, allTabAggregationFilter, keywordsOperation),
  ];
  const allTabCandidates = store.ManualSearchCandidateReducer.ByJobId[jobId].AllTabCandidates || [];
  return allTabCandidates.filter(item => selectedCandidateIds.includes(item.Id));
};
export function getFilteredAllTabCandidates({ store, jobId }) {
  const allTabCandidates = store.ManualSearchCandidateReducer.ByJobId[jobId].AllTabCandidates || [];
  const isAllTabAggregationFilterSelected = store.ManualSearchReducer.ByJobId[jobId]?.AllTabFilterAppliedFlag;
  const featureToggleList = getFeatureToggleList(store);
  const isAllTabAggregationFilterEnabled = featureToggleList.AdvancedSearchAggregationFilters.IsEnabled;
  if (!isAllTabAggregationFilterSelected || !isAllTabAggregationFilterEnabled) return allTabCandidates;
  const selectedAggregation = store.ManualSearchCandidateReducer.ByJobId[jobId].AllTabSelectedFilters || {};
  const aggregation = store.ManualSearchCandidateReducer.ByJobId[jobId].AllTabAggregation || {};
  const keywordsOperation = store.ManualSearchCandidateReducer.ByJobId[jobId].KeywordsOperation;
  const selectedCandidateIds = [...getSelectedCandidateIds(selectedAggregation, aggregation, keywordsOperation)];
  return allTabCandidates.filter(item => selectedCandidateIds.includes(item.Id));
}

function reorderAggregationFieldItems(aggregationFieldItems, selectedItems) {
  const selectedKeys = Object.keys(aggregationFieldItems).filter(key => selectedItems.includes(key));
  const unselectedKeys = Object.keys(aggregationFieldItems).filter(key => !selectedItems.includes(key));
  const reorderedKeys = [...selectedKeys, ...unselectedKeys];
  return Object.fromEntries(reorderedKeys.map(key => [key, aggregationFieldItems[key]]));
}

function getReorderedAggregationFields(allAggregationFilters, selectedFilters) {
  return Object.keys(selectedFilters).reduce((reorderedFields, field) => {
    if (allAggregationFilters[field]) {
      const selectedItems = selectedFilters[field];
      const aggregationFieldItems = allAggregationFilters[field];
      reorderedFields[field] = reorderAggregationFieldItems(aggregationFieldItems, selectedItems);
    } else {
      reorderedFields[field] = allAggregationFilters[field];
    }
    return reorderedFields;
  }, {});
}

export function reorderAllTabCandidatesAggregationFilters(allAggregationFilters, selectedFilters) {
  const reorderedFields = getReorderedAggregationFields(allAggregationFilters, selectedFilters);
  return { ...allAggregationFilters, ...reorderedFields };
}

export {
  getKeyWordsToHighlight,
  getCandidatesFilterWithStatus,
  getDefaultPageSize,
  isCandidateStateChangeAllowed,
  isPublishIconDisplayAllowed,
  getCandidateStatusForSegment,
  getExperienceTimeline,
  getExperienceDuration,
  getCandidateCardCursorStyle,
  getCandidate360NotesIcon,
  handleFavouriteCandidateIconClick,
};
