import React from 'react';
import { Alert } from 'antd';

export default function CreditInfoAlert({
  credits,
  onCloseCreditInfoAlert,
  selectedActiveCandidateCount,
  currentUser,
  isPaidJobServiceEnabled,
  statusKey,
  candidateContext,
  availableCredits,
  userConfig,
  showCreditInfoAlert,
}) {
  if (showCreditInfoAlert) return null;
  const activeTabDisplayName = userConfig?.ActiveChannelAlias || 'active';
  const creditInfoAlertDescription = `You will be charged ${credits} credit for each unique ${activeTabDisplayName} profile view or contact pull per ${candidateContext}.`;
  const creditInfoAlertStatuses = JSON.parse(localStorage.getItem(statusKey ?? 'CreditInfoAlertStatuses')) || {};
  const availableCreditValue = availableCredits?.['Arya'].AvailableCredits;
  let showAvailableCredit = true;
  if (typeof availableCreditValue === 'string' && availableCreditValue?.toLowerCase() === 'unlimited') {
    showAvailableCredit = false;
  }

  const { sub } = currentUser;
  return !isPaidJobServiceEnabled &&
    !creditInfoAlertStatuses[sub] &&
    selectedActiveCandidateCount > 0 &&
    showAvailableCredit ? (
    <Alert
      message="Credit Deduction"
      description={creditInfoAlertDescription}
      type="info"
      className="credit-deduction-alert"
      showIcon
      closable
      onClose={() => onCloseCreditInfoAlert()}
    />
  ) : null;
}
