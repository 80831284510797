import React from 'react';
import _ from 'lodash';
import { Timeline } from 'antd';
import TextPreview from '../../Utils/TextPreview/TextPreview';
import styles from './CandidateWorkHistory.module.scss';
import { SmartIcon } from '../../Icons/AryaIcons';
import CalculatePromotion from '../../Utils/PromotionCalculator';
import { getExperienceDuration, getExperienceTimeline } from '../../Utils/CandidateListUtils';
import { highlightKeyWords } from '../../Utils/KeywordHighlightUtil';

function CandidateWorkHistory(props) {
  const {
    experiences = [],
    name,
    appName,
    workExperience,
    keywordsToHighlight,
    mustHavesKeywords = [],
    candidateDetailsStatus,
    candidate,
    activeTab,
    activeSourceName,
  } = props;
  const noOfPormotions = CalculatePromotion(experiences);
  function generateAllHighlightKeywords(keywordsToHighlight, activeTab, activeSourceName, candidate) {
    return _.uniq([
      ...(keywordsToHighlight || []),
      ...(activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || []),
    ]);
  }

  const [allHighlightKeywords, setAllHighlightKeywords] = React.useState([]);
  React.useEffect(() => {
    if (candidateDetailsStatus === 'COMPLETED') {
      const allHighlights = generateAllHighlightKeywords(keywordsToHighlight, activeTab, activeSourceName, candidate);
      const domSelectors = ['div#candidate-360-work-history'];
      const mergedKeywordsList = [...allHighlights, ...mustHavesKeywords];
      setAllHighlightKeywords(mergedKeywordsList);
      highlightKeyWords(domSelectors, mergedKeywordsList);
      highlightKeyWords(domSelectors, mustHavesKeywords, 'mustHaveMark');
    }
  }, [candidateDetailsStatus, keywordsToHighlight, mustHavesKeywords]);

  return (
    <div id="candidate-360-work-history">
      {experiences.length ? (
        <div>
          <div className={styles.historySummary}>
            <SmartIcon />
            <span>{`${
              name ?? appName
            } has had ${noOfPormotions} promotions roles in the last ${workExperience} years`}</span>
          </div>
          <Timeline>
            <div className={styles.historyTimeline}>
              {experiences.map(experience => {
                const text = experience?.Summary?.trim();
                const experienceTimeline = getExperienceTimeline(experience);
                let timeline;
                if (experience.Company && experienceTimeline) {
                  timeline = (
                    <div className={styles.companyName}>{`${experience.Company} | ${experienceTimeline}`}</div>
                  );
                } else if (experience.Company) {
                  timeline = <div className={styles.companyName}>{experience.Company}</div>;
                } else if (experienceTimeline) {
                  timeline = <div className={styles.companyName}>{experienceTimeline}</div>;
                } else {
                  timeline = null;
                }
                const duration = getExperienceDuration(experience);
                return (
                  <div className={styles.timelineItem} key={experience}>
                    <Timeline.Item color="#52C41A" key={experience}>
                      <div>
                        <div className={styles.titleAndDuration}>
                          <div className={styles.jobTitle}>{experience.Title}</div>
                          {duration ? <span>({duration})</span> : null}
                        </div>
                        {timeline}
                        <div className={styles.cardView}>
                          <TextPreview
                            text={text}
                            shouldSetSeeMoreButton
                            partialClassName={styles.partialClass}
                            keywords={allHighlightKeywords}
                          />
                        </div>
                      </div>
                    </Timeline.Item>
                  </div>
                );
              })}
            </div>
            <div className={styles.hiddenTimeline}>
              <Timeline.Item dot={<div />}></Timeline.Item>
            </div>
          </Timeline>
        </div>
      ) : (
        <div>
          <SmartIcon />
          <span>No work history available</span>
        </div>
      )}
    </div>
  );
}

export default CandidateWorkHistory;
