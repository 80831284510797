import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import _ from 'lodash';
import { getAdminFeatures } from './Reducers/UserReducer';
import ScrollToTop from './ScrollTop';
import * as ConfigActions from './Actions/ConfigActions';
import getMergeTags from './Actions/MergeTagsActions';
import * as JobActions from './Actions/JobActions';
import * as JobStatusActions from './Actions/JobStatusActions';
import * as ConnectSettingsActions from './Actions/ConnectSettingsActions';
import * as AryaPayOrderActions from './Actions/AryaPayOrderActions';
import AccountNotConfigured from './Components/AccountNotConfigured/AccountNotConfigured';
import PulseAccountSetup from './Components/PulseAccountSetup/PulseAccountSetup';
import NetworkIssueErrorBoundary from './Components/ErrorBoundary/NetworkIssue/NetworkIssue';
import { NetworkErrorComponent } from './Components/ErrorComponent/NetworkErrorComponent';
import AppAlertNotificationContainer from './Containers/AppNotification/AppAlertNotification';
import AppFeatureLockedNotificationContainer from './Containers/AppNotification/AppFeatureLockedNotification';
import MessagePopupsContainer from './Containers/MessagePopups/MessagePopups';
import CallDialogContainer from './Containers/CallDialog/CallDialog';
import NavbarContainer from './Containers/Navbar/Navbar';
import CalendarContainer from './Containers/Calendar/CalendarContainer';
import ReportsPortalContainer from './Containers/ReportPortalContainer/ReportPortalContainer';
import ResearchPortalContainer from './Containers/ResearchPortalContainer/ResearchPortalContainer';
import JobListContainer from './Containers/JobList/JobList';
import JobCreateContainer from './Containers/JobCreate/JobCreateContainer';
import JobEditContainer from './Containers/JobEdit/JobEditContainer';
import JobDetailContainer from './Containers/JobDetail/JobDetail';
import SettingsContainer from './Containers/Settings/Settings';
import JobForbidden from './Components/JobForbidden/JobForbidden';
import JobNotfound from './Components/JobNotFound/JobNotFound';
import UserAlerts from './Components/UserAlerts/UserAlerts';
import CandidateView from './Components/CandidateView/CandidateView';
import ViewSampleCandidates from './Components/ViewSampleCandidates/ViewSampleCandidates';
import RedirectUrlMapper from './Components/RedirectUrlMapper/RedirectUrlMapper';
import AtsRedirectUrlMapper from './Components/AtsRedirectUrlMapper/AtsRedirectUrlMapper';
import ConnectAtsRedirectUrlMapper from './Components/AtsRedirectUrlMapper/ConnectAtsRedirectUrlMapper';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import PlanSelection from './Containers/PlanSelection/PlanSelection';
import CreditSelection from './Containers/PlanSelection/CreditSelection';
import { getFeatureToggleList } from './Reducers/FeatureToggleReducer.ts';
import { getScoutingAgentConfig } from './Reducers/ScoutingAgentReducer';
import Payment from './Containers/Payment/Payment';
import Orders from './Containers/Orders/Orders';
import PasswordResetModal from './Containers/PasswordResetModal/PasswordResetModal';
import AdvancedJobRouter from './Components/AdvancedJobRouter/AdvancedJobRouter';
import { createBroadcastChannel } from './Events/BroadcastNotificationHandler';
import { loadSubscribers } from './PubSub/Subscribers';
import * as UserSessionActions from './Actions/UserSessionActions';
import * as scoutingAgentActions from './Actions/ScoutingAgentActions';
import * as ConnectActions from './Actions/ConnectActions';
import * as UserActions from './Actions/UserActions';
import { setIdentityCredentials as _setIdentityCredentials } from './Actions/CognitoActions';
import { getConfig, getNavbarRenderFlag } from './Reducers/ConfigReducer';
import { getDowntimeRenderFlag } from './Reducers/AnnouncementReducer';
import { getCurrentUser, getCurrentUserDetails, getImpersonatedUserDetails } from './Reducers/UserSessionReducer';
import config from './Config/Config';
import AppFooter from './Components/Footer/Footer';
import { getEnvironmentSkOrgId } from './Analytics/EventUtils';
import ListOfLists from './Containers/Lists/ListOfLists';
import SegmentDetailsContainer from './Containers/Lists/ListDetailContainer/SegmentDetailContainerArya';
import { isPulseUser } from './Utils/ConfigUtils';
import Help from './Containers/Help/Help';
import { ConnectUrlMapper } from './Containers/Connect/ConnectUrlMapper';
import ManualSearchWrapper from './Containers/ManualSearchV2/ManualSearchWrapper/ManualSearchWrapper';
import { setupV2SocketConnections as _setupV2SocketConnections } from './Events/SocketConnectionManager';

const mapDispatchToProps = {
  fetchJobStatus: JobStatusActions.fetchJobStatus,
  fetchConfig: ConfigActions.fetchConfig,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,
  fetchCallerIds: ConnectSettingsActions.fetchCallerIds,
  fetchCountries: JobActions.fetchCountries,
  fetchConnectConfig: ConfigActions.fetchConnectConfig,
  getConnectConsentConfig: ConfigActions.getConnectConsentConfig,
  fetchWhiteLabelInfo: ConfigActions.fetchWhiteLabelInfo,
  fetchProductVariants: AryaPayOrderActions.fetchProductVariants,
  fetchMergeTags: getMergeTags,
  loadCurrentUser: UserSessionActions.loadCurrentUser,
  setIdentityCredentials: _setIdentityCredentials,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  fetchScoutingAgentConfig: scoutingAgentActions.getScoutingAgentConfigAction,
  setNavbarRenderStatus: ConfigActions.setNavbarRenderStatus,
  addUserToOrganizationAsGuest: UserActions.addUserToOrganizationAsGuest,
  setupV2SocketConnections : _setupV2SocketConnections
};

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
  userConfig: getConfig(state),
  navbarRenderFlag: getNavbarRenderFlag(state),
  downtimeRenderFlag: getDowntimeRenderFlag(state),
  currentUserDetails: getCurrentUserDetails(state),
  impersonatedUserDetails: getImpersonatedUserDetails(state),
  currentUser: getCurrentUser(state),
  scoutingAgentConfig: getScoutingAgentConfig(state),
  adminFeatures: getAdminFeatures(state),
});

const history = createBrowserHistory();

class Routes extends Component {
  state = {
    configFetchStatus: 'PENDING',
  };

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = () => {
    const {
      fetchConfig,
      fetchCountries,
      fetchConnectConfig,
      getConnectConsentConfig,
      fetchEmailConfig,
      fetchCallerIds,
      fetchJobStatus,
      fetchProductVariants,
      loadCurrentUser,
      userConfig,
      fetchMergeTags,
      fetchUserEmails,
      fetchScoutingAgentConfig,
      featureToggleList,
      setNavbarRenderStatus,
      setupV2SocketConnections
    } = this.props;
    Promise.all([fetchConfig(), fetchConnectConfig(), fetchJobStatus()])
      .then(async () => {
        await fetchProductVariants();
        await fetchMergeTags();
        let currentUser = null;
        if (!userConfig.IsAryaUser) {
          currentUser = await loadCurrentUser();
          const currentUserAttributes = currentUser?.attributes || {};
          const { email, given_name: firstName = '', family_name: lastName = '' } = currentUserAttributes;
          const currentUserFullName = `${firstName} ${lastName}`;
          if (window.olark) {
            window.olark('api.visitor.updateEmailAddress', {
              emailAddress: email,
            });
            window.olark('api.visitor.updateFullName', {
              fullName: currentUserFullName,
            });
          }
        }
        setupV2SocketConnections();
        this.setState({
          configFetchStatus: 'SUCCESS',
        });
      })
      .catch(err => {
        if (
          _.get(err, 'response.status', null) === 401 ||
          _.get(err, 'response.status', null) === 403 ||
          _.get(err, 'response.status', null) === 404
        ) {
          this.setState({
            configFetchStatus: 'SUCCESS',
          });
        } else {
          this.setState({
            configFetchStatus: 'FAILED',
          });
        }
      });
    const urlParams = new URLSearchParams(window.location.search);
    const ats = urlParams.get('ats');
    if (ats?.toLowerCase() === 'bullhorn') setNavbarRenderStatus(false);
    fetchCountries();
    fetchCallerIds();
    getConnectConsentConfig();
    fetchEmailConfig({ isAdmin: true });
    fetchEmailConfig({ isAdmin: false });
    createBroadcastChannel();
    loadSubscribers();
    fetchUserEmails({ isOrgDefault: true });
    const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
    if (isScoutingAgentEnabled) fetchScoutingAgentConfig();
  };

  refreshData = () => {
    this.fetchInitialData();
  };

  configureAnalytics = () => {
    const { currentUserDetails = {} } = this.props;
    const {
      UserGuid: currentUserGuid,
      RoleName: currentUserRole,
      OrgId: currentUserOrgId,
      Email: currentUserEmail,
      FullName: currentUserName,
    } = currentUserDetails;
    // Test orgId '149b21ed-de53-4343-9733-750ad7ca7e8c'
    const skOrgId = getEnvironmentSkOrgId(config.urls.env, currentUserOrgId, config.smartKarrot.isEnabled);
    if (skOrgId && window.location.hostname !== config.urls.pulseHostName) {
      if (window.UsageAnalytics) {
        window.UsageAnalytics.configure(
          config.smartKarrot.appId,
          skOrgId,
          config.smartKarrot.variant,
          config.smartKarrot.productId,
          { useClassNames: true }
        );
        window.UsageAnalytics.setUser(currentUserGuid, skOrgId, config.smartKarrot.productId);
        window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.name, currentUserName);
        window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.emailId, currentUserEmail);
        window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.persona, currentUserRole);
        window.UsageAnalytics.setUserAttribute(window.UsageAnalytics.UserAttribute.designation, currentUserRole);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const {
      featureToggleList: prevFeatureToggleList,
      currentUserDetails: prevCurrentUserDetails,
      adminFeatures: prevAdminFeatures,
    } = prevProps;
    const {
      featureToggleList: currentFeatureToggleList,
      setIdentityCredentials,
      currentUserDetails,
      fetchScoutingAgentConfig,
      adminFeatures,
      addUserToOrganizationAsGuest,
    } = this.props;
    if (currentFeatureToggleList.ReactEmailEditor.IsEnabled && !prevFeatureToggleList.ReactEmailEditor.IsEnabled) {
      setIdentityCredentials();
    }
    const currentUserGuid = currentUserDetails?.UserGuid;
    if (currentUserGuid && currentUserGuid !== prevCurrentUserDetails?.UserGuid) {
      this.configureAnalytics();
    }
    const isScoutingAgentEnabled = _.get(currentFeatureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
    const isScoutingAgentPrevJobsEnabled = _.get(prevFeatureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
    if (isScoutingAgentPrevJobsEnabled !== isScoutingAgentEnabled && isScoutingAgentEnabled) fetchScoutingAgentConfig();
    if (
      (!!adminFeatures &&
        prevAdminFeatures?.JobLevelSourcingSupportAssistant?.IsEnabled !==
          adminFeatures.JobLevelSourcingSupportAssistant.IsEnabled) ||
      prevFeatureToggleList?.JobLevelSourcingSupportAssistant?.IsEnabled !==
        currentFeatureToggleList?.JobLevelSourcingSupportAssistant.IsEnabled
    ) {
      const isJobLevelSourcingSupportAssistantEnabledForAdmin = _.get(
        adminFeatures,
        ['JobLevelSourcingSupportAssistant', 'IsEnabled'],
        false
      );
      const isJobLevelSourcingSupportAssistantEnabledForUser = _.get(
        currentFeatureToggleList,
        ['JobLevelSourcingSupportAssistant', 'IsEnabled'],
        false
      );

      if (
        isJobLevelSourcingSupportAssistantEnabledForAdmin &&
        isJobLevelSourcingSupportAssistantEnabledForUser &&
        localStorage.getItem('Impersonate')
      ) {
        const userId = localStorage.getItem('ManagementUserId');
        const impersonatedUserString = localStorage.getItem('ImpersonatedUser');
        const impersonatedUser = JSON.parse(impersonatedUserString);
        addUserToOrganizationAsGuest({ orgId: impersonatedUser.OrgId, userId });
      }
    }
  }

  refreshPage = () => {
    window.location.reload();
  };

  openSegmentView = (segmentId, _history) => {
    _history.push(`/segments/${segmentId}/candidates?status=sourced`);
  };

  openListOfList = (link, _history) => {
    _history.push(link);
  };

  getCandidateViewParams = routeProps => {
    const { location, match } = routeProps;
    let candidateViewVersion;
    const queryParams = queryString.parse(location.search);
    const { src, v, portal, highlights, mustHaves, recordId, criteriaType } = queryParams;
    const { jobId, candidateId } = match.params;
    if (v === '3' || src === 'qs') candidateViewVersion = 'v3';
    else candidateViewVersion = 'v2';
    return { jobId, candidateId, src, candidateViewVersion, portal, highlights, mustHaves, recordId, criteriaType };
  };

  render() {
    const { featureToggleList, navbarRenderFlag, downtimeRenderFlag } = this.props;
    const { configFetchStatus } = this.state;

    const getRoutes = () => {
      const connectTabRoutes = featureToggleList.Connect.IsEnabled
        ? [
            // {
            //   exact: true,
            //   path: '/connect?tab=:tab',
            //   render: routeProps => (
            //     <ConnectContainer
            //       {...routeProps}
            //       setUrl={url => routeProps.history.push(url)}
            //       defaultActiveTab={routeProps.match.params.tab}
            //     />
            //   ),
            // },
            {
              exact: true,
              path: '/connect',
              render: routeProps => {
                const { location, history: _history } = routeProps;
                const queryParams = queryString.parse(location.search);
                const { type, tab, workflowId } = queryParams;
                const createWorkflowId = location?.state?.workflowId;
                return (
                  <ConnectUrlMapper
                    history={_history}
                    type={type}
                    tab={tab}
                    location={location}
                    workflowId={workflowId}
                    createWorkflowId={createWorkflowId}
                    setUrl={url => _history.push(url)}
                    atsProps={{}}
                  />
                );
              },
            },
          ]
        : [];
      const paidJobRoutes = featureToggleList.PaidJobService.IsEnabled
        ? [
            { path: '/v1/jobs/:jobId/_candidates', component: ViewSampleCandidates },
            { path: '/buy-credit', component: CreditSelection },
            { path: '/select-plan', component: PlanSelection },
            { path: '/payment', component: Payment },
            { path: '/orders', component: Orders },
          ]
        : [];

      const isAdvancedJobCreationEnabled = featureToggleList?.AdvancedJobCreation?.IsEnabled;
      const isPulse = isPulseUser();
      const isHelpTabEnabled = featureToggleList.KnowledgeBase.IsEnabled && !isPulse;
      const managementUser = localStorage.getItem('ManagementUser');
      const impersonate = localStorage.getItem('Impersonate');

      const getClassnameForBody = classNames(
        { 'show-body-top': navbarRenderFlag },
        { 'downtime-announcement-top': downtimeRenderFlag && navbarRenderFlag },
        { 'impersonate-banner': managementUser && impersonate && navbarRenderFlag },
        { 'hide-body-top': !navbarRenderFlag }
      );

      if (configFetchStatus === 'PENDING') {
        return <LoadingScreen loading active />;
      }
      if (configFetchStatus === 'FAILED') {
        return <NetworkErrorComponent />;
      }
      if (configFetchStatus === 'SUCCESS') {
        return (
          <NetworkIssueErrorBoundary>
            <ScrollToTop>
              <div id="body-content" className={getClassnameForBody}>
                <Route path="*" component={AppAlertNotificationContainer} />
                <Route path="*" component={AppFeatureLockedNotificationContainer} />
                <Route path="*" component={PasswordResetModal} />
                <Switch>
                  <Route exact path="/" component={JobListContainer} />
                  <Route exact path="/PeopleScout" component={JobListContainer} />
                  <Route path="/unauthorized" component={AccountNotConfigured} />
                  {isHelpTabEnabled && <Route path="/help" component={Help} />}
                  <Route path="/pulsesetup" component={PulseAccountSetup} />
                  <Route path="/useralerts" component={UserAlerts} />
                  <Route
                    path="/jobs/:jobId/forbidden"
                    render={routeProps => {
                      const { history: _history } = routeProps;
                      return <JobForbidden openListOfList={linkTo => this.openListOfList(linkTo, _history)} />;
                    }}
                  />
                  <Route
                    path="/jobs/:jobId/notfound"
                    render={routeProps => {
                      const { match, location, history: _history } = routeProps;
                      const { jobId } = match.params;
                      return (
                        <JobNotfound
                          jobId={jobId}
                          location={location}
                          openListOfList={linkTo => this.openListOfList(linkTo, _history)}
                        />
                      );
                    }}
                  />
                  <Route path="/jobs/:jobId/edit" component={JobEditContainer} />
                  <Route
                    exact
                    path="/jobs/:jobId/candidates/redirect"
                    render={() => <RedirectUrlMapper redirectTo="CandidateList" operation="OpenCandidateDrawer" />}
                  />
                  <Route
                    exact
                    path="/jobs/:jobId/candidates/:candidateId"
                    render={routeProps => {
                      const candidateViewParams = this.getCandidateViewParams(routeProps);
                      const {
                        jobId,
                        candidateId,
                        src,
                        candidateViewVersion,
                        portal,
                        highlights,
                        mustHaves,
                        criteriaType,
                        recordId,
                      } = candidateViewParams;
                      return (
                        <ManualSearchWrapper jodId={jobId}>
                          <CandidateView
                            candidateId={candidateId}
                            jobId={jobId}
                            candidateViewVersion={candidateViewVersion}
                            highlights={highlights}
                            mustHaves={mustHaves}
                            src={src}
                            portal={portal}
                            history={routeProps.history}
                            criteriaType={criteriaType}
                            recordId={recordId}
                          />
                        </ManualSearchWrapper>
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/segments/:jobId/candidates/:candidateId"
                    render={routeProps => {
                      const candidateViewParams = this.getCandidateViewParams(routeProps);
                      const { jobId, candidateId, src, candidateViewVersion, portal, highlights, mustHaves } =
                        candidateViewParams;
                      return (
                        <ManualSearchWrapper jodId={jobId}>
                          <CandidateView
                            candidateId={candidateId}
                            jobId={jobId}
                            candidateViewVersion={candidateViewVersion}
                            highlights={highlights}
                            mustHaves={mustHaves}
                            src={src}
                            portal={portal}
                            candidateContext="segment"
                            history={routeProps.history}
                          />
                        </ManualSearchWrapper>
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/candidates/:candidateId"
                    render={routeProps => {
                      const candidateViewParams = this.getCandidateViewParams(routeProps);
                      const { candidateId, portal } = candidateViewParams;
                      return <CandidateView candidateId={candidateId} portal={portal} candidateViewVersion="v2" />;
                    }}
                  />
                  <Route path="/jobs/:jobId/:tab" component={JobDetailContainer} />
                  <Route
                    path="/segments/:segmentId/:tab"
                    render={routeProps => {
                      const { location, match, history: _history } = routeProps;
                      const { segmentId } = match.params;
                      return (
                        <SegmentDetailsContainer
                          segmentId={segmentId}
                          onSegmentCreationCallback={_segmentId => this.openSegmentView(_segmentId, _history)}
                          history={_history}
                          location={location}
                          match={match}
                          openListOfList={linkTo => this.openListOfList(linkTo, _history)}
                        />
                      );
                    }}
                  />
                  <Route path="/jobs/create" component={JobCreateContainer} />
                  <Route path="/jobs/:jobId" component={JobDetailContainer} />
                  <Route
                    path="/segments/:segmentId"
                    render={routeProps => {
                      const { location, match, history: _history } = routeProps;
                      const { segmentId } = match.params;
                      return (
                        <SegmentDetailsContainer
                          segmentId={segmentId}
                          onSegmentCreationCallback={_segmentId => this.openSegmentView(_segmentId, _history)}
                          history={_history}
                          location={location}
                          match={match}
                        />
                      );
                    }}
                  />
                  {featureToggleList.Calendar.IsEnabled ? (
                    <Route path="/calendar" component={CalendarContainer} />
                  ) : null}
                  <Route path="/jobs" component={JobListContainer} />
                  {featureToggleList.CandidateCRM.IsEnabled && !isPulse ? (
                    <Route
                      path="/segments"
                      render={routeProps => {
                        const { history: _history } = routeProps;
                        return (
                          <ListOfLists
                            history={_history}
                            onSegmentCreationCallback={segmentId => this.openSegmentView(segmentId, _history)}
                          />
                        );
                      }}
                    />
                  ) : null}
                  {featureToggleList.ReportingPortal.IsEnabled ? (
                    <Route path="/reports" component={ReportsPortalContainer} />
                  ) : null}
                  {featureToggleList.SalesDashboardReports.IsEnabled ||
                  featureToggleList.CareerProjectionReport.IsEnabled ? (
                    <Route path="/research" component={ResearchPortalContainer} />
                  ) : null}
                  <Route path="/settings/:tab" component={SettingsContainer} />
                  {connectTabRoutes.map(routeConfig => (
                    <Route
                      exact={routeConfig.exact}
                      key={routeConfig.path}
                      path={routeConfig.path}
                      render={routeConfig.render}
                    />
                  ))}
                  {paidJobRoutes.map(routeConfig => (
                    <Route key={routeConfig.path} path={routeConfig.path} component={routeConfig.component} />
                  ))}
                  {isAdvancedJobCreationEnabled && <Route path="/jobs-v2" component={AdvancedJobRouter} />}
                  <Route
                    path="*"
                    render={routeProps => {
                      const path = routeProps?.location?.pathname;
                      if (path === '/_trynow' || path === '/signup') {
                        history.push('/');
                        return <JobListContainer />;
                      }
                      return <div>Page not found</div>;
                    }}
                  />
                </Switch>
              </div>
            </ScrollToTop>
          </NetworkIssueErrorBoundary>
        );
      }
      return null;
    };

    return (
      <Router>
        <>
          <Route exact path="/embed/:ats" render={() => <AtsRedirectUrlMapper />} />
          <Route exact path="/connect/embed/:ats" render={() => <ConnectAtsRedirectUrlMapper />} />
          <Route path="*" render={props => <NavbarContainer {...props} onRefresh={this.refreshData} />} />
          {getRoutes()}
          <Route path="*" component={AppFooter} />
          <div className="call-dialog-container">
            <Route
              path="*"
              render={routeProps => (
                <CallDialogContainer
                  {...routeProps}
                  dialingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dialingTone.mp3`)}
                  ringingTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/ringingTone.mp3`)}
                  dtmfTone={new Audio(`${process.env.PUBLIC_URL}/static/Audio/dtmf.wav`)}
                />
              )}
            />
          </div>
          <div id="messages-footer">
            <div id="message-popup-container">
              <Route path="*" component={MessagePopupsContainer} />
            </div>
          </div>
        </>
      </Router>
    );
  }
}

Route.propTypes = {
  path: PropTypes.string,
  fetchConfig: PropTypes.func,
};

Route.defaultProps = {
  fetchConfig: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
