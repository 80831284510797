import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import SingleThreadWindow from '../../Components/SourcingSupport/SingleThreadWindow';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import SourcingSupport from '../../Components/SourcingSupport/SourcingSupport';
import * as SourcingSupportChatActions from '../../Actions/SourcingSupportChatActions';
import { getUsersListFromIdList } from '../../Reducers/UserReducer';
import {
  getChatIdOfJob,
  getAllThreadsForTheJob,
  getTopicIdOfTheJob,
  getLastFetchedThreadCount,
  getUnreadMessageCountForTheChat,
  getSingleThread,
} from '../../Reducers/SourcingSupportChatReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getConfiguration } from '../../Components/Collaborators/Collaborators';

const mapStateToProps = (state, props) => ({
  sharedUsers: getUsersListFromIdList(state, [
    ...new Set([...props.jobDetails.SharedWith, ...props.jobDetails.AssignedTo]),
  ]),
  chatId: getChatIdOfJob(state, props.jobDetails.JobId),
  initialChatsFetchApiStatus: getApiStatus(state, 'initialChatsFetchApiStatus'),
  initialThreadsFetchApiStatus: getApiStatus(state, 'initialThreadsFetchApiStatus'),
  fetchSingleThreadApiStatus: getApiStatus(state, 'fetchSingleThreadApiStatus'),
  singleThread: getSingleThread(state, props.queryParams?.chatId, props.queryParams?.messageId),
  threads: getAllThreadsForTheJob(state, props.jobDetails.JobId),
  currentUser: getCurrentUser(state),
  topicId: getTopicIdOfTheJob(state, props.jobDetails.JobId),
  lastFetchedThreadCount: getLastFetchedThreadCount(state, props.jobDetails.JobId),
  unreadMessageCount: getUnreadMessageCountForTheChat(state, props.jobDetails.JobId),
});

const mapDispatchToProps = {
  initiateChat: SourcingSupportChatActions.initiateChat,
  fetchAllThreads: SourcingSupportChatActions.fetchAllThreads,
  addThreadMessageToTheChat: SourcingSupportChatActions.addThreadMessageToTheChat,
  addThreadReplyToTheChat: SourcingSupportChatActions.addThreadReplyToTheChat,
  fetchMoreRepliesForTheThread: SourcingSupportChatActions.fetchMoreRepliesForTheThread,
  markChatAsRead: SourcingSupportChatActions.markChatAsRead,
  fetchSingleThread: SourcingSupportChatActions.fetchSingleThread,
};

function SourcingSupportContainer(props) {
  const {
    initiateChat,
    sharedUsers,
    jobDetails,
    chatId,
    initialChatsFetchApiStatus,
    threads,
    currentUser,
    fetchAllThreads,
    addThreadMessageToTheChat,
    topicId,
    addThreadReplyToTheChat,
    fetchMoreRepliesForTheThread,
    lastFetchedThreadCount,
    initialThreadsFetchApiStatus,
    markChatAsRead,
    unreadMessageCount,
    fetchSingleThread,
    singleThread,
    queryParams,
    fetchSingleThreadApiStatus,
    history,
  } = props;

  const { AryaStatus } = jobDetails;
  const { sub: userGuid } = currentUser;
  const { chatId: chatIdFromQueryParams, messageId: messageIdFromQueryParams } = queryParams;
  const isChatDisabled = AryaStatus.toLowerCase() === 'closed';
  const showSingleThreadComponent = chatIdFromQueryParams !== null && messageIdFromQueryParams !== null;

  const firstName = currentUser.given_name;
  const lastName = currentUser.family_name;
  const currentMember = {
    userGuid,
    avatarConfiguration: getConfiguration([firstName, lastName].join(' ')),
    isAryaRecruiter: localStorage.getItem('Impersonate') !== null,
    displayName: [firstName, lastName].join(' '),
  };

  return showSingleThreadComponent ? (
    <SingleThreadWindow
      chatId={chatIdFromQueryParams}
      messageId={messageIdFromQueryParams}
      topicId={topicId}
      addThreadReplyToTheChat={addThreadReplyToTheChat}
      fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
      isChatDisabled={isChatDisabled}
      currentMember={currentMember}
      thread={singleThread}
      fetchSingleThread={fetchSingleThread}
      fetchSingleThreadApiStatus={fetchSingleThreadApiStatus}
      history={history}
      jobId={jobDetails.JobId}
    />
  ) : (
    <SourcingSupport
      currentUser={currentUser}
      threads={threads}
      initialChatsFetchApiStatus={initialChatsFetchApiStatus}
      initialThreadsFetchApiStatus={initialThreadsFetchApiStatus}
      addThreadMessageToTheChat={addThreadMessageToTheChat}
      addThreadReplyToTheChat={addThreadReplyToTheChat}
      topicId={topicId}
      chatId={chatId}
      fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
      fetchAllThreads={fetchAllThreads}
      lastFetchedThreadCount={lastFetchedThreadCount}
      isChatDisabled={isChatDisabled}
      markChatAsRead={markChatAsRead}
      unreadMessageCount={unreadMessageCount}
      currentMember={currentMember}
      jobDetails={jobDetails}
      sharedUsers={sharedUsers}
      initiateChat={initiateChat}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SourcingSupportContainer);
