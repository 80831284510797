import _ from 'lodash';
import cleanSet from 'clean-set';
import { SET_API_STATUS } from '../Actions/ApiStatusActions';
import { SET_FETCH_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID_API_STATUS } from '../Actions/ActionCreators/AryaPayOrderActionCreator';
import {
  SET_FETCH_TRY_NOW_CANDIDATE_API_STATUS,
  SET_FETCH_TRY_NOW_CANDIDATE_DETAILS_API_STATUS,
  SET_CANDIDATE_SPECIFICATIONS_API_STATUS,
  SET_FAVOURITE_CANDIDATE_API_STATUS,
  SET_CANDIDATE_PUBLISH_API_STATUS,
} from '../Actions/ActionCreators/CandidateActions';
import {
  GLOBAL_SUPPLY_LOCATION_API_STATUS,
  FETCH_GLOBAL_SUPPLY_API_STATUS,
} from '../Actions/ActionCreators/SalesDashBoardActions';
import {
  SET_UNDO_JOB_AUTO_REJECT_API_STATUS,
  SET_JOB_REACTIVATION_API_STATUS,
} from '../Actions/ActionCreators/JobActionCreator';
import {
  SET_FETCH_REPORT_SUMMARY_API_STATUS,
  SET_FETCH_REPORT_STATS_API_STATUS,
  SET_FETCH_RECRUITER_API_STATUS,
  SET_EXPORT_REPORT_API_STATUS,
  SET_FETCH_REPORT_COLUMNS_API_STATUS,
  SET_SAVE_REPORT_QUERY_API_STATUS,
  SET_FETCH_SAVED_QUERIES_API_STATUS,
  SET_FETCH_CLIENT_LIST_API_STATUS,
  SET_AVAILABLE_REPORT_API_STATUS,
} from '../Actions/ActionCreators/ReportPortalActionCreator';
import { SET_FETCH_NOTIFICATION_CONFIG_API_STATUS } from '../Actions/ActionCreators/ConfigActions';
import {
  CAREER_PROJECTION_API_STATUS,
  CAREER_ASSESSMENT_API_STATUS,
} from '../Actions/ActionCreators/CareerProjectionActions';
import { FETCH_LOCATION_API_STATUS } from '../Actions/ActionCreators/LocationSearchActions';
import { SET_CONTACT_PROVIDER_API_STATUS } from '../Actions/ActionCreators/ContactProviderActionCreator';
import { SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS } from '../Actions/ActionCreators/UserActivityActionCreator';
import { SET_CREATE_CLIENT_API_STATUS } from '../Actions/ActionCreators/CreateClientActionCreator';
import { SET_CANDIDATE_COLLAPSED_FILTERS_STATUS } from '../Actions/JobCandidatesTabActions';

const initialState = {
  quickSearchCandidateStatusUpdate: {},
};

function ApiStatusReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case SET_API_STATUS: {
      const { apiName, status } = action.payload;
      return {
        ...state,
        [apiName]: status,
      };
    }
    case 'SET_FETCH_BULK_CONTACTS_API_STATUS':
      return {
        ...state,
        fetchBulkContactStatus: action.payload,
      };
    case 'SET_CONNECT_STATUS_API_STATUS':
      return {
        ...state,
        ConnectStatus: action.payload.status,
      };
    case 'SET_CANDIDATE_ALL_DETAILS_API_STATUS':
      return {
        ...state,
        candidateDetailsStatus: action.payload,
      };
    case 'SET_JOB_LIST_API_STATUS':
      return {
        ...state,
        jobListStatus: action.payload,
      };
    case 'SET_JOB_STATUS_COUNT_API_STATUS':
      return {
        ...state,
        jobCountByStatusesApiStatus: action.payload,
      };
    case 'SET_CANDIDATE_DETAILS_API_STATUS':
      return {
        ...state,
        candidateListStatus: action.payload,
      };
    case 'SET_CANDIDATE_PERSONALITY_STATUS_API_STATUS':
      return {
        ...state,
        getPersonalityStatus: action.payload.status,
      };
    case 'SET_CANDIDATE_PERSONALITY_API_STATUS':
      return {
        ...state,
        fetchPersonalityStatus: action.payload.status,
      };
    case 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS':
      return {
        ...state,
        resumeUnlockStatus: action.payload,
      };
    case 'SET_CANDIDATE_DOWNLOAD_RESUME_API_STATUS':
      return {
        ...state,
        resumeDownloadStatus: action.payload,
      };
    case 'SET_QUICK_SEARCH_CANDIDATE_DETAIL_API_STATUS':
      return {
        ...state,
        quickSearchcandidateDetails: action.payload,
      };
    case 'UPDATE_QUICK_SEARCH_CANDIDATE_STATUS_API_STATUS': {
      const { status, candidateId, candidateStatus } = action.payload;
      newState = cleanSet(state, ['quickSearchCandidateStatusUpdate', candidateId, candidateStatus], status);
      return newState;
    }

    case 'UPDATE_CANDIDATE_STATUS_API_STATUS':
      newState = { ...state };
      newState = cleanSet(
        newState,
        [
          'sourceCandidateStatusUpdate',
          action.payload.candidateId,
          action.payload.jobId,
          action.payload.candidateStatus,
        ],
        action.payload.status
      );
      newState = cleanSet(
        newState,
        [
          'sourceCandidateJobMatchingStatusUpdate',
          action.payload.candidateId,
          action.payload.jobId,
          action.payload.candidateStatus,
        ],
        action.payload.status
      );
      newState = cleanSet(
        newState,
        [
          'sourceCandidateJobMatchingStatusUpdate',
          action.payload.candidateId,
          action.payload.jobId,
          action.payload.connectionStatus,
        ],
        action.payload.status
      );
      return newState;

    case 'UPDATE_TITLE_SYNONYMS_FETCH_API_STATUS':
      return {
        ...state,
        titleSynonymStatusUpdate: action.payload,
      };

    case 'UPDATE_SKILLS_FETCH_API_STATUS':
      return {
        ...state,
        skillsFetchStatusUpdate: action.payload,
      };

    case 'UPDATE_TAGS_FETCH_API_STATUS':
      return {
        ...state,
        tagsFetchStatusUpdate: action.payload,
      };

    case 'SET_TEST_TEMPLATE_API_STATUS':
      return {
        ...state,
        testTemplateStatus: action.payload,
      };
    case 'SET_TEST_TEXT_TEMPLATE_API_STATUS':
      return {
        ...state,
        testTextTemplateStatus: action.payload,
      };
    case 'SET_EMAIL_TEMPLATES_API_STATUS':
      return {
        ...state,
        getEmailTemplateStatus: action.payload,
      };
    case 'SET_REQUEST_CREDITS_API_STATUS':
      return {
        ...state,
        requestCreditsStatus: action.payload,
      };
    case 'SET_CREATE_JOB_API_STATUS':
      return {
        ...state,
        createJobStatus: action.payload,
      };
    case SET_CANDIDATE_SPECIFICATIONS_API_STATUS:
      return {
        ...state,
        fetchCandidateSpecificationsApiStatus: action.payload,
      };
    case 'SET_EDIT_JOB_API_STATUS':
      return {
        ...state,
        editJobStatus: action.payload,
      };
    case 'SET_PUSH_CANDIDATE_ATS_API_STATUS':
      return {
        ...state,
        pushCandToAtsStatus: action.payload,
      };
    case 'SET_JOB_DETAILS_API_STATUS':
      return {
        ...state,
        jobDetailsStatus: action.payload,
      };
    case 'SET_UPDATE_BOT_DETAILS_API_STATUS':
      return {
        ...state,
        updateBotDetailsStatus: action.payload,
      };
    case 'SET_UPDATE_CONFIG_API_STATUS':
      return {
        ...state,
        updateConfigStatus: action.payload,
      };
    case 'SET_FETCH_CALLER_IDS_API_STATUS':
      return {
        ...state,
        fetchCallerIdStatus: action.payload,
      };
    case 'SET_UPDATE_CONSENT_CONFIG_API_STATUS':
      return {
        ...state,
        updateConsentApiStatus: action.payload,
      };
    case FETCH_LOCATION_API_STATUS:
      return {
        ...state,
        locationApiStatus: action.payload,
      };
    case 'SET_UPDATE_JOB_INTELLIGENCE_STATUS':
      return {
        ...state,
        updateJobIntelApiStatus: action.payload,
      };
    case 'SET_FETCH_USER_GIVEN_INTEL_API_STATUS': {
      return {
        ...state,
        fetchUserGivenIntelApiStatus: action.payload,
      };
    }
    case 'SET_FETCH_BULK_CANDIDATE_NOTES_API_STATUS':
      return {
        ...state,
        fetchBulkCandidateNotesApiStatus: _.get(action, ['payload', 'status'], ''),
      };
    case 'SET_FETCH_BULK_CALL_NOTES_API_STATUS':
      return {
        ...state,
        fetchBulkCallNotesApiStatus: _.get(action, ['payload', 'status'], ''),
      };
    case 'SET_FETCH_BULK_CANDIDATE_ALL_NOTES_API_STATUS':
      return {
        ...state,
        fetchBulkCandidateAllNotesApiStatus: _.get(action, ['payload', 'status'], ''),
      };
    case 'SET_PAYMENT_PROFILES_API_STATUS':
      return {
        ...state,
        paymnetProfileApiStatus: action.payload,
      };
    case 'BULK_PUBLISH_CANDIDATE_API_STATUS':
      return {
        ...state,
        bulkPublishCandidateApiStatus: _.get(action, ['payload', 'status'], ''),
      };
    case 'SET_JOB_DISTRIBUTION_STATUS_API_STATUS':
      return {
        ...state,
        jobDistributionStatusApiStatus: action.payload,
      };
    case SET_UNDO_JOB_AUTO_REJECT_API_STATUS:
      return {
        ...state,
        undoJobAutoRejectApiStatus: action.payload.status,
      };
    case SET_FETCH_REPORT_SUMMARY_API_STATUS:
      return {
        ...state,
        fetchReportSummaryApiStatus: action.payload.status,
      };
    case SET_FETCH_REPORT_STATS_API_STATUS:
      return {
        ...state,
        fetchReportStatsApiStatus: action.payload.status,
      };
    case SET_FETCH_RECRUITER_API_STATUS:
      return {
        ...state,
        fetchRecruiterApiStatus: action.payload.status,
      };
    case SET_FETCH_CLIENT_LIST_API_STATUS:
      return {
        ...state,
        fetchClientListApiStatus: action.payload.status,
      };
    case SET_EXPORT_REPORT_API_STATUS:
      return {
        ...state,
        exportReportApiStatus: action.payload.status,
      };
    case SET_SAVE_REPORT_QUERY_API_STATUS:
      return {
        ...state,
        saveReportQueryApiStatus: action.payload.status,
      };
    case SET_FETCH_SAVED_QUERIES_API_STATUS:
      return {
        ...state,
        fetchSavedQueriesApiStatus: action.payload.status,
      };
    case SET_FETCH_REPORT_COLUMNS_API_STATUS:
      return {
        ...state,
        fetchReportColumnsApiStatus: action.payload.status,
      };
    case SET_FETCH_NOTIFICATION_CONFIG_API_STATUS:
      return {
        ...state,
        fetchNotificationConfigApiStatus: action.payload.status,
      };
    case 'SET_DELETE_PAYMENT_CARD_API_STATUS':
      return {
        ...state,
        deletePaymentCardApiStatus: action.payload,
      };
    case SET_AVAILABLE_REPORT_API_STATUS:
      return {
        ...state,
        availableReportsApiStatus: action.payload.status,
      };
    case SET_FETCH_PLAN_DETAILS_BY_PRODUCT_VARIANT_ID_API_STATUS:
      return {
        ...state,
        fetchPlanDetailsByProductVariantIdApiStatus: action.payload,
      };
    case 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_API_STATUS':
      return {
        ...state,
        fetchTotalAmountForProductvariantApiStatus: action.payload,
      };
    case 'SET_TEST_SMTP_API_STATUS':
      return {
        ...state,
        testSmtpApiStatus: action.payload,
      };
    case 'GET_JOB_QUESTIONS_API_STATUS':
      return {
        ...state,
        getJobQuestionApiStatus: action.payload,
      };
    case 'SET_APPLY_INTEL_API_STATUS':
    case 'SET_REFINE_CRITERIA_API_STATUS':
      return {
        ...state,
        [action.payload.action]: action.payload.status,
      };
    case 'SET_SUGGESTIONS_FETCH_API_STATUS':
      return {
        ...state,
        suggestionsFetchApiStatus: action.payload,
      };
    case 'FETCH_TITLE_API_STATUS':
      return {
        ...state,
        fetchTitleApiStatus: action.payload,
      };
    case 'SET_CANDIDATE_INTEL_API_STATUS':
      return {
        ...state,
        candidateIntelApiStatus: action.payload.candidateIntelApiStatus,
      };
    case 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHTAX_API_STATUS':
      return {
        ...state,
        fetchTotalAmountForProductvariantWithTaxApiStatus: action.payload,
      };
    case 'SET_TOTAL_AMOUNT_FOR_PRODUCT_VARIANT_WITHOUT_TAX_API_STATUS':
      return {
        ...state,
        fetchTotalAmountForProductvariantWithoutTaxApiStatus: action.payload,
      };
    case SET_FETCH_TRY_NOW_CANDIDATE_API_STATUS:
      return {
        ...state,
        fetchTryNowCandidateApiStatus: action.payload,
      };
    case SET_FETCH_TRY_NOW_CANDIDATE_DETAILS_API_STATUS:
      return {
        ...state,
        fetchTryNowCandidateDetailsApiStatus: action.payload,
      };
    case 'USER_PASSWORD_UPDATE_API_STATUS':
      return {
        ...state,
        updatePasswordApiStatus: action.payload,
      };
    case 'GENERATE_MAGIC_LINK_API_STATUS':
      return {
        ...state,
        generateMagicLinkApiStatus: action.payload,
      };
    case 'SIGNUP_API_STATUS':
      return {
        ...state,
        signupApiStatus: action.payload,
      };
    case CAREER_PROJECTION_API_STATUS:
      return {
        ...state,
        careerProjectionApiStatus: action.payload,
      };
    case CAREER_ASSESSMENT_API_STATUS:
      return {
        ...state,
        careerAssessmentApiStatus: action.payload,
      };
    case FETCH_GLOBAL_SUPPLY_API_STATUS:
      return {
        ...state,
        globalSupplyApiStatus: action.payload,
      };
    case GLOBAL_SUPPLY_LOCATION_API_STATUS:
      return {
        ...state,
        globalSupplyLocationApiStatus: action.payload,
      };
    case 'SET_CONVERSATIONS_SCHEDULED_DRIP_API_STATUS':
      return {
        ...state,
        dripConfigApiStatus: action.payload,
      };
    case 'SET_FETCH_DRIP_STATS_API_STATUS':
      return {
        ...state,
        dripStatsApiStatus: action.payload,
      };
    case SET_JOB_REACTIVATION_API_STATUS:
      return {
        ...state,
        jobReactivationApiStatus: action.payload,
      };
    case SET_MONITOR_USER_EMAIL_ACTIVITY_API_STATUS:
      return {
        ...state,
        monitorUserEmailActivityApiStatus: action.payload,
      };
    case SET_CONTACT_PROVIDER_API_STATUS: {
      const { status, providerId } = action.payload;
      newState = cleanSet(state, ['contactProviderAuthenticationApiStatus', providerId], status);
      return newState;
    }
    case SET_CREATE_CLIENT_API_STATUS: {
      return {
        ...state,
        createClientApiStatus: action.payload,
      };
    }

    case 'SET_SEGMENT_JOB_API_STATUS':
      return {
        ...state,
        createSegmentStatus: action.payload,
      };
    case 'FETCH_LIST_OF_LISTS_API_STATUS':
      return {
        ...state,
        fetchListOfListsStatus: action.payload,
      };
    case 'SET_EDIT_SEGMENT_API_STATUS':
      return {
        ...state,
        editSegmentStatus: action.payload,
      };
    case 'FETCH_SEGMENT_LIST_API_STATUS':
      return {
        ...state,
        fetchSegmentListApiStatus: action.payload,
      };
    case 'FETCH_CANDIDATE_JOBS_API_STATUS':
      return {
        ...state,
        fetchCandidateJobsApiStatus: action.payload,
      };
    case 'FETCH_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS':
      return {
        ...state,
        fetchCandidateListsAndCampaignsApistatus: action.payload,
      };
    case SET_CANDIDATE_COLLAPSED_FILTERS_STATUS:
      return {
        ...state,
        manualSearchFiltersStatus: action.payload.status,
      };

    case SET_FAVOURITE_CANDIDATE_API_STATUS: {
      return {
        ...state,
        favouriteCandidateStatus: action.payload,
      };
    }
    case SET_CANDIDATE_PUBLISH_API_STATUS: {
      return {
        ...state,
        candidatePublishStatus: action.payload,
      };
    }
    case 'SET_REMOVE_FAVOURITE_CANDIDATE_API_STATUS': {
      return {
        ...state,
        removeFavouriteCandidateStatus: action.payload,
      };
    }
    case 'FETCH_SOURCING_SUPPORT_CHAT_API_STATUS': {
      return {
        ...state,
        initialChatsFetchApiStatus: action.payload,
      };
    }
    default:
      return state;
  }
}

function getApiStatus(state, apiName) {
  return state.ApiStatusReducer[apiName];
}

function getApiStatuses(state) {
  return state.ApiStatusReducer;
}

function getCandidateIntelApiStatus(state) {
  return _.get(state, ['ApiStatusReducer', 'candidateIntelApiStatus']);
}

function getCandidateDetailApiStatus(state) {
  return _.get(state, ['ApiStatusReducer', 'candidateDetailsStatus']);
}

function getFavouriteCandidateApiStatus(state) {
  return _.get(state, ['ApiStatusReducer', 'favouriteCandidateStatus']);
}

function getCandidatePublishApiStatus(state) {
  return _.get(state, ['ApiStatusReducer', 'candidatePublishStatus']);
}

function getRemoveFavouriteCandidateApiAtatus(state) {
  return _.get(state, ['ApiStatusReducer', 'removeFavouriteCandidateStatus']);
}

function getCreateSourcingSupportChatApiStatus(state) {
  return _.get(state, ['ApiStatusReducer', 'initialChatsFetchApiStatus']);
}

export {
  ApiStatusReducer,
  getApiStatus,
  getApiStatuses,
  getCandidateIntelApiStatus,
  getCandidateDetailApiStatus,
  getFavouriteCandidateApiStatus,
  getCandidatePublishApiStatus,
  getRemoveFavouriteCandidateApiAtatus,
  getCreateSourcingSupportChatApiStatus,
};
