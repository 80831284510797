import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { verifyUser, patchUpdateUser, fetchKnowledgeBaseAuthToken } from '../Repository/UserRepository';
import * as userActions from './UserActions';

function loadCurrentUser() {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_LOAD_CURRENT_USER_API_STATUS',
        payload: 'PENDING',
      });
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const userGuid = localStorage.getItem('Impersonate')? localStorage.getItem('Impersonate') : user.attributes.sub;
      dispatch({
        type: 'LOAD_CURRENT_USER',
        payload: user,
      });
      const userInfo = await dispatch(
        userActions.fetchUsers({
          userGuids: [userGuid],
        })
      );
      dispatch({
        type: 'SET_LOAD_CURRENT_USER_API_STATUS',
        payload: 'SUCCESS',
      });
      localStorage.setItem('OrgGuid',userInfo.Users[0]?.OrgGuid.toLowerCase());
      return user;
    } catch {
      dispatch({
        type: 'SET_LOAD_CURRENT_USER_API_STATUS',
        payload: 'FAILED',
      });
      return {};
    }
  };
}

function reloadCurrentUser() {
  return dispatch => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    }).then(user => {
      dispatch({
        type: 'LOAD_CURRENT_USER',
        payload: user,
      });
    });
  };
}

function updateUserAttributes(updatedUserInfo) {
  const userInfo = {
    FirstName: updatedUserInfo.given_name || '',
    LastName: updatedUserInfo.family_name || '',
    OfficePhone: updatedUserInfo.phone_number || '',
  };
  return dispatch => {
    patchUpdateUser(userInfo)
      .then(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: 'Saved successfully',
          },
        });
        dispatch(reloadCurrentUser());
      })
      .catch(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: 'Failed to update user details',
          },
        });
      });
  };
}

function updateUserPassword(oldPassword, newPassword) {
  return dispatch => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(() => {
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'SUCCESS',
            Message: 'Password updated successfully',
          },
        });
      })
      .catch(err => {
        if (err.code === 'NotAuthorizedException') {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Invalid Password',
            },
          });
        } else if (err.code === 'InvalidPasswordException' || err.code === 'InvalidParameterException') {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: "Password strength doesn't meet the requirements",
            },
          });
        } else if (err.code === 'LimitExceededException') {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Password update limit exceeded. Please try after sometime',
            },
          });
        } else {
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              Type: 'ERROR',
              Message: 'Password update failed',
            },
          });
        }
      });
  };
}

function userVerificationAction(params) {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_USER_VERIFICATION_STATUS',
        payload: { status: 'PENDING' },
      });
      const data = await verifyUser(params.user_name, params.confirmation_code, params.email);
      dispatch({
        type: 'SET_USER_VERIFICATION_STATUS',
        payload: { status: 'SUCCESS', message: data },
      });
    } catch (err) {
      if (err.response.status === 400) {
        const errorCode = _.get(err, ['response', 'data', 'Error', 'Code']);
        dispatch({
          type: 'SET_USER_VERIFICATION_STATUS',
          payload: { status: 'FAILED', statusCode: errorCode },
        });
      } else {
        dispatch({
          type: 'SET_USER_VERIFICATION_STATUS',
          payload: { status: 'FAILED', message: err.message },
        });
      }
    }
  };
}

function getKnowledgeBaseAuthorizationToken() {
  return async dispatch => {
    try {
      dispatch({ type: 'GET_KNOWLEDGEBASE_AUTHORIZATION_TOKEN_API_STATUS', payload: { status: 'INPROGRESS' } });
      const response = await fetchKnowledgeBaseAuthToken();
      const authToken = {
        cognitoToken: response.data.CognitoToken,
        userToken: response.data.UserToken,
        userName: response.data.UserName,
        roles: response.data.Roles,
      };
      dispatch({
        type: 'GET_KNOWLEDGEBASE_AUTHORIZATION_TOKEN_API_STATUS',
        payload: { status: 'COMPLETED' },
      });
      return authToken;
    } catch (error) {
      dispatch({
        type: 'GET_KNOWLEDGEBASE_AUTHORIZATION_TOKEN_API_STATUS',
        payload: { status: 'FAILED', message: error.message },
      });
      throw error;
    }
  };
}

export {
  loadCurrentUser,
  reloadCurrentUser,
  updateUserAttributes,
  updateUserPassword,
  userVerificationAction,
  getKnowledgeBaseAuthorizationToken,
};
