import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Skeleton, Icon, Select, Button, /* Input, */ Form, Popover, Tooltip } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import AryaSendIconV2 from '../../../Icons/AryaSendIconV2';
import ExclamationCircleIcon from '../../../Icons/ExclamationCircleIcon';
import config from '../../../Config/Config';
import './ConnectMessageWindow.scss';
import ChatTextHistory from '../ChatTextHistory/ChatTextHistory';
import RichTextEditor from '../../Editor/RichTextEditor';
import UserAlerts from '../../UserAlerts/UserAlerts';
import languageSupportmessages from './messages';
import { getSortedPhonesByTypes, validateStatus } from '../../../Utils/ContactUtils';
import { validateTemplate } from '../../../Utils/Validators';
import { getChatHistoryWithDatesOnTop } from '../../../Utils/ChatHistoryUtils';
import Candidate360MessageStack from './Connect360MessageStack';
import Candidate360MailAndMessageComposeWindow from '../Candidate360MailAndMessageComposeWindow/Candidate360MailAndMessageComposeWindow';
import { validatePhone } from '../../../Utils/FormValidators';
import { formatTextMessage, getPlainTextFromHTML } from '../../../Utils/TextUtils';
import { messageCount, emojiFinder } from '../../Utils/SmsUtils';
import SpamAlertText from '../../Common/SpamTextAlert/SpamTextAlert';

// const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;
const { MessageBubble: MessageBubbleFromTextHistory } = ChatTextHistory;

function getPhoneDropdownOptions(phones, phoneOptionValue, isConnectContentPane) {
  if (!phones && !phoneOptionValue) {
    return null;
  }
  const sortedPhones = getSortedPhonesByTypes({ phones });
  const phoneDropdown = sortedPhones.map(phone => {
    return _.get(phone, ['IsRefunded'], false) ? null : (
      <Option value={phone.Number} key={phone.Number}>
        <span className={validateStatus(phone.ValidityStatus) ? 'invalid-spam-phone' : null}> {phone.Number}</span>
      </Option>
    );
  });
  const allowPhoneOptionAdd = phoneOptionValue && !isConnectContentPane;
  if (allowPhoneOptionAdd)
    phoneDropdown.push(
      <Option value={phoneOptionValue} key={phoneOptionValue} className="phone-option-container">
        <span className={validateStatus(phoneOptionValue?.ValidityStatus) ? 'invalid-spam-phone' : null}>
          {' '}
          {phoneOptionValue}
        </span>
        <span className="add-phone">+ Add Phone</span>{' '}
      </Option>
    );
  return phoneDropdown;
}

class ConnectMessageWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOptionValue: undefined,
      mergeTagPopover: false,
    };
    this.myRef = React.createRef();
    this.formRef = React.createRef();
    this.addTags = this.addTags.bind(this);
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  componentDidUpdate(prevProps) {
    const {
      currentValues: { isConsent },
      form,
      templateName,
    } = this.props;
    if (templateName !== prevProps.templateName) {
      const newFieldsValue = {};
      if (isConsent) {
        newFieldsValue.consentTemplate = templateName;
      } else {
        newFieldsValue.template = templateName;
      }
      form.setFieldsValue(newFieldsValue);
    }
  }

  onChangePhone = (phoneValue, eventCallbacks) => {
    eventCallbacks.onChangePhone(phoneValue);
    this.setState({ phoneOptionValue: undefined });
  };

  validateTextTemplate = ({ text }) => {
    const { mergeTags } = this.props;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    return validateTemplate({ body: text, bodyMergeTags: mergeTagsForMessage });
  };

  handleSubmit = event => {
    const {
      form,
      eventCallbacks,
      messages,
      currentValues,
      setInvalidTemplateNotification,
      candidateContext,
      getMessageConsentStatus,
      setTemplateName,
    } = this.props;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const text = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
        if (!this.validateTextTemplate({ text })) {
          setInvalidTemplateNotification(candidateContext);
          return;
        }
        const { selectedPhone } = currentValues;
        let messageConsentStatus = getMessageConsentStatus();
        if (!messageConsentStatus && messages && messages.find(m => m.To === selectedPhone.Number)) {
          messageConsentStatus = 'Pending';
        }
        const message = { ...values, IsConsentMessage: !messageConsentStatus || messageConsentStatus === 'Failed' };
        eventCallbacks.onSend(message, text, this.onReadPhoneNumberSMS);
        setTemplateName(undefined);
        form.setFieldsValue({
          template: undefined,
          consentTemplate: undefined,
        });
      }
    });
  };

  addPhone = value => {
    const { jobGuid, candidate, addContact } = this.props;
    const contactObject = {
      Phones: [
        {
          Number: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
  };

  onAddPhoneClick = value => {
    const {
      eventCallbacks,
      form,
      contact: { Phones: phones },
      setPhoneNumber,
      phoneNumber,
    } = this.props;
    const { phoneOptionValue } = this.state;
    const { validateFields } = form;
    const isPhoneExist = !!phones.find(entry => entry.Number === value);
    if (setPhoneNumber) setPhoneNumber(value);
    if (!isPhoneExist)
      validateFields(err => {
        if (!err) {
          this.addPhone(phoneOptionValue);
          eventCallbacks.onChangePhone(phoneNumber);
          this.setState({
            phoneOptionValue: undefined,
          });
        }
      });
  };

  setPhoneOptionValue = value => {
    this.setState({
      phoneOptionValue: value,
    });
  };

  getSelectPhoneNumberDropdown = () => {
    const {
      form,
      currentValues,
      eventCallbacks,
      contact: { Phones: phones },
      phoneNumber,
      isConnectContentPane,
    } = this.props;
    const { phoneOptionValue } = this.state;
    const { getFieldDecorator } = form;
    const { selectedPhone } = currentValues;
    const number = phoneNumber || undefined;
    const initalValue = selectedPhone?.Number || number;

    return (
      <div className="connect-message-window-flex-items phone-dropdown">
        <Item>
          {getFieldDecorator('phoneNumber', {
            initialValue: initalValue,
            rules: [
              {
                required: true,
                message: 'Invalid number. Please start with country code.',
                validator: validatePhone,
              },
            ],
          })(
            <Select
              dropdownClassName="connect-drawer"
              placeholder="Select phone number"
              dropdownMenuStyle={{ maxHeight: 120 }}
              style={{ width: 200 }}
              onChange={value => {
                this.onChangePhone(value, eventCallbacks);
                form.setFieldsValue({
                  template: undefined,
                  consentTemplate: undefined,
                });
              }}
              disabled={!phones || !phones.length}
              getPopupContainer={() => this.formRef.current}
              className="select-to-dropdown-360"
              showSearch
              onSearch={this.setPhoneOptionValue}
              onSelect={this.onAddPhoneClick}
              notFoundContent=""
              dropdownStyle={{ minWidth: 'fit-content' }}
            >
              {getPhoneDropdownOptions(phones, phoneOptionValue, isConnectContentPane)}
            </Select>
          )}
        </Item>
      </div>
    );
  };

  getNonConsentTemplateDropdown = ({ isSendMessageAllowed, isPlanExistOrCreditZero }) => {
    const { form, eventCallbacks, templateName, nonConsentMessageTemplates, intl } = this.props;
    const { getFieldDecorator } = form;
    const templateSelectClassname = !templateName ? 'empty-select-template' : '';
    return (
      <div>
        {getFieldDecorator('template', { initialValue: templateName })(
          <Select
            dropdownClassName="template-dropdown"
            dropdownMenuStyle={{ maxHeight: 120 }}
            dropdownStyle={{ width: 300 }}
            placeholder="Select template"
            allowClear
            placement="topLeft"
            style={{ width: 300 }}
            onChange={templateId => eventCallbacks.onTemplateSelect(templateId, false)}
            disabled={!isSendMessageAllowed || isPlanExistOrCreditZero}
            getPopupContainer={() => this.formRef.current}
            className={`tempalete-candidate-360 ${templateSelectClassname}`}
          >
            {nonConsentMessageTemplates.map(template => (
              <Option key={template.Id} value={template.Id} title={template.Name}>
                {template.Name}
              </Option>
            ))}
          </Select>
        )}
      </div>
    );
  };

  getConsentTemplateDropdown = ({ isSendMessageAllowed, isValidConsentStatus, isPlanExistOrCreditZero }) => {
    const { form, eventCallbacks, templateName, consentMessageTemplates, intl } = this.props;
    const { getFieldDecorator } = form;
    const templateSelectClassname = !templateName ? 'empty-select-template' : '';
    return (
      <div>
        {getFieldDecorator('consentTemplate', { initialValue: templateName })(
          <Select
            dropdownClassName="template-dropdown"
            dropdownMenuStyle={{ maxHeight: 120 }}
            placeholder="Select template"
            dropdownStyle={{ width: 300 }}
            allowClear
            placement="topLeft"
            onChange={templateId => eventCallbacks.onTemplateSelect(templateId, true)}
            disabled={!isSendMessageAllowed || isPlanExistOrCreditZero}
            getPopupContainer={() => this.formRef.current}
            className={`tempalete-candidate-360 ${templateSelectClassname}`}
          >
            {isValidConsentStatus
              ? consentMessageTemplates.map(template => (
                <Option key={template.Id} value={template.Id} title={template.Name}>
                  {template.Name}
                </Option>
              ))
              : null}
          </Select>
        )}
      </div>
    );
  };

  getTextEditorWithCharacterCount = args => {
    const { isSendMessageAllowed, showCharacterCount = true, shouldSentNonConsentMessage } = args;
    const {
      currentValues,
      eventCallbacks,
      mergeTags,
      featureToggleList,
      availableCreditInfo,
      isJunkWordFound,
      junkWords,
      setIsJunkWordFound,
    } = this.props;
    const { messageInputValue } = currentValues;
    const { charactersPerMessage } = config;
    const messageLength = messageInputValue ? getPlainTextFromHTML(messageInputValue).length : 0;
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;
    const placeholderText = 'Type a new message...';
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;
    const isTextEditorDisable =
      !isSendMessageAllowed ||
      (!shouldSentNonConsentMessage && !messageConsentTemplateCreationEnabled) ||
      isPlanExistOrCreditZero;

    return (
      <div
        className={classNames('message-textarea', {
          'message-textarea-with-spamalert': isJunkWordFound,
        })}
      >
        <RichTextEditor
          className="message-editor"
          editorContent={{
            htmlContent: messageInputValue,
          }}
          placeholder={placeholderText}
          onChange={eventCallbacks.onUpdateMessage}
          mergeTags={mergeTags}
          ref={this.myRef}
          showToolbar={false}
          disabled={isTextEditorDisable}
          editorContext="Message"
        />
        {isJunkWordFound ? (
          <SpamAlertText
            style={{ paddingLeft: '15px' }}
            junkWords={junkWords}
            setIsJunkWordFound={setIsJunkWordFound}
          />
        ) : null}
        <hr
          style={{
            borderColor: 'rgb(252,252,252)',
            width: '100%',
            marginBottom: '-1px',
            marginTop: '0px',
          }}
        />
        {emojiFinder(messageInputValue).length ? (
          <div
            style={{
              display: 'inline-flex',
              backgroundColor: 'rgb(255,251,230)',
              paddingLeft: '15px',
              alignItems: 'center',
            }}
          >
            <p style={{ marginBottom: '-1px' }}>
              &nbsp; Unicode (Special characters/emojis) {emojiFinder(messageInputValue)} detected &nbsp;
            </p>
            <Tooltip
              zIndex={2223}
              title="Utilizing Unicode (special characters/emojis) for text reduces the character count per text from 160 to 70 and may result in carrier flagging text as spam. Their usage also results in an increased number of texts sent and credits used."
              placement="topLeft"
            >
              <ExclamationCircleIcon />
            </Tooltip>
          </div>
        ) : null}
        {showCharacterCount ? (
          <div className="message-character-count">
            {charactersPerMessage - (messageLength % charactersPerMessage)}/
            {Math.floor(messageLength / charactersPerMessage)}
            &nbsp; &nbsp;
          </div>
        ) : null}
      </div>
    );
  };

  getIsSendMessageAllowed = ({ messageConsentStatus }) => {
    return (
      (!messageConsentStatus || messageConsentStatus === 'Approved' || messageConsentStatus === 'Failed') &&
      this.getIsValidConsentStatus()
    );
  };

  getIsValidConsentStatus = () => {
    const {
      currentValues,
      contact: { ConsentStatus: consentStatus },
    } = this.props;
    const { selectedPhone } = currentValues;
    return !consentStatus || consentStatus === 'Approved' || (selectedPhone && selectedPhone.IsAddedByUser);
  };

  getSendButtonState = () => {
    const {
      getMessageConsentStatus,
      messages,
      currentValues,
      contact: { ConsentStatus: consentStatus },
      featureToggleList,
      currentValues: { messageInputValue },
      sendMessageApiStatus,
      phonenumberMessages,
    } = this.props;

    const selectedPhoneNumberMessages = getChatHistoryWithDatesOnTop(phonenumberMessages);
    const receivedMessages = selectedPhoneNumberMessages.filter(message => !message.IsByPerson && message.To);
    const lastSentMessageFromRecruiter = receivedMessages?.length ? receivedMessages[receivedMessages.length - 1] : {};
    const lastSentMessageBody = lastSentMessageFromRecruiter.Body?.trim();
    const currentMessageBody = this.myRef.current
      ? _.trimEnd(this.myRef.current?.quillRef?.getText()?.trim(), '\n')
      : null;
    const lastSentMessageTime = moment.utc(lastSentMessageFromRecruiter.SentTime).local();
    const isSendingMessageWithinAnHour = moment().diff(lastSentMessageTime, 'hour') < 1;
    const isCurrentAndLastSentMessageByRecruiterSame =
      currentMessageBody?.toLowerCase() === lastSentMessageBody?.toLowerCase();
    const isSameMessageWithinAnHour = isCurrentAndLastSentMessageByRecruiterSame && isSendingMessageWithinAnHour;
    if (isSameMessageWithinAnHour)
      return { isSendButtonDisabled: true, tooltipTitle: "Same message can't be sent within an hour to candidate" };
    if (sendMessageApiStatus === 'INPROGRESS') return { isSendButtonDisabled: true };

    const { selectedPhone } = currentValues;
    let messageConsentStatus = getMessageConsentStatus();
    if (!messageConsentStatus && messages?.find(m => m.To === selectedPhone.Number)) {
      messageConsentStatus = 'Pending';
    }
    const isMessagingAllowedForUser = featureToggleList.MessageRead.IsAllowed;
    const dollarSignRegex = /\n$/gi;
    const specialCharacterRegex = /(<([^>]+)>)/gi;
    const messageInputLength = messageInputValue?.replace(specialCharacterRegex, '')?.length;
    const isSendMessageAllowed = this.getIsSendMessageAllowed({ messageConsentStatus });
    let isSendButtonDisabled = !isMessagingAllowedForUser || !isSendMessageAllowed;
    if (this.myRef.current) {
      isSendButtonDisabled =
        isSendButtonDisabled ||
        this.myRef.current.quillRef.getText().trim().replace(dollarSignRegex, '').length === 0 ||
        !selectedPhone;
    }
    if (!this.myRef.current && !messageInputLength) {
      isSendButtonDisabled = true;
    }
    return { isSendButtonDisabled };
  };

  getTemplateDropdown = ({
    isValidConsentStatus,
    messageConsentStatus,
    isSendMessageAllowed,
    showAddMergeTags,
    isPlanExistOrCreditZero,
  }) => {
    const candidate360Style =
      !isSendMessageAllowed || isPlanExistOrCreditZero
        ? { background: '#F5F5F5', marginTop: '32px' }
        : { background: 'white', marginTop: '32px' };
    const { isSendButtonDisabled, tooltipTitle } = this.getSendButtonState();

    const templateDropDownStyle = candidate360Style;
    const buttonDisableStyle = isSendButtonDisabled || isPlanExistOrCreditZero ? { filter: 'grayscale(1)' } : {};

    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';

    const sendButton = (
      <div className="action-button">
        <Button
          id="connect-message-send-button"
          htmlType="submit"
          disabled={isSendButtonDisabled || isPlanExistOrCreditZero}
          onClick={this.handleSubmit}
          style={buttonDisableStyle}
        >
          <AryaSendIconV2 />
        </Button>
      </div>
    );
    const sendButtonWithTooltip = tooltipTitle ? (
      <Tooltip title={tooltipTitle} overlayStyle={{ zIndex: 111111 }} placement="topLeft">
        {sendButton}
      </Tooltip>
    ) : (
      sendButton
    );

    return shouldSentNonConsentMessage ? (
      <div
        id="template-dropdown-credits-wrap"
        className="connect-message-window-flex-items"
        style={templateDropDownStyle}
      >
        {this.getNonConsentTemplateDropdown({ isSendMessageAllowed, isPlanExistOrCreditZero })}
        <div className="merge-tag-360">{showAddMergeTags}</div>
        {sendButtonWithTooltip}
      </div>
    ) : (
      <div
        id="template-dropdown-credits-wrap"
        className="connect-message-window-flex-items"
        style={templateDropDownStyle}
      >
        {this.getConsentTemplateDropdown({ isSendMessageAllowed, isValidConsentStatus, isPlanExistOrCreditZero })}
        <div className="merge-tag-360">{showAddMergeTags}</div>
        {sendButtonWithTooltip}
      </div>
    );
  };

  getMessagingNotAllowedAlert = ({ isMessagingAllowedForUser }) => {
    return isMessagingAllowedForUser === 'false' ? (
      <div className="user-alert-wrapper">
        <UserAlerts
          header="Pro feature alert!"
          content={
            <span>
              You do not have access to this feature. Please contact{' '}
              <a href="mailto:support@leoforce.com">support@leoforce.com</a> to upgrade your plan.
            </span>
          }
        />
      </div>
    ) : null;
  };

  getTemplateWarningMessage = () => {
    const { candidateContext } = this.props;
    const className = 'template-lable-360';
    return candidateContext === 'segment' ? (
      <div className={`template-label ${className}`}>
        Please select a compatible template for this segment, which does not have any job merge tags
      </div>
    ) : null;
  };

  getConsentMessage = ({ isSendMessageAllowed }) => {
    return isSendMessageAllowed ? (
      <div>
        <div className="message-window-template-consent-note-wrapper">
          <span className="message-window-template-consent-note">
            Note: Below text will be appended to the end of every consent text message.
          </span>
        </div>
        <div className="message-window-template-consent-text">
          <FormattedMessage {...languageSupportmessages.textConsentMessage} />
        </div>
      </div>
    ) : null;
  };

  showCharacterCountWithTooltip = () => {
    const {
      getMessageConsentStatus,
      currentValues: { messageInputValue },
    } = this.props;
    const messageConsentStatus = getMessageConsentStatus();
    return (
      <div
        style={{
          marginLeft: '15px',
          justifyContent: 'center',
          paddingTop: '5px',
          paddingBottom: '-5px',
          display: 'inline-flex',
        }}
      >
        {messageCount(messageInputValue, messageConsentStatus === undefined || messageConsentStatus === 'Failed')}{' '}
        &nbsp;
        <div style={{ marginTop: '2px' }}>
          <Tooltip
            zIndex={2223}
            title="The total #of characters and #SMS messages shown here while composing a text may differ from the final count based on the actual value of the merge tags and consent message."
            placement="rightBottom"
          >
            <ExclamationCircleIcon />
          </Tooltip>
        </div>
      </div>
    );
  };

  showConsentMessage = ({ messageConsentStatus, isSendMessageAllowed }) => {
    return messageConsentStatus === 'Approved' ? null : <div>{this.getConsentMessage({ isSendMessageAllowed })}</div>;
  };

  getCandidate360MessageWindow = candidate360MessageComposeSectionPayload => {
    const { isMessageWriteEnabled, isValidConsentStatus, messageConsentStatus, isSendMessageAllowed, add360MergeTags } =
      candidate360MessageComposeSectionPayload;
    const {
      featureToggleList,
      getConsentStatusInfoBanner,
      phonenumberMessages,
      messageConversationApiStatus,
      getCreditInfoBanner,
      availableCreditInfo,
    } = this.props;
    const consentBanner = getConsentStatusInfoBanner();
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;
    const isMessagingAllowedForUser = featureToggleList.MessageRead.IsAllowed;
    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';
    const showConsentBanner = shouldSentNonConsentMessage || isPlanExistOrCreditZero ? null : consentBanner;
    const showAddMergeTags = add360MergeTags || null;

    let textHistoryStyle;

    if (messageConsentStatus === 'Pending') {
      textHistoryStyle = {
        height: 'calc(100vh - 400px)',
      };
    } else if (messageConsentStatus === 'Approved') {
      textHistoryStyle = {
        height: 'calc(100vh - 370px)',
      };
    }
    const selectedPhoneNumberMessages = getChatHistoryWithDatesOnTop(phonenumberMessages);
    const isChatHistoryLoading = messageConversationApiStatus === 'INPROGRESS';
    return (
      <div className="candidate-360-message-window">
        <div id="connect-message-window" ref={this.formRef}>
          <div className="candidate-360-phone-with-label">
            <div className="candidate-360-phone-send-to">
              <span className="candidate-360-phone-label">Send To:</span> {this.getSelectPhoneNumberDropdown()}
            </div>
          </div>
          <div className="candidate-360-text-history-container" style={textHistoryStyle || {}}>
            <div>
              <Skeleton active loading={isChatHistoryLoading}>
                <ChatTextHistory className="connect-chat-window" inputFieldRef={null}>
                  {selectedPhoneNumberMessages.map(message => (
                    <MessageBubbleFromTextHistory
                      key={message.Id}
                      message={formatTextMessage(message.Body)}
                      timestamp={message.CreatedTime}
                      createdByName={message.CreatedByName}
                      align={message.IsByPerson ? 'left' : 'right'}
                      className={message.IsByPerson ? 'leftText' : 'rightText'}
                      type={message.type}
                      DeliveryStatus={message.DeliveryStatus}
                      RequestStatus={message.RequestStatus}
                      messageDeliveryStatusInformation={message.MessageDeliveryStatusInformation}
                      readOnly
                    />
                  ))}
                </ChatTextHistory>
              </Skeleton>
            </div>
          </div>
          <div
            className={classNames('template-dropdown-credits-message-input-box-consent-wrapper', {
              messageNotSent: messageConsentStatus !== 'Approved' && isSendMessageAllowed,
              messageSentConsentNotApproved: !isSendMessageAllowed && messageConsentStatus !== 'Approved',
              smsWindowExtraContentShowLowCreditBanner: !!getCreditInfoBanner(),
            })}
          >
            {isMessageWriteEnabled ? (
              <div>
                {showConsentBanner}
                {getCreditInfoBanner()}
                {this.getTemplateWarningMessage()}
                {this.getTemplateDropdown({
                  isValidConsentStatus,
                  messageConsentStatus,
                  isSendMessageAllowed,
                  showAddMergeTags,
                  isPlanExistOrCreditZero,
                })}
                <div className="message-input-box-consent-wrapper">
                  <div id="message-input-box-wrap" className="connect-message-window-flex-items">
                    <Skeleton active loading={isChatHistoryLoading}>
                      {this.getTextEditorWithCharacterCount({
                        isSendMessageAllowed,
                        showCharacterCount: false,
                        shouldSentNonConsentMessage,
                      })}
                    </Skeleton>
                  </div>
                  <div>
                    {this.showConsentMessage({ messageConsentStatus, isSendMessageAllowed })}
                    {this.showCharacterCountWithTooltip()}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {this.getMessagingNotAllowedAlert({ isMessagingAllowedForUser })}
        </div>
      </div>
    );
  };

  onReadPhoneNumberSMS = async () => {
    const {
      currentValues: { selectedPhone },
      onReadSMS,
      fetchBulkUnReadConversationsCount,
      conversationId,
    } = this.props;
    await onReadSMS(selectedPhone.Number);
    fetchBulkUnReadConversationsCount({ ConversationIds: [conversationId] });
  };

  render() {
    const {
      messages,
      currentValues,
      mergeTags,
      featureToggleList,
      currentValues: { isCommunicationAllowed },

      isComposeMessageModalVisible,
      setMessageComposeModalVisibility,
      isCandidateViewHeaderVisible,
      getConsentStatusInfoBanner,
      getMessageConsentStatus,
      jobId,
      jobTitle,
      onCloseMessageComposer,
      eventCallbacks,
      form,
      sendMessageApiStatus,
      candidateContext,
      jobCode,
      version,
      availableCreditInfo,
      fetchMessages,
      conversationId,
    } = this.props;
    const {
      contact: { ConsentStatus: consentStatus },
    } = this.props;
    const { setFieldsValue } = form;
    const { mergeTagPopover } = this.state;
    const isPlanExistOrCreditZero = availableCreditInfo === 'N/A' || availableCreditInfo === 0;

    const mergeTagLabelName = 'Merge Tags';
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const { selectedPhone } = currentValues;

    const isMessageWriteEnabled = featureToggleList.MessageWrite.IsEnabled;
    const messageConsentTemplateCreationEnabled = featureToggleList.MessageConsentTemplateCreation.IsEnabled;
    let messageConsentStatus = getMessageConsentStatus();
    if (!messageConsentStatus && messages && messages.find(m => m.To === selectedPhone.Number)) {
      messageConsentStatus = 'Pending';
    }

    const isValidConsentStatus = this.getIsValidConsentStatus();
    const isSendMessageAllowed = this.getIsSendMessageAllowed({ messageConsentStatus });
    const { isSendButtonDisabled } = this.getSendButtonState();
    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTagsForMessage.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );
    const shouldSentNonConsentMessage = isValidConsentStatus && messageConsentStatus === 'Approved';
    const isTextEditorDisable =
      !isSendMessageAllowed || (!shouldSentNonConsentMessage && !messageConsentTemplateCreationEnabled);
    const isMergeTagVisible =
      messageConsentStatus !== 'Pending' && messageConsentStatus !== 'Denied' && !isTextEditorDisable;
    const add360MergeTags = (
      <div className="message-window-merge-tag">
        <Popover
          overlayStyle={{ zIndex: 2223 }}
          onVisibleChange={() => this.setState({ mergeTagPopover: !mergeTagPopover })}
          visible={mergeTagPopover}
          content={isMergeTagVisible ? popoverContent : null}
          placement="top"
          trigger="click"
        >
          {!isMergeTagVisible || isPlanExistOrCreditZero ? (
            <span style={{ cursor: 'not-allowed', opacity: 0.5, pointerEvents: 'none' }}>
              {mergeTagLabelName} <Icon type="down" />
            </span>
          ) : (
            <span>
              {mergeTagLabelName} <Icon type={`${mergeTagPopover ? 'up' : 'down'}`} />
            </span>
          )}
        </Popover>
      </div>
    );

    const consentBanner = getConsentStatusInfoBanner();

    const candidate360MessageComposeSectionPayload = {
      isMessageWriteEnabled,
      isValidConsentStatus,
      messageConsentStatus,
      isSendMessageAllowed,
      add360MergeTags,
    };

    if (!isCommunicationAllowed) {
      return <div className="connect-message-consent-banner">{consentBanner}</div>;
    }
    return (
      <Form
        className={classNames('connect-message-form-360', {
          'extended-connect-message-form-360': !isCandidateViewHeaderVisible,
        })}
      >
        <Candidate360MessageStack
          openComposeTextModal={setMessageComposeModalVisibility}
          messages={messages}
          isMessageWriteEnabled={isMessageWriteEnabled}
          setPhoneNumber={eventCallbacks.onChangePhone}
          setFieldsValue={setFieldsValue}
          sendMessageApiStatus={sendMessageApiStatus}
        />
        {isCommunicationAllowed && isComposeMessageModalVisible && (
          <Candidate360MailAndMessageComposeWindow
            jobId={jobId}
            jobTitle={jobTitle}
            version={version}
            jobCode={jobCode}
            candidateContext={candidateContext}
            getCandidate360MessageWindow={this.getCandidate360MessageWindow}
            id="connect-message-send-button"
            htmlType="submit"
            isSendDisabled={isSendButtonDisabled}
            handleSubmit={this.handleSubmit}
            isModalVisible={isComposeMessageModalVisible}
            setModalVisibility={setMessageComposeModalVisibility}
            composeModalTitle="Compose Text"
            candidate360MessageComposeSectionPayload={candidate360MessageComposeSectionPayload}
            context="message"
            onCloseEmailComposer={onCloseMessageComposer}
            readChat={this.onReadPhoneNumberSMS}
            fetchMessages={fetchMessages}
            currentValues={currentValues}
            conversationId={conversationId}
            setPhoneNumber={eventCallbacks.onChangePhone}
          />
        )}
      </Form>
    );
  }
}
export default Form.create()(ConnectMessageWindow);
export { ConnectMessageWindow as ConnectMessageWindowWithoutForm };
