import * as jobDistributionRepository from '../Repository/JobDistributionRepository';
import { AdvertisementPortals } from '../Utils/JobDistributionUtils/JobDistributionUtils';
import { setNotification } from './ActionCreators/ConnectActions';
import {
  resetJobDistributedInfo,
  resetJobDistribution,
  setDistributedJobInfo,
  setDistributedJobPortalInfoStatus,
  setDistributedJobPortals,
  setDistributedJobPortalStatus,
  setDistributeJobStatus,
  setFetchAvailableJobAdCreditsStatus,
  setFetchJobPublishHistoryStatus,
  setJobPublishHistory,
  setMonsterJobInventories,
  setMonsterJobInventoriesStatus,
  setPortalTariff,
} from './ActionCreators/JobDistributionActionCreator';
import { fetchAvailableCredits } from './AryaPayActions';
import { fetchProductVariantsForProduct } from './AryaPayOrderActions';
import { fetchJobDetails } from './JobActions';

function fetchJobPublishHistory(portal) {
  return async dispatch => {
    try {
      dispatch(setFetchJobPublishHistoryStatus('INPROGRESS'));

      const response = await jobDistributionRepository.fetchJobPublishHistory(portal);
      dispatch(setJobPublishHistory(response.data));

      dispatch(setFetchJobPublishHistoryStatus('COMPLETED'));
    } catch (err) {
      dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
      dispatch(setFetchJobPublishHistoryStatus('FAILED'));
    }
  };
}

function fetchJobPublishedPortals(filter) {
  const { jobId, includeDraft = false, includeRecalled = false } = filter;
  return async dispatch => {
    try {
      dispatch(setDistributedJobPortalStatus('INPROGRESS'));
      const response = await jobDistributionRepository.fetchJobPublishedPortals(jobId, includeDraft, includeRecalled);
      dispatch(
        setDistributedJobPortals({
          portals: response.data,
          setDraft: includeDraft,
          setDistributed: true,
          setRecalled: includeRecalled,
        })
      );
      dispatch(setDistributedJobPortalStatus('COMPLETED'));
      return response.data;
    } catch (err) {
      dispatch(setNotification('ERROR', 'Oops, something just went wrong. Please try again.'));
      dispatch(setDistributedJobPortalStatus('FAILED'));
    }
    return [];
  };
}

function fetchAvailableMonsterJobInventories(jobId) {
  return async dispatch => {
    try {
      dispatch(setMonsterJobInventoriesStatus('INPROGRESS'));
      const response = await jobDistributionRepository.fetchAvailableMonsterJobInventories(jobId);
      dispatch(setMonsterJobInventories(response.data));
      dispatch(setMonsterJobInventoriesStatus('COMPLETED'));
    } catch (err) {
      dispatch(setMonsterJobInventoriesStatus('FAILED'));
    }
  };
}

function fetchJobPortalPublishedInfoWithoutApiStatusUpdate(jobId, portal) {
  return async dispatch => {
    const response = await jobDistributionRepository.fetchJobPortalPublishedInfo(jobId, portal);
    if (response.status === 200) {
      dispatch(setDistributedJobInfo(jobId, portal, response.data));
    }
    if (response.status === 204) {
      dispatch(resetJobDistributedInfo(portal));
    }
  };
}

function fetchJobPortalPublishedInfo(jobId, portal) {
  return async dispatch => {
    try {
      dispatch(setDistributedJobPortalInfoStatus('INPROGRESS'));
      await dispatch(fetchJobPortalPublishedInfoWithoutApiStatusUpdate(jobId, portal));
      dispatch(setDistributedJobPortalInfoStatus('COMPLETED'));
    } catch (err) {
      const isRequestCancelled = err.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        dispatch(setDistributedJobPortalInfoStatus('FAILED'));
      }
    }
  };
}

function distributeJob(jobId, portal, values, goBack, successMessage = '') {
  return async dispatch => {
    try {
      dispatch(setDistributeJobStatus('INPROGRESS'));
      await jobDistributionRepository.distributeJob(jobId, {
        Portals: [
          {
            Name: portal,
          },
        ],
        ...values,
      });
      if (!values.IsDraft) {
        dispatch(fetchJobDetails(jobId));
        goBack();
        if (AdvertisementPortals.includes(portal.toLowerCase())) {
          const currentMessage =
            successMessage ||
            `Congratulations! Your Intelligent Job Advertising campaign has been launched. Review the 'Job Info' tab for results.`;
          dispatch(setNotification('SUCCESS', currentMessage));
        } else {
          dispatch(
            setNotification('SUCCESS', `Congratulations! Your Intelligent Job Posting campaign has been launched`)
          );
        }
        await dispatch(fetchJobPublishedPortals({ jobId, includeDraft: values.IsDraft ?? false }));
        await dispatch(fetchJobPortalPublishedInfo(jobId, portal));
        await dispatch(fetchAvailableMonsterJobInventories(jobId));
      } else {
        await dispatch(fetchJobPortalPublishedInfoWithoutApiStatusUpdate(jobId, portal));
        dispatch(
          setDistributedJobPortals({ setDraft: true, portals: [{ DistributionStatus: 'Draft', Portal: portal }] })
        );
      }
      dispatch(setDistributeJobStatus('COMPLETED'));
    } catch (err) {
      let errMessage = 'Oops, something just went wrong. Please try again.';
      if (err.response.status === 406) {
        errMessage = `Credentials not integrated`;
      }
      if (err.response.status === 400) {
        const inValidFieldName = err.response.data?.Error?.Code?.replace('INVALID_', '');
        if (inValidFieldName) {
          errMessage = `Please fill in a valid ${inValidFieldName}`;
        }
      }
      dispatch(setNotification('ERROR', errMessage));
      dispatch(setDistributeJobStatus('FAILED'));
    }
  };
}

function recallJob(jobId, portal) {
  return async dispatch => {
    try {
      await jobDistributionRepository.recallJob(jobId, {
        Portals: [
          {
            Name: portal,
          },
        ],
      });
      if (AdvertisementPortals.includes(portal.toLowerCase())) {
        dispatch(setNotification('SUCCESS', `Successfully ended Campaign`));
      } else {
        dispatch(setNotification('SUCCESS', `Successfully deleted job from ${portal}`));
      }
      await dispatch(fetchJobPublishedPortals({ jobId, includeRecalled: true }));
      await dispatch(fetchJobPortalPublishedInfo(jobId, portal));
    } catch (err) {
      let errMessage = 'Oops, something just went wrong. Please try again.';
      if (err.response.status === 406) {
        errMessage = `Credentials not integrated`;
      }
      dispatch(setNotification('ERROR', errMessage));
    }
  };
}

function fetchPortalTariff(portal) {
  return async dispatch => {
    const response = await jobDistributionRepository.fetchPortalTariff(portal);
    response.data.portal = portal.toLowerCase();
    dispatch(setPortalTariff(response.data));
  };
}

function fetchAvailableJobAdCredits() {
  return async dispatch => {
    dispatch(setFetchAvailableJobAdCreditsStatus('INPROGRESS'));
    const { ProductVariants: productVariants } = await dispatch(fetchProductVariantsForProduct('Job Advertisment'));
    await dispatch(fetchAvailableCredits(productVariants[0].Id), 'Arya');
    dispatch(setFetchAvailableJobAdCreditsStatus('COMPLETED'));
  };
}

export {
  resetJobDistribution,
  fetchJobPublishHistory,
  fetchJobPublishedPortals,
  fetchAvailableMonsterJobInventories,
  fetchJobPortalPublishedInfo,
  distributeJob,
  recallJob,
  fetchPortalTariff,
  fetchAvailableJobAdCredits,
};
