import React from 'react';
import { Icon, Tooltip, message } from 'antd';
import styles from './PushCandidateComponent.module.scss';
import PushIconV2 from '../../Icons/PushIconV2';

const appliedBucketPushCandidateErrorText = 'You can only push candidates that are in Applied bucket';

function PushCandidateComponent(props) {
  const {
    atsSource,
    onPushCandToAts,
    loading = false,
    isPushAllowed = false,
    showContactInfoButton,
    showPushCandidateButton,
    isRecreateCandidateAllowed,
    jobStatus,
    aryaJobStatus,
    isPushCandidateNotAllowedForAryaClosedJobs,
    isManualCandidateCreationAllowedForApplied,
    appliedtime,
  } = props;

  if (loading) {
    return (
      <div className={styles.loadinIcon}>
        <Icon type="loading" />
      </div>
    );
  }
  const getIsCandidatePushAllowedForCurrentJobStatus = () =>
    aryaJobStatus.toLowerCase() !== 'closed' || !isPushCandidateNotAllowedForAryaClosedJobs;

  const closedJobStatusErrorText = `You cannot push the candidate for jobs that are in the ${jobStatus} state`;

  const handlePushIconOnClick = e => {
    if (isManualCandidateCreationAllowedForApplied && !appliedtime) {
      e.stopPropagation();
      showAlertForPushOnCandidates(appliedBucketPushCandidateErrorText);
      return;
    }
    if (isPushAllowed && isRecreateCandidateAllowed) {
      if (getIsCandidatePushAllowedForCurrentJobStatus()) onPushCandToAts();
      else showAlertForPushOnCandidates(closedJobStatusErrorText);
      return;
    }
    e.stopPropagation();
  };

  const handleInfoButtonOnClick = e => {
    if (isManualCandidateCreationAllowedForApplied && !appliedtime) {
      e.stopPropagation();
      showAlertForPushOnCandidates(appliedBucketPushCandidateErrorText);
      return;
    }
    if (getIsCandidatePushAllowedForCurrentJobStatus()) onPushCandToAts();
    else showAlertForPushOnCandidates(closedJobStatusErrorText);
    e.stopPropagation();
  };

  const showAlertForPushOnCandidates = errorText => message.error(errorText);

  if (atsSource) {
    return (
      <Tooltip title={<div>Client Candidate ID: {atsSource.CandidateId}</div>} overlayClassName="drawer-tooltip">
        <div className={styles.candidateDetailPushedCandidate}>
          <PushIconV2
            className={styles.pushStatusIcon}
            onClick={handlePushIconOnClick}
            style={{ fontSize: '18px' }}
            fillColor="#07101a"
          />
        </div>
      </Tooltip>
    );
  }

  const shouldShowInfoButton = showContactInfoButton && isPushAllowed && showPushCandidateButton;

  if (shouldShowInfoButton) {
    return (
      <div className={styles.candidateDetailPushCandidate} role="presentation" onClick={handleInfoButtonOnClick}>
        <PushIconV2 style={{ fontSize: '18px' }} fillColor="#07101a" />
      </div>
    );
  }
  return null;
}

export default PushCandidateComponent;
