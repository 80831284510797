import React, { useState, useEffect } from 'react';
import { Button, Empty, Tooltip } from 'antd';
import CreatePostIcon from '../../Icons/CreatePostIcon';
import SourcingSupportCard from '../SourcingSupportCard/SourcingSupportCard';
import styles from './SourcingSupport.module.scss';
import NewPostComponent from '../SourcingSupportCard/NewPostComponent';
import { getConfiguration } from '../Collaborators/Collaborators';

function EmptyChatListComponent() {
  return (
    <Empty
      image={
        <img src={`${process.env.PUBLIC_URL}/static/Images/empty-sourcing-support-chat.svg`} alt="No jobs found" />
      }
      imageStyle={{ marginBottom: '40px', height: '169px', alignItems: 'center' }}
      description={
        <span>
          <b>No threads open</b>
          <br />
          Get started by posting any queries regarding jobs & candidates
        </span>
      }
    />
  );
}

function SourcingSupport(props) {
  const createPostButtonName = 'Start a post';
  const {
    initialChatsFetchApiStatus,
    threads,
    currentUser,
    chatId,
    addThreadMessageToTheChat,
    topicId,
    addThreadReplyToTheChat,
    fetchMoreRepliesForTheThread,
    fetchAllThreads,
    lastFetchedThreadCount,
    initialThreadsFetchApiStatus,
    isChatDisabled,
    markChatAsRead,
    unreadMessageCount,
    currentMember,
    jobDetails,
    sharedUsers,
    initiateChat,
  } = props;

  const { JobId: jobId, JobTitle: jobTitle, JobGuid: jobGuid } = jobDetails;
  const membersOfChat = sharedUsers.map(user => ({
    id: user.UserGuid,
    userType: 'User',
  }));
  if (currentMember.isAryaRecruiter) {
    membersOfChat.push({
      id: currentMember.userGuid,
      userType: 'User',
      roleName: 'SupportAdmin',
    });
  }
  useEffect(() => {
    if (!chatId) {
      const member = {
        Id: currentMember.userGuid,
        UserType: 'User',
        roleName: currentMember.isAryaRecruiter ? 'SupportAdmin' : 'Owner',
      };
      initiateChat({ usersInTheChat: membersOfChat, jobTitle, jobId, jobGuid, member });
    } else {
      fetchAllThreads({ chatId });
    }
  }, [chatId]);

  const [showNewPostComponent, setShowNewPostComponent] = useState(false);
  const closeNewPost = () => {
    setShowNewPostComponent(false);
  };
  const firstName = currentUser.given_name;
  const lastName = currentUser.family_name;
  const avatarConfiguration = getConfiguration([firstName, lastName].join(' '));
  const isChatEmpty =
    threads?.length === 0 &&
    !showNewPostComponent &&
    initialChatsFetchApiStatus === 'SUCCESS' &&
    initialThreadsFetchApiStatus === 'SUCCESS';

  useEffect(() => {
    if (unreadMessageCount > 0 && window.location.pathname.includes('sourcingsupport')) {
      markChatAsRead({ chatId });
    }
  });

  return (
    <div>
      <div className={styles.souringSupportHeader}>
        <span>Sourcing Assistant Chat</span>
        <Tooltip title= {isChatDisabled? 'Chat is disabled since the job is not in open state':''}>
          <Button
            type="primary"
            className={styles.createPostButton}
            onClick={() => {
              setShowNewPostComponent(true);
            }}
            disabled={isChatDisabled}
          >
            <CreatePostIcon />
            {createPostButtonName}
          </Button>
        </Tooltip>
      </div>
      {showNewPostComponent ? (
        <NewPostComponent
          addThreadToTheChat={addThreadMessageToTheChat}
          chatId={chatId}
          topicId={topicId}
          closeNewPost={closeNewPost}
          avatarConfiguration={avatarConfiguration}
          isChatDisabled={isChatDisabled}
          markChatAsRead={markChatAsRead}
          currentMember={currentMember}
        />
      ) : null}
      {isChatEmpty ? <EmptyChatListComponent /> : null}
      <SourcingSupportCard
        threads={threads}
        chatId={chatId}
        topicId={topicId}
        initialChatsFetchApiStatus={initialChatsFetchApiStatus}
        initialThreadsFetchApiStatus={initialThreadsFetchApiStatus}
        addThreadReplyToTheChat={addThreadReplyToTheChat}
        fetchMoreRepliesForTheThread={fetchMoreRepliesForTheThread}
        fetchAllThreads={fetchAllThreads}
        lastFetchedThreadCount={lastFetchedThreadCount}
        isChatDisabled={isChatDisabled}
        currentMember={currentMember}
      />
    </div>
  );
}

export default SourcingSupport;
